import { useEffect, useState } from "react";
import { CAddressData, CHANGED, CSearchAdrExtTS, ctDataType, MULTI_SELECT_TABLES, SAVED, selCodeDataType, UNSAVED } from "../../../types";
import { v4 as uuidv4 } from 'uuid';
import { Button } from "@progress/kendo-react-buttons";
import { getSelectedState, Grid, GridColumn, GridSelectionChangeEvent, GridSortChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import MyDatePicker from "../../myDatePicker/myDatePicker";
import { CodeTableSel } from "../../CodeTableSel/CodeTableSel";
import { dateInternalToSystem, mui } from "../../../Helper";
import MultiSelectList, { multiSelectProps } from "../MultiSelect/MultiSelectList";
import { DatePickerChangeEvent, TimePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import MyTimePicker from "../../myDatePicker/myTimePicker";
import { SelectCode } from "../../SelectCode/SelectCode";
import CustomDate from "../../Misc/CustomDate";
import CustomTime from "../../Misc/CustomTime";
import { getter, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { IConfirmation } from "../../Misc/Confirmation";
import UserSession from "../../UserSession";
import { format } from "path/win32";
import { IntlService } from "@progress/kendo-react-intl";

interface TypeScreenTabProps {
    data: CAddressData;
    AdrExternTSData: CSearchAdrExtTS[] | undefined;
    contentContainerWidth: number;
    callbackHandleAdrExtTSDataChange(value: CSearchAdrExtTS[]): any;
};

const TypeScreenTab = (props: TypeScreenTabProps) => {
    // For confirmation dialog
    const emptyConfirmation: IConfirmation = {
        title: "",
        message: "",
        button_yes: "",
        button_no: "",
        show: false,
        userdata: "",
        buttonClicked: function (yes: boolean): void {

        }
    }
    const [confirmDialog, setConfirmDialog] = useState<IConfirmation>(emptyConfirmation);

    const [tsIssueDate, setTsIssueDate] = useState<string>("");
    const [validateDate, setValidateDate] = useState<string>("");
    const emptyExtTS: CSearchAdrExtTS = {
        key: "",
        sReqID: "",
        sBG: "",
        sBGDesc: "",
        sRH: "",
        sRHDesc: "",
        sPT: "",
        sPTDesc: "",
        sGrpCWYN: "",
        sKell: "",
        sKellDesc: "",
        sDat: "",
        sDatDesc: "",
        sTSIssueBy: "",
        sTSIssueByDesc: "",
        sTSIssueDate: "",
        sRemark: "",
        sEnteredBy: "",
        sEnteredByName: "",
        sEnteredDate: "",
        sAksResult: "",
        sAksResultDesc: "",
        sAksValidDate: "",
        sAksValidTime: "",
        sAntibodyID: "",
        sAksComment: "",
        sBGChangeSerID: "",
        sReqDate: "",
        sReqTime: "",
        sFullBGYN: "",
        FullBGYN: false,
        CWYN: false,
        sRecStatus: ""
    };
    const idGetter_ExtTS = getter('sReqID');
    const [extTSData, setExtTSData] = useState<CSearchAdrExtTS[]>([]);
    const [extTSKey, setExtTSKey] = useState<string>("");
    const [selectedExtTS, setSelectedExtTS] = useState<CSearchAdrExtTS>(emptyExtTS);  
    const [disabled, setDisabled] = useState(false);

    const handleChangeDate = (value: string, source: string) => {
        if (source === 'requestdate') {
            setRequestDate(value);
            setSelectedExtTS(prevData => ({
                ...prevData,
                ['sReqDate']: value
            }));
        } else if (source === 'aksvalidatedate') {
            setValidateDate(value);
            setSelectedExtTS(prevData => ({
                ...prevData,
                ['sAksValidDate']: value
            }));
        } else if (source === 'tsissuedate') {
            setTsIssueDate(value);
            setSelectedExtTS(prevData => ({
                ...prevData,
                ['sTSIssueDate']: value
            }));
        };
    };

    // All code tables related to Ext TS
    const callbackCTSelected_CSearchAdrExtTS = async (ctid: string, controlID: string, objCT: ctDataType) => {
        var prevState: CSearchAdrExtTS = { ...selectedExtTS, [controlID]: objCT.sCode };
        if (controlID === 'sBG')
            prevState.sBGDesc = objCT.sDescDevelopment;
        else if (controlID === 'sRH')
            prevState.sRHDesc = objCT.sDescDevelopment;
        else if (controlID === 'sKell')
            prevState.sKellDesc = objCT.sDescDevelopment;
        else if (controlID === 'sDat')
            prevState.sDatDesc = objCT.sDescDevelopment;
        else if (controlID === 'sAksResult')
            prevState.sAksResultDesc = objCT.sDescDevelopment;
        else if (controlID === 'sTSIssueBy')
            prevState.sTSIssueByDesc = objCT.sDescDevelopment;
        console.log('callbackCTSelected_CAdrPatBld', controlID, objCT, prevState);
        setSelectedExtTS(prevState);
    };

    // All textbox and checkboxes related to main address data
    const handleChangeTS = (e: any) => {
        const type = e.target.type
        const name = e.target.name
        const value = type === "checkbox"
            ? e.target.checked
            : e.target.value
        // console.log('name, type', name, type);
        setSelectedExtTS(prevData => ({
            ...prevData,
            [name]: value
        }))
    };

    // Select code component callback for all selections on main address data
    const callbackCodeSelectedTS = async (tableid: string, controlID: string, obj: selCodeDataType) => {
        var prevState: CSearchAdrExtTS = { ...selectedExtTS, [controlID]: obj.code };
        if (controlID === 'sPT')
            prevState.sPTDesc = obj.desc;
        setSelectedExtTS(prevState);
    };

    const initialSort_TS: Array<SortDescriptor> = [
        { field: "sReqID", dir: "asc" },
    ];

    const [selectedState_ExtTS, setSelectedState_ExtTS] = useState<{
        [id: string]: boolean | number[];
    }>({});
   
    const [sort_ExtTS, setSort_ExtTS] = useState(initialSort_TS);

    const onSelectionChangeTS = (event: GridSelectionChangeEvent) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState_ExtTS,
            dataItemKey: 'key',
        });
        // get the selected row from the grid and set state variable so that data is populated in the form
        let selectedIds: CSearchAdrExtTS[] = [];
        for (const property in newSelectedState) {
            if (newSelectedState[property]) {
                let extTS: CSearchAdrExtTS | undefined = extTSData.find((item: CSearchAdrExtTS) => item.key === property.toString())
                if (extTS !== undefined)
                    selectedIds.push(extTS)
            }
        }
        if (selectedIds.length > 0) {
            console.log('selected TS: ', selectedIds[0]);
            selectedIds[0].sReqDate !== "" ? setRequestDate(selectedIds[0].sReqDate) : setRequestDate("");
            selectedIds[0].sReqTime !== "" ? setRequestTime(selectedIds[0].sReqTime) : setRequestTime("");
            selectedIds[0].sAksValidDate !== "" ? setValidateDate(selectedIds[0].sAksValidDate) : setValidateDate("");
            selectedIds[0].sAksValidTime !== "" ? setValidateTime(selectedIds[0].sAksValidTime) : setValidateTime("");
            selectedIds[0].sTSIssueDate !== "" ? setTsIssueDate(selectedIds[0].sTSIssueDate) : setTsIssueDate("");            
            selectedIds[0].CWYN = selectedIds[0].sGrpCWYN === "1" ? true : false;
            setSelectedExtTS(selectedIds[0]);
            setExtTSKey(selectedIds[0].key);
            selectedIds[0].sRecStatus === "S" ? setDisabled(true) : setDisabled(false);
        }
        else
            setSelectedExtTS(emptyExtTS);
            setSelectedState_ExtTS(newSelectedState);
    };

    const newTS = () => {
        let sel: any = {};
        setSelectedState_ExtTS(sel);
        setExtTSKey("");
        var obj: CSearchAdrExtTS = emptyExtTS;
        obj.sReqID = "New";
        obj.sEnteredBy = UserSession.getLoggedInUser().UserID;
        obj.sEnteredByName = UserSession.getLoggedInUser().UserShortName;
        const intl = new IntlService("en");
        obj.sEnteredDate = intl.formatDate(new Date(), "dd.MM.yyyy")
        setSelectedExtTS(obj);
        setRequestDate("");
        setRequestTime("");
        setValidateDate("");
        setValidateTime("");
        setTsIssueDate("");
        //setExtTSData([]);
        setDisabled(false);
        setTimeout(() => { document.getElementById('sReqDate')?.focus() }, 200);
    };

    function CheckMandatoryData() {
        var bok: boolean = true;
        if (selectedExtTS.sReqDate === "") {
            alert("Enter Req Date");
            setTimeout(() => { document.getElementById('sReqDate')?.focus() }, 200);
            bok = false;
        } else if (selectedExtTS.sReqTime === "") {
            alert("Enter Req Time");
            setTimeout(() => { document.getElementById('sReqTime')?.focus() }, 200);
            bok = false;
        } else if (selectedExtTS.sAksResult === "") {
            alert("Enter Aks Result");
            setTimeout(() => { document.getElementById('sAksResult')?.focus() }, 200);
            bok = false;
        } else if (selectedExtTS.sAksValidDate === "") {
            alert("Enter Aks Valid Date");
            setTimeout(() => { document.getElementById('aksvalidatedate')?.focus() }, 200);
            bok = false;
        } else if (selectedExtTS.sTSIssueBy === "") {
            alert("Enter Issue By");
            setTimeout(() => { document.getElementById('sTSIssueBy')?.focus() }, 200);
            bok = false;
        } else if (selectedExtTS.sTSIssueDate === "") {
            alert("Enter Issue Date");
            setTimeout(() => { document.getElementById('tsissuedate')?.focus() }, 200);
            bok = false;                                         
        };
        return bok;
    };
    function VerifyData() {
        var bok: boolean = true;
        var dtCheck: any = dateInternalToSystem(selectedExtTS.sTSIssueDate);
        if (dtCheck > new Date()) {
            alert("Issue Date Can't Be In Future");
            bok = false;
        } else {
            extTSData.forEach((o: CSearchAdrExtTS) => {
                if (selectedExtTS.sBG !== "" && o.sBG !== "" && o.sBG !== selectedExtTS.sBG) {
                    alert("Blood Group Does Not Match.");
                    bok = false;
                } else if (selectedExtTS.sRH !== "" && o.sRH !== "" && o.sRH !== selectedExtTS.sRH) {
                    alert("Rhesus Does Not Match.");
                    bok = false;
                } else if (selectedExtTS.sPT !== "" && o.sPT !== "" && o.sPT !== selectedExtTS.sPT) {
                    alert("Pheno Type Does Not Match.");
                    bok = false;
                } else if (selectedExtTS.sKell !== "" && o.sKell !== "" && o.sKell !== selectedExtTS.sKell) {
                    alert("Kell Does Not Match.");
                    bok = false;
                };
            });
        };    
        return bok;
    };
   
    const updateListExtTS = () => {
        if (Object.keys(selectedState_ExtTS).length === 0) {
            if (CheckMandatoryData()) {
                if (VerifyData()) {
                    let clonedArray: CSearchAdrExtTS[] = JSON.parse(JSON.stringify(extTSData));
                    var newkey: string = uuidv4();
                    console.log('newkey', newkey);
                    clonedArray.unshift({
                        key: newkey,
                        sReqID: selectedExtTS.sReqID,
                        sBG: selectedExtTS.sBG,
                        sBGDesc: selectedExtTS.sBGDesc,
                        sRH: selectedExtTS.sRH,
                        sRHDesc: selectedExtTS.sRHDesc,
                        sPT: selectedExtTS.sPT,
                        sPTDesc: selectedExtTS.sPTDesc,
                        sGrpCWYN: selectedExtTS.CWYN ? "1" : "0",
                        sKell: selectedExtTS.sKell,
                        sKellDesc: selectedExtTS.sKellDesc,
                        sDat: selectedExtTS.sDat,
                        sDatDesc: selectedExtTS.sDatDesc,
                        sTSIssueBy: selectedExtTS.sTSIssueBy,
                        sTSIssueByDesc: selectedExtTS.sTSIssueByDesc,
                        sTSIssueDate: selectedExtTS.sTSIssueDate,
                        sRemark: selectedExtTS.sRemark,
                        sEnteredBy: selectedExtTS.sEnteredBy,
                        sEnteredByName: selectedExtTS.sEnteredByName,
                        sEnteredDate: selectedExtTS.sEnteredDate,
                        sAksResult: selectedExtTS.sAksResult,
                        sAksResultDesc: selectedExtTS.sAksResultDesc,
                        sAksValidDate: selectedExtTS.sAksValidDate,
                        sAksValidTime: selectedExtTS.sAksValidTime,
                        sAntibodyID: selectedExtTS.sAntibodyID,
                        sAksComment: selectedExtTS.sAksComment,
                        sBGChangeSerID: selectedExtTS.sBGChangeSerID,
                        sReqDate: selectedExtTS.sReqDate,
                        sReqTime: selectedExtTS.sReqTime,
                        sFullBGYN: selectedExtTS.FullBGYN ? "1" : "0",
                        FullBGYN: false,
                        CWYN: false,
                        sRecStatus:UNSAVED,
                    });
                    setExtTSData(clonedArray);
                    props.callbackHandleAdrExtTSDataChange(clonedArray);
                    let sel: any = {};
                    sel[clonedArray[0].key] = true;
                    setSelectedState_ExtTS(sel);
                    console.log('sel', sel);
                    setExtTSKey(newkey);
                };
            };            
        }
        else if (selectedExtTS !== null) {
            var binddata: CSearchAdrExtTS[] = [];
            extTSData.forEach((o: CSearchAdrExtTS) => {
                if (extTSKey === o.key) {
                    binddata.push({
                        ...o, sReqID: selectedExtTS.sReqID,
                        sBG: selectedExtTS.sBG,
                        sBGDesc: selectedExtTS.sBGDesc,
                        sRH: selectedExtTS.sRH,
                        sRHDesc: selectedExtTS.sRHDesc,
                        sPT: selectedExtTS.sPT,
                        sPTDesc: selectedExtTS.sPTDesc,
                        sGrpCWYN: selectedExtTS.sGrpCWYN,
                        sKell: selectedExtTS.sKell,
                        sKellDesc: selectedExtTS.sKellDesc,
                        sDat: selectedExtTS.sDat,
                        sDatDesc: selectedExtTS.sDatDesc,
                        sTSIssueBy: selectedExtTS.sTSIssueBy,
                        sTSIssueByDesc: selectedExtTS.sTSIssueByDesc,
                        sTSIssueDate: selectedExtTS.sTSIssueDate,
                        sRemark: selectedExtTS.sRemark,
                        sEnteredBy: selectedExtTS.sEnteredBy,
                        sEnteredByName: selectedExtTS.sEnteredByName,
                        sEnteredDate: selectedExtTS.sEnteredDate,
                        sAksResult: selectedExtTS.sAksResult,
                        sAksResultDesc: selectedExtTS.sAksResultDesc,
                        sAksValidDate: selectedExtTS.sAksValidDate,
                        sAksValidTime: selectedExtTS.sAksValidTime,
                        sAntibodyID: selectedExtTS.sAntibodyID,
                        sAksComment: selectedExtTS.sAksComment,
                        sBGChangeSerID: selectedExtTS.sBGChangeSerID,
                        sReqDate: selectedExtTS.sReqDate,
                        sReqTime: selectedExtTS.sReqTime,
                        sFullBGYN: selectedExtTS.sFullBGYN,
                        FullBGYN: selectedExtTS.FullBGYN,
                        CWYN: selectedExtTS.CWYN,
                        sRecStatus: selectedExtTS.sRecStatus === SAVED ? CHANGED : selectedExtTS.sRecStatus
                    });
                }
                else
                    binddata.push({ ...o });
            });
            setExtTSData(binddata);
            props.callbackHandleAdrExtTSDataChange(binddata);
        }
    };
     
    useEffect(() => {   
        var obj: CSearchAdrExtTS = emptyExtTS;
        obj.sReqID = "New";
        obj.sEnteredBy = UserSession.getLoggedInUser().UserID;
        obj.sEnteredByName = UserSession.getLoggedInUser().UserShortName;
        const intl = new IntlService("en");
        obj.sEnteredDate = intl.formatDate(new Date(),"dd.MM.yyyy")
        setSelectedExtTS(obj);

        return () => {
            
        };

    }, [])

    useEffect(() => {
        if (props.data.hasOwnProperty('state') && props.data.state === "EMPTY") {
            setExtTSData([]);
            setSelectedExtTS(emptyExtTS);
            setTsIssueDate("");
            setValidateDate("");
            setRequestDate("");
        }
        else {
            var binddata_extts: CSearchAdrExtTS[] = [];
            if (props.AdrExternTSData !== null && props.AdrExternTSData !== undefined)
                props.AdrExternTSData.forEach((o: CSearchAdrExtTS) => {
                    binddata_extts.push({ ...o, key: uuidv4() });
                });
            setExtTSData(binddata_extts);

        }
    }, [props.AdrExternTSData])


    const [requestDate, setRequestDate] = useState<string>("");
    const [requestTime, setRequestTime] = useState<string>("");
    const handleChangeTime = (value: string, source: string) => {
        setRequestTime(value);
        setSelectedExtTS(prevData => ({
            ...prevData,
            ['sReqTime']: value
        }));
    };
    const [validateTime, setValidateTime] = useState<string>("");
   
    const handleChangeValidateTime = (value: string, source: string) => {
        setValidateTime(value);
        setSelectedExtTS(prevData => ({
            ...prevData,
            ['sAksValidTime']: value
        }));
    };
    

    const dlgMSEmptyState: multiSelectProps = {
        multiSelectTable: "",
        selectedValues: "",
        showDialog: false,
        MultiSelectSaved: (multiSelectTable: MULTI_SELECT_TABLES, savedCodes: string, cancel: boolean) => { }
    }
    const [multiSelectData, setMultiSelectData] = useState<multiSelectProps>(dlgMSEmptyState);
    const MultiSelectSaved = (multiSelectTable: MULTI_SELECT_TABLES, savedCodes: string, cancel: boolean) => {
        if (multiSelectTable === "Antibody")
            setSelectedExtTS(prevData => ({
                ...prevData,
                sAntibodyID: savedCodes
            }))
        setMultiSelectData(dlgMSEmptyState);
    }

    const openRepToEmailMS = (multiSelectTable: MULTI_SELECT_TABLES) => {
        var selectedValues: string = "";
        if (multiSelectTable === "Antibody")
            selectedValues = selectedExtTS.sAntibodyID

        setMultiSelectData({
            multiSelectTable: multiSelectTable, showDialog: true,
            selectedValues: selectedValues, MultiSelectSaved
        });
    };

    const genericFunction = () => {
        alert('todo');
    };

    return (
        <>
            <div className="container pt5" style={{ maxWidth: props.contentContainerWidth }}>
                <div className="row">
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'sReqID'}>TS Req ID</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input disabled={true} autoComplete="off" id="sReqID" name="sReqID" type='text' onChange={handleChangeTS}
                                    value={selectedExtTS.sReqID} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        {/*<MyDatePicker label="Request date" source={'requestdate'}*/}
                        {/*    parentOnChange={handleChangeDate}*/}
                        {/*    required={false} pickerValue={requestDate} setPickerValue={setRequestDate} id={'sReqDate'} supplParam1="" supplParam2="" ></MyDatePicker>*/}
                        <CustomDate dateChanged={handleChangeDate} width='150px' className='' defaulValue={requestDate} id={'sReqDate'} source={'requestdate'} label={'Request date'} required={false} disabled={false} ></CustomDate>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper-child"><Label editorId={'sReqTime'}>{mui(userRights, "common", "lblReqTime", "Req. time")}</Label></div>
                        <div className="vertical-wrapper-child">
                            {/*<MyTimePicker label="" source={'sReqTime'} parentOnChange={handleChangeTime}*/}
                            {/*    pickerValue={requestTime} required={false} setPickerValue={setRequestTime} id={'sReqTime'} supplParam1="" supplParam2="" ></MyTimePicker>*/}
                            <CustomTime allowSecond={false} timeChanged={handleChangeTime} width='100px' className='' defaulValue={requestTime} id={'sReqTime'} source={'sReqTime'} label={''} required={false} disabled={false}></CustomTime>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child">&nbsp;</div>
                            <div style={{ display: 'inline-block' }}>
                                <Checkbox disabled={disabled} onChange={handleChangeTS} id="FullBGYN" name="FullBGYN"
                                    value={selectedExtTS.FullBGYN}
                                    label="Full BG YN" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Blood Group</Label></div>
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} disabled={disabled} setSelectedCT={callbackCTSelected_CSearchAdrExtTS} mandantChanged={0} id="sBG"
                                    codeTableID='602' selCode={selectedExtTS.sBG} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Rhesus</Label></div>
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} disabled={disabled} setSelectedCT={callbackCTSelected_CSearchAdrExtTS} mandantChanged={0} id="sRH"
                                    codeTableID='603' selCode={selectedExtTS.sRH} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Pheno type</Label></div>
                            <div className="vertical-wrapper-child">
                                <SelectCode autocomplete={false} disabled={disabled} setSelectedTable={callbackCodeSelectedTS} id="sPT"
                                    tablename="PhenoType" code="PhenoID" desc="Description" selCode={selectedExtTS.sPT} mandantChanged={0} width={"100%"}
                                    supplParam1="" supplParam2=""></SelectCode>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Kell</Label></div>
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} disabled={disabled} setSelectedCT={callbackCTSelected_CSearchAdrExtTS} mandantChanged={0} id="sKell"
                                    codeTableID='627' selCode={selectedExtTS.sKell} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>AKS Result</Label></div>
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} setSelectedCT={callbackCTSelected_CSearchAdrExtTS} mandantChanged={0} id="sAksResult"
                                    codeTableID='602' selCode={selectedExtTS.sAksResult} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        {/*<MyDatePicker label="Validate date" source={'aksvalidatedate'}*/}
                        {/*    parentOnChange={handleChangeDate}*/}
                        {/*    required={false} pickerValue={validateDate} setPickerValue={setValidateDate} id={'aksvalidatedate'} supplParam1="" supplParam2=""></MyDatePicker>*/}
                        <CustomDate dateChanged={handleChangeDate} width='150px' className='' defaulValue={validateDate} id={'aksvalidatedate'} source={'aksvalidatedate'} label={'Aks Valid date'} required={false} disabled={disabled}></CustomDate>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper-child"><Label editorId={'aksvalidatetime'}>Aks Valid Time</Label></div>
                        <div className="vertical-wrapper-child">
                            {/*<MyTimePicker label="" source={'aksvalidatetime'} required={false} parentOnChange={handleChangeValidateTime}*/}
                            {/*    pickerValue={validateTime} setPickerValue={setValidateTime} id={'aksvalidatetime'} supplParam1="" supplParam2="" ></MyTimePicker>*/}
                            <CustomTime allowSecond={false} timeChanged={handleChangeValidateTime} width='100px' className='' defaulValue={validateTime} id={'aksvalidatetime'} source={'aksvalidatetime'} label={''} required={false} disabled={disabled}></CustomTime>
                        </div>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="col-10">
                                <div className="vertical-wrapper">
                                    <div className="vertical-wrapper-child"><Label editorId={'sAntibodyID'}>Antibody</Label></div>
                                    <div className="vertical-wrapper-child">
                                        <Input width='100%' readOnly={true} autoComplete="off" id="sAntibodyID" name="sAntibodyID" type='text'
                                            onChange={handleChangeTS} value={selectedExtTS.sAntibodyID} />
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="button-right">
                                    <div className="vertical-wrapper">
                                        <div className="vertical-wrapper-child">&nbsp;</div>
                                        <div className="vertical-wrapper-child">
                                            <Button type={"button"} themeColor={'primary'} onClick={() => {
                                                openRepToEmailMS("Antibody")
                                            }}>{mui(userRights, "common", "cmdMultiSelect", "M")}</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>TS dat</Label></div>
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} disabled={disabled} setSelectedCT={callbackCTSelected_CSearchAdrExtTS} mandantChanged={0} id="sDat"
                                    codeTableID='626' selCode={selectedExtTS.sDat} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Issued By</Label></div>
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} disabled={disabled} setSelectedCT={callbackCTSelected_CSearchAdrExtTS} mandantChanged={0} id="sTSIssueBy"
                                    codeTableID='320' selCode={selectedExtTS.sTSIssueBy} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        {/*<MyDatePicker label="Issue date" source={'tsissuedate'}*/}
                        {/*    parentOnChange={handleChangeDate}*/}
                        {/*    required={false} pickerValue={tsIssueDate} setPickerValue={setTsIssueDate} id={'tsissuedate'} supplParam1="" supplParam2="" ></MyDatePicker>*/}
                        <CustomDate dateChanged={handleChangeDate} width='150px' className='' defaulValue={tsIssueDate} id={'tsissuedate'} source={'tsissuedate'} label={'Issue date'} required={false} disabled={disabled}></CustomDate>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child">&nbsp;</div>
                            <div style={{ display: 'inline-block' }}>
                                <Checkbox disabled={disabled} onChange={handleChangeTS} id="CWYN" name="CWYN"
                                    value={selectedExtTS.CWYN}
                                    label="TS CW YN" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'sRemark'}>TS Remarks</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input width='100%' autoComplete="off" id="sRemark" name="sRemark" type='text'
                                    onChange={handleChangeTS} value={selectedExtTS.sRemark} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'sAksComment'}>AKS Comment</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="sAksComment" name="sAksComment" type='text' onChange={handleChangeTS}
                                    value={selectedExtTS.sAksComment} />
                            </div>
                        </div>
                    </div>                    
                </div>
                <div className="row">
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'sEnteredByName'}>Entered By</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input disabled={true} width='100%' autoComplete="off" id="sEnteredByName" name="sEnteredByName" type='text'
                                    onChange={handleChangeTS} value={selectedExtTS.sEnteredByName} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'sEnteredDate'}>Entered Date</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input disabled={true} width='100px' autoComplete="off" id="sEnteredDate" name="sEnteredDate" type='text'
                                    onChange={handleChangeTS} value={selectedExtTS.sEnteredDate} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">                        
                        <div className="vertical-wrapper-child">
                            <div className="button-right">
                                <Button style={{ marginTop: 5, width: '120px' }}
                                    themeColor={'success'}
                                    onClick={updateListExtTS}
                                >
                                    Update List
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col" style={{ maxHeight: 350, overflowY: 'hidden', marginTop: 5, border: '0px solid green' }}>
                        <div style={{ overflowX: 'hidden' }}>
                            <Grid style={{ width: '100%', height: 190 }}
                                sortable={true}  
                                sort={sort_ExtTS}
                                onSortChange={(e: GridSortChangeEvent) => {
                                    setSort_ExtTS(e.sort);
                                }}
                                selectable={{
                                    enabled: true,
                                    drag: false,
                                    cell: false,
                                    mode: "single",
                                }}
                                onSelectionChange={onSelectionChangeTS}
                                selectedField={'selected'}
                                data={orderBy(extTSData, sort_ExtTS).map((item) => ({
                                    ...item,
                                    selected: selectedState_ExtTS[idGetter_ExtTS(item)],
                                }))}                                
                                dataItemKey={'key'}                               
                            >
                                <GridToolbar>
                                    <button
                                        title="Add new"
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                        onClick={newTS}
                                    >
                                        Add new
                                    </button>
                                </GridToolbar>
                                <GridColumn field="sReqID" title="ReqID" width="100px" />
                                <GridColumn field="sBGDesc" title="Blood Group" />
                                <GridColumn field="sRHDesc" title="Rhesus" />
                                <GridColumn field="sPTDesc" title="PT" />
                                <GridColumn field="sKellDesc" title="Kell" />
                                <GridColumn field="sDatDesc" title="DAT" />
                                <GridColumn field="sAksResultDesc" title="AKS Result" />
                                <GridColumn field="sAntibodyID" title="Antibody" />
                                <GridColumn field="sAksComment" title="AKS Comment" />
                                <GridColumn field="sAksValidDate" title="AKS Valid" />
                                <GridColumn field="sTSIssueDate" title="Issue Date" />
                                <GridColumn field="sRemark" title="Remark" />
                                <GridColumn field="sTSIssueByDesc" title="Issue By" />
                                <GridColumn field="sEnteredByName" title="Entered By" />
                                <GridColumn field="sEnteredDate" title="Entered Date" />
                                <GridColumn field="sReqDate" title="Req Date" />
                                <GridColumn field="sFullBGYN" title="FullBGYN" />
                                <GridColumn field="sGrpCWYN" title="CWYN" />
                            </Grid>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col pt5" style={{ border: '0px solid red' }}>
                        <div className="button-right">
                            <Button
                                themeColor={'primary'}
                                onClick={genericFunction} style={{ width: '120px', marginRight: 10, display: props.data.sPID === "" ? "none" : "inline-block" }}
                            >
                                Scan TS AKS Result
                            </Button>
                            <Button style={{ width: '120px', marginRight: 10, display: props.data.sPID === "" ? "none" : "inline-block" }}
                                themeColor={'primary'}
                                onClick={genericFunction}
                            >
                                Scan TS AKS Result PDF
                            </Button>
                            <Button
                                themeColor={'primary'}
                                onClick={genericFunction} style={{ width: '120px', display: props.data.sPID === "" ? "none" : "inline-block" }}
                            >
                                Show TS AKS Result
                            </Button>

                        </div>
                    </div>
                </div>
            </div>
            <MultiSelectList multiSelectTable={multiSelectData.multiSelectTable} selectedValues={multiSelectData.selectedValues}
                showDialog={multiSelectData.showDialog} MultiSelectSaved={multiSelectData.MultiSelectSaved}></MultiSelectList>
        </>
    );
}

export default TypeScreenTab;