import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ComboBox, ComboBoxChangeEvent, DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { Label } from "@progress/kendo-react-labels";
import React, { Suspense, useCallback, useEffect } from "react";
import { useRef, useState } from "react";
import { DialogTitleBar, mui } from "../../Helper";
import { CRequestResult, CResultEntry, CResultEntryOutput, CUserRightsAppl, emptyREData, ProfileDropdownData, ReqInfoBar, resultEntryDialog, resultSelDialog, emptyREO, inputBoxDialog, CConfirmationMsg, CVerifyValidationData, emptyVVD, confirmationDialog } from "../../types";
import RequestInfoBar from "../ReqInfoBar/RequestInfoBar";
import ResultSelDialog from "./ResultSelDialog";
import axios from 'axios';
import UserSession from "../UserSession";
import { Input } from "@progress/kendo-react-inputs";
import { InputBoxDialog } from "../InputBoxDialog/InputBoxDialog";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import ProgressBar from "../Misc/ProgressBar";

var mainResultData: CResultEntry[] = ([]);

const GridContext = React.createContext({});
const allInEdit = mainResultData.map((item) =>
    Object.assign({ inEdit: true }, item)
);

const GridDataCellNumResult = (cellProps: any) => {
    const [comboResultValue, setComboResultValue] = useState<CRequestResult>();;

    const { resultMainData }: any = React.useContext(GridContext);
    const { resultData }: any = React.useContext(GridContext);
    const { setResultData }: any = React.useContext(GridContext);

    if (cellProps.dataItem.bDisableResultEntry === true || cellProps.dataItem.bLockRow === true) {
        return (
            <td></td>
        );
    };

    const GridDataCellChange = (e: any) => {
        const dataItem = e.dataItem ?? e.target.props.dataItem;
        const field = e.field ?? e.target.props.field;
        setResultData((oldGridData: any) =>
            oldGridData.map((item: any) => {
                if (item.sParamID === dataItem.sParamID) {
                    item[field || ''] = e.value;
                }
                return item;
            })
        );
    };

    const handleResultComboChange = (e: DropDownListChangeEvent) => {
        var obj: CRequestResult = e.value
        setComboResultValue(e.value);
        setResultData((oldGridData: any) =>
            oldGridData.map((item: any) => {
                if (item.sParamID === obj.sParamId) {
                    item.sSerIDToValidate = obj.sSerID;
                    item.sResultComm = obj.sResultComm;
                    item.sCommText = obj.sCommText;
                    item.sResultText = obj.sResultText;
                    item.sResultFlag = obj.sResultFlag;
                    item.sInterpretationDesc = obj.sResult;
                }
                console.log('item', item);
                return item;
            })
        );
    };
    if (cellProps.dataItem['bDisableResultEntry'] === true) {
        return (
            <td></td>
        );
    }
    else {

        if (cellProps.dataItem.ExistingResult.length > 1 && (cellProps.dataItem.sParamStatus === '3' || cellProps.dataItem.sParamStatus === '63')) {
            return (
                <td>
                    <DropDownList
                        data={cellProps.dataItem.ExistingResult}
                        textField="sRawResult"
                        dataItemKey="sSerID"
                        value={comboResultValue}
                        onChange={handleResultComboChange}
                        style={{ width: "100%" }}
                    />
                </td>
            );
        } else if (cellProps.dataItem.sParamStatus === '1' || cellProps.dataItem.sParamStatus === '2') {
            return (
                <td>
                    <Input
                        {...cellProps}
                        disabled={(cellProps.dataItem.sParamStatus === '1' || cellProps.dataItem.sParamStatus === '2') ? false : true}
                        value={cellProps.dataItem.sRawResult}
                        onChange={GridDataCellChange}
                        onBlurCapture={() => ValidateNumResult(cellProps.dataItem, resultData, setResultData, resultMainData)}
                        style={{ textAlign: 'right' }}
                        width="100%"
                    />
                </td>
            );
        } else {
            return (
                <td>
                    <Input
                        {...cellProps}
                        disabled={true}
                        value={cellProps.dataItem.sRawResult}
                        style={{ textAlign: 'right' }}
                        width="100%"
                    />
                </td>
            );
        };
    }
};

const CustomCellEditCompType = (cellProps: any) => {
    const [comboResultValue, setComboResultValue] = useState<CRequestResult>();;

    const { resultMainData }: any = React.useContext(GridContext);
    const { resultData }: any = React.useContext(GridContext);
    const { setResultData }: any = React.useContext(GridContext);

    if (resultMainData.sProfileResultType === 'BC' || resultMainData.sProfileResultType === 'EB') {
        if (cellProps.dataItem.sResultType === 'BC' || cellProps.dataItem.sResultType === 'RD') {

        } else {
            return (
                <td></td>
            );
        };
    };

    const GridDataCellChange = (e: any) => {
        const dataItem = e.dataItem ?? e.target.props.dataItem;
        const field = e.field ?? e.target.props.field;
        setResultData((oldGridData: any) =>
            oldGridData.map((item: any) => {
                if (item.sParamID === dataItem.sParamID) {
                    item[field || ''] = e.value;
                }
                return item;
            })
        );
    };

    const handleResultComboChange = (e: DropDownListChangeEvent) => {
        var obj: CRequestResult = e.value
        setComboResultValue(e.value);
        setResultData((oldGridData: any) =>
            oldGridData.map((item: any) => {
                if (item.sParamID === obj.sParamId) {
                    item.sSerIDToValidate = obj.sSerID;
                    item.sResultComm = obj.sResultComm;
                    item.sCommText = obj.sCommText;
                    item.sResultText = obj.sResultText;
                    item.sResultFlag = obj.sResultFlag;
                    item.sInterpretationDesc = obj.sResult;
                    item.sCompType =obj.sCompType
                }
                //console.log('item', item);
                return item;
            })
        );
    };
    if (cellProps.dataItem['bDisableResultEntry'] === true || cellProps.dataItem['bLockRow'] === true) {
        return (
            <td></td>
        );
    }
    else {

        if (cellProps.dataItem.ExistingResult.length > 1 && (cellProps.dataItem.sParamStatus === '3' || cellProps.dataItem.sParamStatus === '63')) {
            return (
                <td>
                    <DropDownList
                        data={cellProps.dataItem.ExistingResult}
                        textField="sCompType"
                        dataItemKey="sSerID"
                        value={comboResultValue}
                        onChange={handleResultComboChange}
                        style={{ width: "100%" }}
                    />
                </td>
            );
        } else if (cellProps.dataItem.sParamStatus === '1' || cellProps.dataItem.sParamStatus === '2') {
            return (
                <td>
                    <Input
                        {...cellProps}
                        disabled={(cellProps.dataItem.sParamStatus === '1' || cellProps.dataItem.sParamStatus === '2') ? false : true}
                        value={cellProps.dataItem.sCompType}
                        onChange={GridDataCellChange}
                        onBlurCapture={() => ValidateNumResult(cellProps.dataItem, resultData, setResultData, resultMainData)}
                        style={{ textAlign: 'right' }}
                        width="100%"
                    />
                </td>
            );
        } else {
            return (
                <td>
                    <Input
                        {...cellProps}
                        disabled={true}
                        value={cellProps.dataItem.sCompType}
                        style={{ textAlign: 'right' }}
                        width="100%"
                    />
                </td>
            );
        };
    }
};

const ValidateNumResult = async (cellProps: any, mainResultData: CResultEntry[], setResultData: any, resultMainData: any) => {
    const obj: CResultEntry = cellProps;
    const binddata: CResultEntry[] = [...mainResultData];
    //console.log(binddata);
    const response = await axios.post(UserSession.getSystemSettings().API_URL + 'serology/validateresult',
        JSON.stringify({
            objREO: resultMainData,objResultEntry: obj, collRatioValLevel: resultMainData.collRatioValLevel 
        }),
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                'Content-Type': 'application/json'
            },
            withCredentials: false
        }
    );
    if (response?.data !== null) {
        var objRO = response?.data
        var Msg: string = objRO.objREO.sErrorMsg;
        if (Msg === undefined) Msg = "";        
        if (objRO.objResultEntry.bInvalidCode === true || Msg !== "") alert(Msg!== "" ? objRO.objREO.sErrorMsg : mui(userRights, "common", "msgInvalidCode", "Invalid Code!"));
        objRO.objResultEntry.bInvalidCode = false;
        for (let i = 0; i < binddata.length; i++) {
            if (binddata[i].sParamID === objRO.objResultEntry.sParamID && binddata[i].bDisableResultEntry === false) {
                binddata[i] = JSON.parse(JSON.stringify(objRO.objResultEntry));
                break;
            };
        };
        setResultData(binddata);
    };           
};

const GridDataCellResultComm = (cellProps: any) => {
    const { resultMainData }: any = React.useContext(GridContext);
    const { resultData }: any = React.useContext(GridContext);
    const { setResultData }: any = React.useContext(GridContext);

    if (cellProps.dataItem.bDisableResultEntry === true || cellProps.dataItem.sMachineDiffYN === "1") {
        return (
            <td></td>
        );
    };

    const GridDataCellChange = (e: any) => {
        const dataItem = e.dataItem ?? e.target.props.dataItem;
        const field = e.field ?? e.target.props.field;
        setResultData((oldGridData: any) =>
            oldGridData.map((item: any) => {
                if (item.sParamID === dataItem.sParamID) {
                    item[field || ''] = e.value;
                }
                return item;
            })
        );
    };

    var sResultComm: string = "";
    if ((cellProps.dataItem.sParamStatus === '1' || cellProps.dataItem.sParamStatus === '2')) {
        sResultComm = cellProps.dataItem.sResultComm;
    } else if (cellProps.dataItem.sParamStatus === '3' && cellProps.dataItem.ExistingResult.length === 1) {
        sResultComm = cellProps.dataItem.sResultComm;
    } else if (cellProps.dataItem.ExistingResult.length === 1) {
        sResultComm = cellProps.dataItem.ExistingResult[0].sResultComm;
    } else if (cellProps.dataItem.ExistingResult.length > 1) {
        sResultComm = cellProps.dataItem.sResultComm;
    };
    if (cellProps.dataItem['bDisableResultEntry'] === true) {
        return (
            <td></td>
        );
    }
    else {
        return (
            <td>
                <Input
                    {...cellProps}
                    disabled={false}
                    value={sResultComm}
                    onChange={GridDataCellChange}
                    onBlurCapture={() => ValidateResultComm(cellProps.dataItem, resultData, setResultData, resultMainData)}
                    width="100%"
                />
            </td>
        );
    }
};

const ValidateResultComm = async (cellProps: any, mainResultData: CResultEntry[], setResultData: any, resultMainData: any) => {
    var code: string = "";
    var binvalidcode: boolean;
    const obj: CResultEntry = cellProps;
    const binddata: CResultEntry[] = [...mainResultData];
    //console.log(binddata);
    const response = await axios.post(UserSession.getSystemSettings().API_URL + 'general/validateresultcomm',
        JSON.stringify({
            mandantid: obj.sMandantID, resultcomm: obj.sResultComm, desc: "", binvalidcode: false
        }),
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                'Content-Type': 'application/json'
            },
            withCredentials: false
        }
    );
    if (response?.data !== null) {
        var objRCO = response?.data
        if (objRCO.binvalidcode === true) {
            alert(mui(userRights, "common", "msgInvalidCode", "Invalid Code!"));
        } else {
            for (let i = 0; i < binddata.length; i++) {
                if (binddata[i].sParamID === obj.sParamID) {
                    binddata[i].sResultComm = objRCO.resultcomm;
                    break;
                };
            };
            setResultData(binddata);
        };
    };
};
const SerologyResultAndValidation = (props: resultEntryDialog) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'serologyResultAndValidation' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const ref_divButtons = useRef<HTMLDivElement>(null);
    const ref_divReqInfoBar = useRef<HTMLDivElement>(null);
    const ref_divGrid = useRef<HTMLDivElement>(null);
    const ref_divprofile = useRef<HTMLDivElement>(null);

    const dlgEmptyState: resultSelDialog = {
        selType: "", objRE: emptyREData, selqualresult: false, showDialog: false, CodeSelected: () => { }
    }
    const [resultSelDialogData, setResultSelDialogData] = useState<resultSelDialog>(dlgEmptyState);
    const dlgMainResultDataEmptyState: CResultEntryOutput = emptyREO;

    //const [vVD, setVVD] = useState<CVerifyValidationData>(emptyVVD);
    const [resultMainData, setResultMainData] = useState<CResultEntryOutput>(dlgMainResultDataEmptyState);
    const [resultData, setResultData] = useState<CResultEntry[]>([]);
    const [profileData, setProfileData] = useState<ProfileDropdownData[]>([]);
    const [selProfile, setSelProfile] = useState<ProfileDropdownData>();

    const [visible, setVisible] = useState<boolean>(props.showDialog);
    const [isLoading, setIsLoading] = useState(false)
    const [reqInfo, setReqInfo] = useState<ReqInfoBar | null>(null);

    const [disableSaveButton, setDisableSaveButton] = useState<boolean | false>(true);
    const [disableValidationButton, setDisableValidationButton] = useState<boolean | false>(true);

    const [resultSaved, setResultSaved] = useState<boolean | false>(false);
    const [resultValidated, setResultValidated] = useState<boolean | false>(false);
        
    const dlgEmptyConfirmationDialogState: confirmationDialog = {        
        obj: emptyVVD, showDialog: false, SelectedOptions: () => {}
    };
                
    //const dlgEmptyInputBoxState: inputBoxDialog = {
    //    promptText: "", title: "", maxLength: 255, setText: () => {}, showDialog: false
    //};
    //const [inputBoxDialogData, setInputBoxDialogData] = useState<inputBoxDialog>(dlgEmptyInputBoxState);
    const [confirmationDialogData, setConfirmationDialogData] = useState<confirmationDialog>(dlgEmptyConfirmationDialogState);

    const callbackReqInfo = async (objReqInfo: ReqInfoBar) => {
        setReqInfo(objReqInfo);
    };

    var gridHt = 0;
    var rootHeight = 900;
    var buttonHeight = 0;
    var reqInfoBarHeight = 0;
    var profileBtnHeight = 0;

    //console.log('refs ', ref_divButtons, ref_divReqInfoBar, ref_divGrid);

    if (ref_divButtons !== undefined) buttonHeight = Number(ref_divButtons.current?.clientHeight);
    if (ref_divReqInfoBar !== undefined) reqInfoBarHeight = Number(ref_divReqInfoBar.current?.clientHeight);
    if (ref_divprofile !== undefined) profileBtnHeight = Number(ref_divprofile.current?.clientHeight);
    if (isNaN(buttonHeight))
        buttonHeight = 30;
    if (isNaN(reqInfoBarHeight))
        reqInfoBarHeight = 210;
    if (isNaN(profileBtnHeight))
        profileBtnHeight = 28;
    //console.log('rootHeight', rootHeight);
    //console.log('buttonHeight', buttonHeight);
    //console.log('reqInfoBarHeight', reqInfoBarHeight);
    //console.log('profileBtnHeight', profileBtnHeight);
    gridHt = rootHeight - (buttonHeight + reqInfoBarHeight + profileBtnHeight + 100);
    /*console.log('gridHeight new old ', gridHt, ref_divGrid.current?.style.height);       */

    const AddParamClick = async () => {

    };

    const ValidateClick = async (objVVD: CVerifyValidationData) => {
        var bRefreshGrid: boolean = false;
        //if (await VerifyData("Validate") === true) {
           
        //};
        setIsLoading(true);        
        if (resultMainData !== undefined) {
            resultMainData.listResEntry = resultData;
            resultMainData.sUserID = UserSession.getLoggedInUser().UserID;
        };
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'serology/validaterequestresultdata',
            JSON.stringify({
                objREO: resultMainData, objVVD: objVVD
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );

        // console.log(response?.data);
        var maindata: CResultEntryOutput | undefined;
        var binddata: CResultEntry[] = [];
        if (response?.data !== null) {
            maindata = response?.data.objREO;
            //setVVD(response?.data.objVVD);
        };
        if (maindata !== undefined) {
            if (maindata.sErrorMsg !== "") {
                alert(maindata.sErrorMsg);
            } else {
                setDisableSaveButton(true);
                setDisableValidationButton(true);
                bRefreshGrid = maindata.bRefreshGrid;
                maindata.listResEntry.forEach((o: CResultEntry) => {
                    if (o.sParamStatus === "1" || o.sParamStatus === "2") setDisableSaveButton(false);
                    if (o.sParamStatus === "3") setDisableValidationButton(false);
                    binddata.push({ ...o });
                });

                setResultData(binddata);
                setResultValidated(true);
                //mainResultData = binddata;
            };
        };

        if (bRefreshGrid === true || props.selprofilecode !== "") {
            CancelClick();
        } else {
            GetProfiles();
        };
        setIsLoading(false);
    };

    //const setText = async (inputText: string) => {
    //    if (inputText !== "") {          
    //        setVVD(prevData => ({
    //            ...prevData,
    //            ['sReactionReason']: inputText
    //        }));
    //    };
        
    //    setInputBoxDialogData(dlgEmptyInputBoxState);
    //};

    const VerifyData= async (sMode: string) => {
        var bok: boolean = false;        
        var objVVD: CVerifyValidationData = emptyVVD;
        objVVD.sMandantID = UserSession.getLoggedInUser().CurrentMandant;
        objVVD.sMode = sMode;
        if (resultMainData !== undefined) resultMainData.listResEntry = resultData;
        objVVD.objREO = resultMainData;
               
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'serology/verifydata',
            JSON.stringify({
                sMandantID: objVVD.sMandantID, objREO: objVVD.objREO, sMode: objVVD.sMode,
                bValidateIncompatibleComp: objVVD.bValidateIncompatibleComp, bAddBldGrpRhesusToPatient: objVVD.bAddBldGrpRhesusToPatient,
                bAddPhenoTypeToPatient: objVVD.bAddPhenoTypeToPatient, bSetCompFreeAndProceedWithValidation: objVVD.bSetCompFreeAndProceedWithValidation,
                bResultEnteredRDParam: objVVD.bResultEnteredRDParam, bResultEnteredBCParam: objVVD.bResultEnteredBCParam,
                bBloodComponentExists: objVVD.bBloodComponentExists, bDataChanged: objVVD.bDataChanged, nMsgAntigenDoesNotMatch: objVVD.nMsgAntigenDoesNotMatch,
                nMsgBldGrpRhsOfPatAndCompDoesNotMatch: objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch, nMsgBldGrpRhsOfPatAndCompDoesNotMatch1: objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch1,
                nMsgBldGrpOfResultAndComponentDoesNotMatch: objVVD.nMsgBldGrpOfResultAndComponentDoesNotMatch, nMsgRhesusOfResultAndPatientDoesNotMatch: objVVD.nMsgRhesusOfResultAndPatientDoesNotMatch,
                nMsgRhesusOfResultAndComponentDoesNotMatch: objVVD.nMsgRhesusOfResultAndComponentDoesNotMatch, nMsgAcceptBldGrpAndRhesus: objVVD.nMsgAcceptBldGrpAndRhesus,
                nMsgAcceptPhenoTypeYN: objVVD.nMsgAcceptPhenoTypeYN, nGetReactionReason: objVVD.nGetReactionReason,
                sReactionReason: objVVD.sReactionReason, sConfirmationMsg: objVVD.sConfirmationMsg
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== null) {
            bok = true;
            objVVD = response?.data;
          
            if (objVVD.ConfirmationMsg.length > 0 || objVVD.nGetReactionReason === 1) {
                const SelectedOpts = (obj: CVerifyValidationData) => {
                    if (obj !== undefined) {
                        console.log(obj);
                        var objCM: CConfirmationMsg;
                        for (objCM of obj.ConfirmationMsg) {
                            if (objCM.msgid === "MsgAntigenDoesNotMatch") {
                                obj.nMsgAntigenDoesNotMatch = Number(objCM.action);
                            } else if (objCM.msgid === "MsgBldGrpRhsOfPatAndCompDoesNotMatch") {
                                objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch = Number(objCM.action);
                            } else if (objCM.msgid === "MsgBldGrpRhsOfPatAndCompDoesNotMatch1") {
                                objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch1 = Number(objCM.action);
                            } else if (objCM.msgid === "MsgBldGrpOfResultAndComponentDoesNotMatch") {
                                objVVD.nMsgBldGrpOfResultAndComponentDoesNotMatch = Number(objCM.action);
                            } else if (objCM.msgid === "MsgRhesusOfResultAndPatientDoesNotMatch") {
                                objVVD.nMsgRhesusOfResultAndPatientDoesNotMatch = Number(objCM.action);
                            } else if (objCM.msgid === "MsgRhesusOfResultAndComponentDoesNotMatch") {
                                objVVD.nMsgRhesusOfResultAndComponentDoesNotMatch = Number(objCM.action);
                            } else if (objCM.msgid === "MsgAcceptBldGrpAndRhesus") {
                                objVVD.nMsgAcceptBldGrpAndRhesus = Number(objCM.action);
                            } else if (objCM.msgid === "MsgAcceptPhenoTypeYN") {
                                objVVD.nMsgAcceptPhenoTypeYN = Number(objCM.action);
                            };
                            if (objCM.action === "7") {
                                bok = false;
                            };
                        };
                    };
                    if (bok === true) {
                        //setVVD(obj);
                        if (sMode === "V") {
                            ValidateClick(obj);
                        } else {
                            SaveClick(sMode, obj);
                        };
                    };
                    setConfirmationDialogData(dlgEmptyConfirmationDialogState);
                };
                setConfirmationDialogData({
                    obj: objVVD, showDialog: true, SelectedOptions: SelectedOpts
                });
            } else {
                if (bok === true) {
                    //setVVD(obj);
                    if (sMode === "V") {
                        ValidateClick(objVVD);
                    } else {
                        SaveClick(sMode, objVVD);
                    };
                };
            };                                  
        };
        //return bok;
    };

    const SaveClick = async (sMode: string, objVVD: CVerifyValidationData) => {
        /*if (await VerifyData("Save") === false) return;*/
        const binddata: CResultEntry[] = [...resultData];
        var obj: CResultEntry;
        var sAddnURL: string;
        if (sMode === 'S') {
            sAddnURL = 'serology/saverequestresultdata';
        } else {
            sAddnURL = 'serology/rerunparam';
        };
        for (obj of binddata) {
            if (obj.sRawResult === "" && obj.bDisableResultEntry === false) {
                alert(mui(userRights, "common", "msgNoBlankResult", "Please enter all results!"));
                return;
            };
        };
        setIsLoading(true);
        if (resultMainData !== undefined) {
            resultMainData.listResEntry = resultData;
            resultMainData.sUserID = UserSession.getLoggedInUser().UserID;
        };
        const response = await axios.post(UserSession.getSystemSettings().API_URL + sAddnURL,
            JSON.stringify({
                objREO: resultMainData, objVVD: objVVD
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        var maindata: CResultEntryOutput | undefined;
        if (response?.data !== null) {
            maindata = response?.data.objREO;
            //setVVD(response?.data.objVVD);
        }
        if (maindata !== undefined) {
            if (maindata.sErrorMsg !== "") {
                alert(maindata.sErrorMsg);
            } else {
                setDisableSaveButton(true);
                setDisableValidationButton(true);
                binddata.length = 0;
                maindata.listResEntry.forEach((o: CResultEntry) => {
                    if (o.sParamStatus === "1" || o.sParamStatus === "2") setDisableSaveButton(false);
                    if (o.sParamStatus === "3") setDisableValidationButton(false);
                    binddata.push({ ...o });
                });                   
                setResultData(binddata);
                setResultSaved(true);
            };
        };
       
        setIsLoading(false);
    };

    const CancelClick = async () => {
        var bok: boolean = false;
        if (resultSaved === true || resultValidated === true) bok = true;
        console.log('saved\validated', bok);
        props.ResultSaved(bok);
        setVisible(false);
    };

    //const SetSelProfile = (event: DropDownListChangeEvent) => {
    //    setSelProfile(event.value);
    //    GetResultData(event.value);
    //};

    const ProfileClick = (code: string) => {
        if (code !== selProfile?.code) {
            profileData.forEach((obj: ProfileDropdownData) => {
                if (obj.code === code) {
                    var arr: CResultEntry[] = []
                    setResultData(arr);
                    var arr1: CResultEntryOutput = (dlgMainResultDataEmptyState);
                    setResultMainData(arr1);
                    setSelProfile(obj);
                    GetResultData(obj);
                    obj.color = "inverse"
                } else obj.color = "primary";
            });
        };
    };

    const CustomCellEditReRun = (props: GridCellProps) => {
        const objRE: CResultEntry = props.dataItem;
        var disabled: boolean = true;
        if (Number(objRE.sParamStatus) > 2) disabled = false;
        if (objRE.bDisableResultEntry === true) {
            return (
                <td></td>
            );
        }
       else {
    return (
        <td style={{ textAlign: 'center', padding: '1px' }} >
            <Button disabled={disabled} title={mui(userRights, "common", "ttRerunParam", "Rerun Param")} className="buttons-container-button" icon="refresh"
                onClick={() => {
                    VerifyData("RR")
                }}
            >
            </Button>
        </td>
    );
}
    };

    const ReRunParam = async (objRE: CResultEntry) => {
    };

    const CustomCellEditParam = (props: GridCellProps) => {
        const paramid = props.dataItem['sParamID'];
        const paramdesc = props.dataItem['sParamDesc'];
        const bDisableResultEntry = props.dataItem['bDisableResultEntry'];
            return (
                <td style={{ fontWeight: bDisableResultEntry === true ? 'bold' : '' }} title={paramid}>
                    {paramdesc}
                </td>
            );

    };

    const CustomCellPreVal1 = (props: GridCellProps) => {
        const value = props.dataItem['sPreVal1'];
        const tttext = props.dataItem['sPreVal1Tooltip'];
        if (props.dataItem['bDisableResultEntry'] === true) {
            return (
                <td></td>
            );
        }
        else {
            return (
                <td title={tttext}>
                    {value}
                </td>
            );
        }
    };

    const CustomCellPreVal2 = (props: GridCellProps) => {
        const value = props.dataItem['sPreVal2'];
        const tttext = props.dataItem['sPreVal2Tooltip'];
        if (props.dataItem['bDisableResultEntry'] === true) {
            return (
                <td></td>
            );
        }
        else {
            return (
                <td title={tttext}>
                    {value}
                </td>
            );
        }
    };

    const CustomCellPreVal3 = (props: GridCellProps) => {
        const value = props.dataItem['sPreVal3'];
        const tttext = props.dataItem['sPreVal3Tooltip'];
        if (props.dataItem['bDisableResultEntry'] === true) {
            return (
                <td></td>
            );
        }
        else {
            return (
                <td title={tttext}>
                    {value}
                </td>
            );
        }
    };

    const CustomCellEditResultSel = useCallback((propsG: GridCellProps) => {
        const status = propsG.dataItem['sParamStatus'];
        if (propsG.dataItem['bDisableResultEntry'] === true || propsG.dataItem['sPercentYN'] === "1") {
            return (
                <td></td>
            );
        }
        else {
            return (
                <td>
                    <Button
                        type={"button"}
                        style={{ textAlign: 'center', width: '25px' }}
                        themeColor={'primary'}
                        onClick={() => { onResSelClick(propsG.dataItem, 'open') }}
                        disabled={(status === '1' || status === '2') ? false : true}
                    >
                        ...
                    </Button>

                </td>
            );
        }
    }, [resultData]);

    const onResSelClick = useCallback((objRE: CResultEntry, action: string) => {
        const CodeSel = (Code: string, Desc: string) => {
            if (Code !== "") {
                console.log('objRE', objRE);
                objRE.sRawResult = Code;
                if (Code.toUpperCase() === "FT") {
                    objRE.sResultText = Desc;
                } else {
                    objRE.sResultText = "";
                };
                ValidateResult(objRE, false);
            }
            setResultSelDialogData(dlgEmptyState);
        }
        //if (action === 'open')
        //    setBackdrop('');
        //else
        //    setBackdrop('none');
        setResultSelDialogData({ selType: "Result", objRE: objRE, selqualresult: false, showDialog: action === 'open' ? true : false, CodeSelected: CodeSel });
    }, [resultData]);

    const ValidateResult = async (objRE: CResultEntry, bQualResult: boolean) => {
        const binddata: CResultEntry[] = [...resultData];

        setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'serology/validateresult',
            JSON.stringify({
                objREO: resultMainData, objResultEntry: objRE, collRatioValLevel: resultMainData?.collRatioValLevel, validatequalresult: bQualResult
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== null) {
            var obj = response?.data
            var Msg: string = obj.objREO.sErrorMsg;
            if (Msg === undefined) Msg = "";
            if (obj.objResultEntry.bInvalidCode === true || Msg !== "") alert(obj.objREO.sErrorMsg !== undefined ? obj.objREO.sErrorMsg : mui(userRights, "common", "msgInvalidCode", "Invalid Code!"));
            for (let i = 0; i < binddata.length; i++) {
                if (binddata[i].sParamID === obj.objResultEntry.sParamID && binddata[i].bDisableResultEntry === false) {
                    binddata[i] = JSON.parse(JSON.stringify(obj.objResultEntry));
                    break;
                };
            };
            setResultData(binddata);
        };        
        setIsLoading(false);
    };

    const CustomCellEditInterp = (props: GridCellProps) => {
        const desc = props.dataItem['sInterpretationDesc'];
        const color = props.dataItem['sResultColColor'];
        if (props.dataItem['bDisableResultEntry'] === true) {
            return (
                <td></td>
            );
        }
        else {
            return (
                <td style={{ backgroundColor: color }} title={desc}>
                    {desc}
                </td>
            );
        }
    };

    const CustomCellEditResultComm = (props: GridCellProps) => {
        const status = props.dataItem['sParamStatus'];
        if (props.dataItem['bDisableResultEntry'] === true) {
            return (
                <td></td>
            );
        }
        else {
            return (
                <td>
                    <Button
                        type={"button"}
                        style={{ textAlign: 'center', width: '25px' }}
                        themeColor={'primary'}
                        onClick={() => { onResCommSelClick(props.dataItem, 'open') }}
                        disabled={false}
                    >
                        ...
                    </Button>

                </td>
            );
        }
    };

    const onResCommSelClick = useCallback((objRE: CResultEntry, action: string) => {
        const CodeSel = (Code: string, Desc: string) => {
            if (Code !== "") {
                const binddata: CResultEntry[] = [...resultData];
                var obj: CResultEntry;
                for (obj of binddata) {
                    if (obj.sParamID === objRE.sParamID && obj.bDisableResultEntry === false) {
                        obj.sResultComm = Code;
                        if (Code.toUpperCase() === "FT") {
                            objRE.sCommText = Desc;
                        } else {
                            objRE.sCommText = "";
                        };
                        break;
                    }
                }
                setResultData(binddata);
            }
            setResultSelDialogData(dlgEmptyState);
        };
        setResultSelDialogData({ selType: "ResultComm", objRE: objRE, selqualresult: false, showDialog: action === 'open' ? true : false, CodeSelected: CodeSel });
    }, [resultData]);
        
    const GetProfiles = async () => {        
        var arr: CResultEntry[] = []
        setResultData(arr);

        var arr1: CResultEntryOutput = (dlgMainResultDataEmptyState);
        setResultMainData(arr1);
        //setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'general/getprofiles',
            JSON.stringify({
                mandantid: props.mandantid, reqid: props.reqid, pid: props.pid, fid: props.fid, reqdatetime: props.reqdatetime,
                valtype: props.valtype, addedparams: props.addedparams, pregnancyweek: props.pregnancyweek, mbyn: props.mbyn,
                userid: props.userid, paramid: "", selprofilecode: props.selprofilecode
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== null) {
            var binddata: ProfileDropdownData[] = response?.data;
            var obj: ProfileDropdownData;
            setProfileData(binddata);
            for (obj of binddata) {
                if (obj.selected === true) {
                    obj.color = "inverse";
                    setSelProfile(obj);
                    GetResultData(obj);
                };
            };
        };
        //setIsLoading(false);
    };

    const GetResultData = async (obj: ProfileDropdownData) => {
        var arr: CResultEntry[] = []
        setResultData(arr);
        var arr1: CResultEntryOutput= (dlgMainResultDataEmptyState);
        setResultMainData(arr1);
        setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'serology/getserologydata',
            JSON.stringify({
                mandantid: props.mandantid, reqid: props.reqid, pid: props.pid, fid: props.fid, reqdatetime: props.reqdatetime, reqprovider: props.reqprovider,
                valtype: props.valtype, addedparams: props.addedparams, pregnancyweek: props.pregnancyweek, mbyn: props.mbyn, userid: props.userid, obj: obj
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        setDisableSaveButton(true);
        setDisableValidationButton(true);
        var binddata: CResultEntry[] = [];
        if (response?.data !== null) {
            setResultMainData(response?.data);
            response?.data.listResEntry.forEach((o: CResultEntry) => {
                if (o.sParamStatus === "1" || o.sParamStatus === "2") setDisableSaveButton(false);
                if (o.sParamStatus === "3") setDisableValidationButton(false);
                binddata.push({ ...o });
            });
            setResultData(binddata);
        };
        setIsLoading(false);
    };

    //const cancelDialog = () => {
    //    UpdateVVD(7, 'n' + confirmationDialogData.msgid)
    //    setConfirmationDialogData(dlgEmptyConfirmationDialogState);
    //};
    //const confirmDialog = () => {
    //    UpdateVVD(6,'n' + confirmationDialogData.msgid)
    //    setConfirmationDialogData(dlgEmptyConfirmationDialogState);
    //};

    //const UpdateVVD = (nAns: number, fieldName:string) => {
    //    setVVD(prevData => ({
    //        ...prevData,
    //        [fieldName]: nAns
    //    }));
    //    //var objVVD: CVerifyValidationData = vVD;
    //    //if (confirmationDialogData.msgid === "MsgAntigenDoesNotMatch") {
    //    //    objVVD.nMsgAntigenDoesNotMatch = nAns;
    //    //} else if (confirmationDialogData.msgid === "MsgBldGrpRhsOfPatAndCompDoesNotMatch") {
    //    //    objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch = nAns;
    //    //} else if (confirmationDialogData.msgid === "MsgBldGrpRhsOfPatAndCompDoesNotMatch1") {
    //    //    objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch1 = nAns;
    //    //} else if (confirmationDialogData.msgid === "MsgBldGrpOfResultAndComponentDoesNotMatch") {
    //    //    objVVD.nMsgBldGrpOfResultAndComponentDoesNotMatch = nAns;
    //    //} else if (confirmationDialogData.msgid === "MsgRhesusOfResultAndPatientDoesNotMatch") {
    //    //    objVVD.nMsgRhesusOfResultAndPatientDoesNotMatch = nAns;
    //    //} else if (confirmationDialogData.msgid === "MsgRhesusOfResultAndComponentDoesNotMatch") {
    //    //    objVVD.nMsgRhesusOfResultAndComponentDoesNotMatch = nAns;
    //    //} else if (confirmationDialogData.msgid === "MsgAcceptBldGrpAndRhesus") {
    //    //    objVVD.nMsgAcceptBldGrpAndRhesus = nAns;
    //    //} else if (confirmationDialogData.msgid === "MsgAcceptPhenoTypeYN") {
    //    //    objVVD.nMsgAcceptPhenoTypeYN = nAns;
    //    //};
    //    //setVVD(objVVD);
    //};

    useEffect(() => {
        //InputBoxDialogData(dlgEmptyInputBoxState);
        setConfirmationDialogData(dlgEmptyConfirmationDialogState)
        setResultSaved(false);
        setResultValidated(false);
        GetProfiles();
        setVisible(props.showDialog);
    }, [props.showDialog])

    var dlgHeight: number = window.innerHeight - 80;
    var dlgWidth: number = window.innerWidth - 40;

    return (
        <>
            <div>
                {visible && (
                    <Dialog title={<DialogTitleBar title={mui(userRights, "serologyResultAndValidation", "textTitle", "serology Result")} />} onClose={CancelClick} height={dlgHeight} closeIcon={false} width={dlgWidth} >
                        <div style={{ overflowX: 'hidden', width: '97%', marginLeft: 10, marginRight: 1 }}>
                            <div ref={ref_divButtons} className="row">
         
                                <div className="col" style={{ textAlign: "right" }}>
                                    <Button disabled={false} type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                        AddParamClick()
                                    }}>{mui(userRights, "common", "cmdAddParam", "Add Param")}</Button>&nbsp;
                                    <Button disabled={disableValidationButton} type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                        VerifyData("V");
                                    }}>{mui(userRights, "common", "cmdValidate", "Validate")}</Button>&nbsp;
                                    <Button disabled={disableSaveButton} type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                        VerifyData("S");
                                    }}>{mui(userRights, "common", "cmdSave", "Save")}</Button>&nbsp;
                                    {props.dayWorkSpace === false ?
                                        <Button type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                            CancelClick()
                                        }}>{mui(userRights, "common", "cmdCancel", "Cancel")}</Button>
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                            <div className="row" style={{ height: 1 }}></div>
                            <div ref={ref_divReqInfoBar} className="row">
                                <RequestInfoBar reqid={props.reqid} valtype={props.valtype} showmatdata={true} setSelectedReqInfo={callbackReqInfo}></RequestInfoBar>
                            </div>
                            <div ref={ref_divprofile} className="row" style={{ paddingBottom: 10 }}>
                                <div className="col">
                                    <div className="vertical-wrapper">
                                        <div className="vertical-wrapper-child"><Label editorId={'profileid'}>{mui(userRights, "common", "ProfileID", "ProfileID")}</Label></div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="vertical-wrapper">
                                        <div className="vertical-wrapper-child">
                                            <table width="100%" ><tbody>
                                                <tr>
                                                    {
                                                        profileData !== null && profileData.map((obj: ProfileDropdownData, index) => (
                                                            <td key={index}>
                                                                <Button type={"button"} style={{ width: '150px', marginRight: 10 }} themeColor={obj.color} onClick={() => {
                                                                    ProfileClick(obj.code)
                                                                }}>{obj.code}</Button>
                                                            </td>
                                                        ))
                                                    }
                                                </tr>
                                            </tbody>
                                            </table>                                           
                                            {/*<DropDownList*/}
                                            {/*    data={profileData}*/}
                                            {/*    textField="code"*/}
                                            {/*    id="code"*/}
                                            {/*    value={selProfile}*/}
                                            {/*    onChange={SetSelProfile}                                                */}
                                            {/*/>&nbsp;                                            */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col"></div>
                                <div className="col"></div>
                                <div className="col"></div>
                                <div className="col"></div>
                            </div>
                            <div ref={ref_divGrid} className="row">
                                <div className="col">
                                    {isLoading ? <ProgressBar message=""></ProgressBar> : 
                                    <GridContext.Provider
                                        value={{ setResultData, resultData, resultMainData }}
                                    >
                                        
                                        {/*{confirmationDialogData.visible && (*/}
                                        {/*    <Dialog title={confirmationDialogData.title} onClose={cancelDialog} height={200} width={700}>*/}
                                        {/*        <p style={{ margin: "5px", textAlign: "left" }}>*/}
                                        {/*            {confirmationDialogData.msg}*/}
                                        {/*        </p>*/}
                                        {/*        <DialogActionsBar>*/}
                                        {/*            <button*/}
                                        {/*                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"*/}
                                        {/*                onClick={cancelDialog}*/}
                                        {/*            >*/}
                                        {/*                {mui(userRights, "common", "btnNo", "No")}*/}
                                        {/*            </button>*/}
                                        {/*            <button*/}
                                        {/*                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"*/}
                                        {/*                onClick={confirmDialog}*/}
                                        {/*            >*/}
                                        {/*                {mui(userRights, "common", "btnYes", "Yes")}*/}
                                        {/*            </button>*/}
                                        {/*        </DialogActionsBar>*/}
                                        {/*    </Dialog>*/}
                                        {/*)}*/}
                                        <Grid
                                            style={{
                                                height: gridHt, width: '100%', marginLeft: 2, marginRight: 0
                                            }}
                                            data={resultData}
                                            dataItemKey={"sParamID"}
                                            editField="inEdit"
                                        >
                                            <GridColumn field="" title={mui(userRights, "serologyResultAndValidation", "col_btnReRun", "")} width="50px" cell={CustomCellEditReRun} />
                                            <GridColumn field="sParamDesc" title={mui(userRights, "serologyResultAndValidation", "col_ParamDesc", "Param")} width="120px" cell={CustomCellEditParam} />
                                            <GridColumn field="sPreVal1" title={mui(userRights, "serologyResultAndValidation", "col_PreVal1", "PreVal1")} cell={CustomCellPreVal1} />
                                            <GridColumn field="sPreVal2" title={mui(userRights, "serologyResultAndValidation", "col_PreVal2", "PreVal2")} cell={CustomCellPreVal2} />
                                            <GridColumn field="sPreVal3" title={mui(userRights, "serologyResultAndValidation", "col_PreVal3", "PreVal3")} cell={CustomCellPreVal3} />
                                            <GridColumn field="sRawResult" title={mui(userRights, "serologyResultAndValidation", "col_Result", "Result")} width="125px" cell={GridDataCellNumResult} />
                                            <GridColumn field="" title={mui(userRights, "serologyResultAndValidation", "col_ResultSel", " ")} width="50px" cell={CustomCellEditResultSel} />
                                            <GridColumn field="sCompType" title={mui(userRights, "serologyResultAndValidation", "col_CompType", "CompType")} width={resultMainData.sProfileResultType === 'BC' || resultMainData.sProfileResultType === 'EC'?"125px":"0"} cell={CustomCellEditCompType} />
                                            <GridColumn field="sInterpretationDesc" title={mui(userRights, "serologyResultAndValidation", "col_Interpretation", "Interpretation")} width="200px" cell={CustomCellEditInterp} />
                                            {/*<GridColumn field="sResultColColor" title={mui(userRights, "serologyResultAndValidation", "col_ResColor", " ")} width="50px" cell={CustomCellEditResultColor} />*/}
                                            <GridColumn field="sResultComm" title={mui(userRights, "serologyResultAndValidation", "col_ResultComm", "Result Comm")} width="100px" cell={GridDataCellResultComm} />
                                            <GridColumn field="" title={mui(userRights, "serologyResultAndValidation", "col_ResultCommSel", " ")} width="50px" cell={CustomCellEditResultComm} />
                                            <GridColumn field="sRefValue" title={mui(userRights, "serologyResultAndValidation", "col_RefValue", "Ref Value")} width="90px" />
                                            <GridColumn field="sUnit" title={mui(userRights, "serologyResultAndValidation", "col_Unit", "Unit")} />
                                            );
                                        </Grid>
                                        </GridContext.Provider>
                                    }
                                </div>
                            </div>
                        </div>
                    </Dialog>
                )}
            </div>
            <Suspense fallback={<Loader />}>
                <ResultSelDialog selType={resultSelDialogData.selType} objRE={resultSelDialogData.objRE} selqualresult={resultSelDialogData.selqualresult} showDialog={resultSelDialogData.showDialog}
                    CodeSelected={resultSelDialogData.CodeSelected}></ResultSelDialog>
                {/*<RequestDialog key={'R'} data={requestDialogData} callbackRequestDialogClosed={callbackRequestDialogClosed}></RequestDialog>*/}
                {/*<InputBoxDialog promptText={inputBoxDialogData.promptText} title={inputBoxDialogData.title} maxLength={inputBoxDialogData.maxLength} setText={inputBoxDialogData.setText} showDialog={inputBoxDialogData.showDialog} ></InputBoxDialog>*/}
                <ConfirmationDialog obj={confirmationDialogData.obj} showDialog={confirmationDialogData.showDialog} SelectedOptions={confirmationDialogData.SelectedOptions}></ConfirmationDialog>
            </Suspense>
        </>
    );
}
export default SerologyResultAndValidation;