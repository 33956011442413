import React, { Suspense } from "react";
import { useRef, useEffect, useState } from "react";
import axios from 'axios';
import UserSession from "../UserSession";
import { CUserRightsAppl, ReqInfoBar, requestDialogData, COneRequest, addressDialogData, dlgScannerUIEmptyState, patientReportsDialog, dlgPatRepEmptyState, ItemsToPrintDialogData, emptyItemsToPrintData, CRequest, emptyRequest, ReqSplCommentDialog, emptyReqSplCommentDialog, enmScanning, CScanProperties, emptyScanProperties  } from "../../types";
import { loadFromStorage, mui, useIntersection } from "../../Helper";
import { ListView, ListViewHeader, ListViewItemProps } from "@progress/kendo-react-listview";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import RequestDialog from "../RequestEntry/RequestDialog";
import AddressDialog from "../ModalForms/AddressDialog/AddressDialog";
import ScannerUI, { scannerUIProps } from "../ModalForms/ScannerUI/ScannerUI";
import { ContextMenu, MenuItem } from "@progress/kendo-react-layout";
import { Offset } from "@progress/kendo-react-popup";
import PatientReports from "../PatientReports/PatientReports";
import ItemsToPrint from "../Printing/ItemsToPrint";
import RequestSpecialCommentDialog from "../RequestEntry/RequestSpecialCommentDialog";

interface ReqInfoProps {
    reqid: string;
    valtype: string;
    setSelectedReqInfo(objReqInfo: ReqInfoBar): any;
    showmatdata: boolean;
}

const RequestInfoBar = (props: ReqInfoProps) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'common' === ura.sFormName;
        }) : null;

    var reqinfo : ReqInfoBar={
        mandantid: "",
        mandantdesc:"",
        reqid: "",
        reqdatetime: "",
        reqdate: "",
        reqtime: "",
        reqnr: "",
        reqprovider: "",
        reqproviderdesc: "",
        handprofcount: "",
        absneutrophile: "",
        pid: "",
        piddesc: "",
        lastname: "",
        firstname:"",
        fid: "",
        admdate: "",
        admtime: "",
        admdatetime: "",
        dischargedate: "",
        dischargetime: "",
        dischargedatetime: "",
        patkind: "",
        patkinddesc: "",
        deptcode: "",
        deptname: "",
        stationcode: "",
        stationname: "",
        room: "",
        roomdesc: "",
        bed: "",
        birthdate: "",
        gender: "",
        genderdesc: "",
        weight: "",
        height: "",
        edtaphenomena: "",
        latestaksinfo:"",
        bginfo:"",
        telnr: "",
        pregweeks: "",
        anticoag: "",
        heparin: "",        
        reqcomment: "",
        reqcommentcolor: "",
        reqextadd: "",
        emergency: false,
        emergencycolor: "",
        age: "",
        compgroupinfo: "",
        sc1color: "",
        sc2color: "",
        defsc1color: "",
        defsc2color: "",
        disableshowdocbutton: true,
        disabledscancontrols: true,
        disablescanresultpdf: true,
        listmatinfo:[],
    };
    const [data, setData] = useState(reqinfo);

    // for request dialog
    const ref = useRef<HTMLDivElement>(null);
    const inViewport = useIntersection(ref, '0px'); // Trigger as soon as the element becomes visible
    const [requestDialogData, setRequestDialogData] = useState<requestDialogData>({ reqid: '', pid: '', fid: '', bloodProduct: false, showDialog: false, inViewport: inViewport, extcall: false });
    const [addressDialogData, setAddressDialogData] = useState<addressDialogData>({ loadPID: '', showDialog: false, inViewport: false });
    const [scannerUIData, setScannerUIData] = useState<scannerUIProps>(dlgScannerUIEmptyState);      
    const [patientReportsDialogData, setPatientReportsDialogData] = useState<patientReportsDialog>(dlgPatRepEmptyState);
    const [itemsToPrintData, setItemsToPrintData] = useState<ItemsToPrintDialogData>(emptyItemsToPrintData);
    const [reqSplCommData, setReqSplCommData] = useState<ReqSplCommentDialog>(emptyReqSplCommentDialog);
        
    async function FillData() {
        if (props.reqid === '')
            return;
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'reqinfobar/getinfo',
            JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, "reqid": props.reqid, "valtype": props.valtype, workstationid: loadFromStorage("workstationid") }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false,                
            }
        );
        setData(response?.data);
        props.setSelectedReqInfo(response?.data);
    };

    const matListHeader = () => {
        if (props.showmatdata === true) {
            return (
                    <tr>
                    <td style={{ width: 120, fontWeight: 'bold' }}>Material</td>
                    <td style={{ width: 150, fontWeight: 'bold' }}>ExtractionDateTime</td>
                    <td style={{ width: 150, fontWeight: 'bold' }}>MatInLabDateTime</td>
                    <td style={{ width: 80, fontWeight: 'bold' }}>CollAmt</td>
                    <td style={{ width: 80, fontWeight: 'bold' }}>CollDur</td>
                    </tr>
            );
        }
        else {
            return(<div></div>)
        }
    };

    const matItemRender = (itemprops: ListViewItemProps) => {
        if (props.showmatdata === true) {
            let item = itemprops.dataItem;
            return (
                <tr>
                    <td >
                        <div className="div-text-fixed" style={{ width: 120 }}>
                            {item.sMaterialDesc}
                        </div>
                        
                    </td>
                    <td style={{ width: 150 }}>{item.sExtractionDate + ' ' + item.sExtractionTime}</td>
                    <td style={{ width: 150 }}>{item.sMatInLabDate + ' ' + item.sMatInLabTime}</td>
                    <td style={{ width: 80 }}>{item.sCollectedAmount}</td>
                    <td style={{ width: 80 }}>{item.sCollectedDuration}</td>
                    </tr>
            );
}
        else {
            return (<div></div>)
        };        
    };

    useEffect(() => {
        FillData();        
    }, [props.reqid])
       
    const ShowDocClick = async () => {
        alert('TODO');
    };

    const PrintReportClick = async () => {
        const FormClosed = (closed: boolean) => {
            setPatientReportsDialogData(dlgPatRepEmptyState);
        }

        setPatientReportsDialogData({
            mandantid: UserSession.getLoggedInUser().CurrentMandant, pid: data.pid, showDialog: true, FormClosed: FormClosed
        })
    };

    const CwsClick = async () => {
        alert('TODO');
    };

    const SerothekClick = async () => {
        alert('TODO');
    };

    const MatQualityClick = async () => {
        alert('TODO');
    };

    const SC1Click = async () => {
        const CommSaved = (saved: boolean) => {
            if (saved) {
                var binddata: ReqInfoBar = data;
                if (binddata.sc1color === '') {
                    binddata.sc1color = binddata.defsc1color;
                    setData(binddata);
                };
            };
            setReqSplCommData(emptyReqSplCommentDialog);
        }

        setReqSplCommData({
            mandantid: data.mandantid, mandantdesc: data.mandantdesc, reqid: data.reqid, reqdate: data.reqdate, reqtime: data.reqtime,
            pid: data.pid, splcommtype: "1", showDialog: true, callbackDataSaved: CommSaved
        });
    };
    const SC2Click = async () => {
        const CommSaved = (saved: boolean) => {
            if (saved) {
                var binddata: ReqInfoBar = data;
                if (binddata.sc2color === '') {
                    binddata.sc2color = binddata.defsc2color;
                    setData(binddata);
                };
            };
            setReqSplCommData(emptyReqSplCommentDialog);
        }

        setReqSplCommData({
            mandantid: data.mandantid, mandantdesc: data.mandantdesc, reqid: data.reqid, reqdate: data.reqdate, reqtime: data.reqtime,
            pid: data.pid, splcommtype: "2", showDialog: true, callbackDataSaved: CommSaved
        });
    };
    const PatDetailsClick = async () => {
        if (data.pid !== undefined && data.pid !== "") {
            setAddressDialogData({ loadPID: data.pid, showDialog: true, inViewport: true })
        };    
    };
    // Called when the address add/edit screen is closed using the cancel or save button
    const callbackAddressDialogClosed = () => {
        setAddressDialogData({ loadPID: '', showDialog: false, inViewport: false })
    };
    const RequestClick = async () => {
        if (data === null || data === undefined) return;
        setRequestDialogData({ reqid: data.reqid, pid: data.pid, fid: data.fid, bloodProduct: false, showDialog: true, inViewport: inViewport, extcall: false })
    };
    const callbackRequestDialogClosed = (objRequest: COneRequest | null) => {
        setRequestDialogData({ reqid: '', pid: '', fid: '', bloodProduct: false, showDialog: false, inViewport: inViewport, extcall: false });        
    };
    const AddParamClick = async () => {
        if (data === null || data === undefined) return;
        setRequestDialogData({ reqid: data.reqid, pid: data.pid, fid: data.fid, bloodProduct: false, showDialog: true, inViewport: inViewport, extcall: false })
    };
    const PrintLableClick = async () => {
        if (data === null || data === undefined) return;
        var objReq: CRequest=emptyRequest;
        objReq.sReqID = data.reqid;
        objReq.sPID = data.pid;
        objReq.sFID = data.fid;
        setItemsToPrintData({
            reqData: objReq, matid: "", sformnr: "", enmwhattoprint: 2, bprintdirectly: false,
            bprintwhenrequestplan: false,
            bprintreqlabel: false,
            bprintmedienlabelsonly: false,
            bprintomrbarcodenndlabel: false,
            bfrommatinlab: false, showDialog: true
        })
    };    
    const callbackItemToPrintDialogClosed = () => {
        setItemsToPrintData(emptyItemsToPrintData)
    };
       

    // Context menu related
    const [show, setShow] = useState(false);
    const handleOnClose = () => {
        setShow(false);
    };
    const handleOnSelect = (e: any) => {
        const obj: CScanProperties = emptyScanProperties;
        switch (e.item.data.action) {
            case "scan":
                // Scan Patient data
                setScannerUIData({
                    source: enmScanning.Scan_ExternLabResults,
                    showDialog: true,
                    multipleScans: true,
                    selPDF: false,
                    objScanProperties:obj,
                    ScanningOver: () => { }
                });
                break;
            case "pdf":
                // Scan Patient PDF
                setScannerUIData({
                    source: enmScanning.Scan_ExternLabResults,
                    showDialog: true,
                    multipleScans: false,
                    selPDF: true,
                    objScanProperties: obj,
                    ScanningOver: () => { }
                });                             
                break;
        };
        setShow(false);
    };
   
    const [offset, setOffset] = useState<Offset>({ top: 0, left: 0 });
    const handleContextMenu = (e: any) => {
        setOffset({ left: e.pageX, top: e.pageY });
        e.preventDefault();
        setShow(true);
    };
    // One page scanned on twain form
    async function ScanningOver(source: string, success: boolean, data: string[], extlab: string, key2: string) {
        // console.log('AddressDialog.onePageScanned', source, success, data !== "" ? "data" : "no data");         
        if (success) {
            setScannerUIData(dlgScannerUIEmptyState);
            SaveScanData(data, extlab, key2, source);
        } else {
            setScannerUIData(dlgScannerUIEmptyState);
        };       
    };

    async function SaveScanData(arrScanData: string[], extlab: string, key2: string, filetype:string) {
        const SaveCaseData_URL = UserSession.getSystemSettings().API_URL + 'scan/SaveScanDataAsPerScanType';
        const response = await axios.post(SaveCaseData_URL,
            JSON.stringify({
                sMandantID: UserSession.getLoggedInUser().CurrentMandant, sScannedFilePathName: "",
                nPageNr: 0, enm: enmScanning.Scan_ExternLabResults, sPID: data.pid, sIDFrgCard: "", sReqID: data.reqid, sBldBgNr: "", sBldBgType: "",
                bShowDoc: true, nDocumentNr: 0, sExternLab: extlab, sKey2: key2, sMaterialID: "", sMaterialDesc: "",
                sLotID: "", bNewScanSaved: false, sFileType: filetype, sScanExtLabResultDocumentNr: "",
                arrScanData: arrScanData, sUserID: UserSession.getLoggedInUser().UserID, bDisableShowDocButton:data.disableshowdocbutton
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== null) {
            var objSC = response?.data;
            if (objSC.bNewScanSaved === true) {
                var obj: ReqInfoBar = { ...data };
                obj.disableshowdocbutton = false;
                setData(obj);
                props.setSelectedReqInfo(obj);
            };            
        };
    };            

    return (
        <>
            <div className="container-fluid" style={{ overflowX: "hidden", width: '99%', padding: 5, paddingBottom: 5 }}>
                <div className="row" style={{ border: '1px solid lightblue', marginTop: 5, marginBottom: 5, marginLeft: 1, marginRight: 1, borderRadius: 0, backgroundColor: "#e0f0ff" }}>
                    <div className="row" style={{padding: 0, margin: 0}}>
                        <div className="col" style={{ padding: 0, margin: 0 }}>
                            <div style={{ height: 130, padding: 5, marginTop: 5, marginBottom: 5, marginLeft: 5, marginRight: 0, borderRadius: 5, backgroundColor: "white" }}>
                                <div className="row" >
                                    <div className="col"> <b className="primary-color">Patient</b> </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <table cellPadding="3" style={{ width: '100%' }}>
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>FID:</td>
                                                <td>{data.fid}</td>
                                                <td style={{ fontWeight: 'bold' }}>PID:</td>
                                                <td>{data.pid}</td>                                                
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>Name:</td>
                                                <td>
                                                    <div className="div-text-fixed">
                                                        {data.lastname + ', ' + data.firstname}
                                                    </div>
                                                </td>                                               
                                                <td style={{ fontWeight: 'bold' }}>Birthdate:</td>
                                                <td>{data.birthdate + '/' + data.genderdesc}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>Station:</td>
                                                <td>{data.stationname}</td>
                                                <td style={{ fontWeight: 'bold' }}>Telefon:</td>
                                                <td>{data.telnr}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>BG Info:</td>
                                                <td>{data.bginfo}</td>
                                                <td style={{ fontWeight: 'bold' }}>AKS Info:</td>
                                                <td>{data.latestaksinfo}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                        <div className="col" style={{padding: 0, margin: 0 }}>
                            <div style={{ height: 130, padding: 5, marginTop: 5, marginBottom: 5, marginLeft: 5, marginRight: 0, borderRadius: 5, backgroundColor: "white" }}>
                                <div className="row" >
                                    <div className="col"> <b className="primary-color">Request</b> </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <table cellPadding="3" style={{ width: '100%'}}>
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>ReqID:</td>
                                                <td colSpan={2}>{data.reqid}</td>
                                                <td style={{ fontWeight: 'bold' }}>Req Date:</td>
                                                <td colSpan={2}>{data.reqdatetime}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>ReqProvider:</td>
                                                <td colSpan={5}>{data.reqproviderdesc}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>ReqNr:</td>
                                                <td colSpan={2}>{data.reqnr}</td>
                                                <td style={{ color: data.emergency ? 'red' : '', width: 100, fontWeight: 'bold' }}>Notfall:</td>
                                                <td colSpan={2}>
                                                    {data.emergency && <div style={{ width: 180 }}><button style={{ height: 15, width: 15, backgroundColor: "red" }} className="round k-icon k-font-icon"></button></div>}
                                                </td>
                                            </tr>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col" style={{ padding: 0, margin: 0 }}>
                            <div style={{ height: 130, marginTop: 5, padding: 5, marginBottom: 5, marginLeft: 5, marginRight: 0, borderRadius: 5, backgroundColor: "white" }}>
                                <div className="row" >
                                    <div className="col"> <b className="primary-color">Material</b> </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <ListView style={{ height: 80, maxHeight: 80 }}                              
                                            data={data.listmatinfo}
                                        item={matItemRender}
                                        header={matListHeader}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                                                           
                    <div className="row" style={{ height: 5 }}></div>
                    {/*<input ref={selPDFRef} style={{display: "none"}} id="selectpdf" hidden type="file" accept=".pdf" onChange={handleFileChange}  />*/}
                    <div className="row">
                        <div className="col">
                            <ContextMenu
                                vertical={true}
                                onSelect={handleOnSelect}
                                onClose={handleOnClose}
                                show={show}
                                offset={offset}
                            >
                                <MenuItem text="Scan Result" data={{ action: "scan" }} />
                                <MenuItem text="Scan PDF" data={{ action: "pdf" }} />

                            </ContextMenu>
                            <Button disabled={data.disabledscancontrols} style={{ width: '132px', marginRight: '3px' }} onClick={handleContextMenu} type={"button"} themeColor={'primary'}
                            >{mui(userRights, "common", "cmdScanDoc", "Scan Doc")}</Button>                                                     
                            <Button disabled={data.disableshowdocbutton} style={{ width: '132px', marginRight: '3px' }} type={"button"} themeColor={'primary'} onClick={() => {
                                ShowDocClick()
                            }}>{mui(userRights, "common", "cmdShowDoc", "Show Doc")}</Button>
                            <Button style={{ width: '132px', marginRight: '3px' }} type={"button"} themeColor={'primary'} onClick={() => {
                                PrintReportClick()
                            }}>{mui(userRights, "common", "cmdPrintReport", "Print Report")}</Button>
                            <Button style={{ width: '132px', marginRight: '3px' }} type={"button"} themeColor={'primary'} onClick={() => {
                                CwsClick()
                            }}>{mui(userRights, "common", "cmdCWS", "CWS")}</Button>
                            <Button style={{ width: '132px', marginRight: '3px' }} type={"button"} themeColor={'primary'} onClick={() => {
                                SerothekClick()
                            }}>{mui(userRights, "common", "cmdSerothek", "Serothek")}</Button>
                            <Button style={{ width: '132px', marginRight: '3px' }} type={"button"} themeColor={'primary'} onClick={() => {
                                MatQualityClick()
                            }}>{mui(userRights, "common", "cmdMatQuality", "Mat Quality")}</Button>
                            <Button style={{ width: '132px', marginRight: '3px', backgroundColor: data.sc1color }} type={"button"} themeColor={'primary'} onClick={() => {
                                SC1Click()
                            }}>{mui(userRights, "common", "cmdSC1", "SC1")}</Button>
                            <Button style={{ width: '132px', marginRight: '3px', backgroundColor: data.sc2color }} type={"button"} themeColor={'primary'} onClick={() => {
                                SC2Click()
                            }}>{mui(userRights, "common", "cmdSC2", "SC2")}</Button>
                            <Button style={{ width: '132px', marginRight: '3px' }} type={"button"} themeColor={'primary'} onClick={() => {
                                PatDetailsClick()
                            }}>{mui(userRights, "common", "cmdPatDetails", "Pat Details")}</Button>
                            <Button style={{ width: '132px', marginRight: '3px' }} type={"button"} themeColor={'primary'} onClick={() => {
                                RequestClick()
                            }}>{mui(userRights, "common", "cmdRequest", "Request")}</Button>
                            <Button style={{ width: '132px', marginRight: '3px' }} type={"button"} themeColor={'primary'} onClick={() => {
                                AddParamClick()
                            }}>{mui(userRights, "common", "cmdAddParam", "Add Param")}</Button>
                            <Button style={{ width: '132px', marginRight: '0px' }} type={"button"} themeColor={'primary'} onClick={() => {
                                PrintLableClick()
                            }}>{mui(userRights, "common", "cmdPrintLabel", "Print Label")}</Button>
                        </div>                      
                    </div>
                    <div className="row" style={{ height: 3 }}></div>                    
                </div>
                <Suspense fallback={<Loader />}>                    
                    <RequestDialog key={'R'} data={requestDialogData} callbackRequestDialogClosed={callbackRequestDialogClosed}></RequestDialog>
                    <AddressDialog key={'A'} data={addressDialogData} callbackAddressDialogClosed={callbackAddressDialogClosed}></AddressDialog>
                    <ScannerUI multipleScans={scannerUIData.multipleScans} selPDF={scannerUIData.selPDF} objScanProperties={scannerUIData.objScanProperties} source={scannerUIData.source} showDialog={scannerUIData.showDialog} ScanningOver={ScanningOver}></ScannerUI>
                    <PatientReports mandantid={patientReportsDialogData.mandantid} pid={patientReportsDialogData.pid} showDialog={patientReportsDialogData.showDialog} FormClosed={patientReportsDialogData.FormClosed}></PatientReports>
                    <ItemsToPrint itemstoprintdata={itemsToPrintData} callbackDialogClosed={callbackItemToPrintDialogClosed} ></ItemsToPrint >
                    <RequestSpecialCommentDialog mandantid={reqSplCommData.mandantid} mandantdesc={reqSplCommData.mandantdesc} reqid={reqSplCommData.reqid}
                        reqdate={reqSplCommData.reqdate} reqtime={reqSplCommData.reqtime} pid={reqSplCommData.pid} splcommtype={reqSplCommData.splcommtype}
                        showDialog={reqSplCommData.showDialog } callbackDataSaved={reqSplCommData.callbackDataSaved}></RequestSpecialCommentDialog>
                </Suspense>               
            </div>
        </>
    )

}
export default RequestInfoBar;