import { Input } from "@progress/kendo-react-inputs";
import { useEffect, useRef, useState } from "react";
import { dateInternalToSystem, formatDate, mui } from "../../Helper";
import { Dialog, DialogCloseEvent } from "@progress/kendo-react-dialogs";
import { Calendar } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import {
    IntlProvider,
    load,
    loadMessages,
    LocalizationProvider,
} from "@progress/kendo-react-intl";
import deMessages from "../../de.json";
import itMessages from "../../it.json";
import frMessages from "../../fr.json";
import UserSession from "../UserSession";
import axios from 'axios'

/* CLDR Data */
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";

import gbCaGregorian from "cldr-dates-full/main/en-GB/ca-gregorian.json";
import gbDateFields from "cldr-dates-full/main/en-GB/dateFields.json";

import deCaGregorian from "cldr-dates-full/main/de/ca-gregorian.json";
import deDateFields from "cldr-dates-full/main/de/dateFields.json";

import itCaGregorian from "cldr-dates-full/main/it/ca-gregorian.json";
import itDateFields from "cldr-dates-full/main/it/dateFields.json";

import frCaGregorian from "cldr-dates-full/main/fr/ca-gregorian.json";
import frDateFields from "cldr-dates-full/main/fr/dateFields.json";
import { CResultEntry, emptyREData } from "../../types";

load(
    likelySubtags,
    currencyData,
    weekData,
    deCaGregorian,
    deDateFields,
    gbCaGregorian,
    gbDateFields,
    itCaGregorian,
    itDateFields,
    frCaGregorian,
    frDateFields,
);
interface ICustomDate {
    defaulValue: string;
    id: string;
    source: string;
    label: string;
    required: boolean;
    className?: string;
    disabled: boolean;
    width: string;
    dateChanged: (value: string, source: string, supplParam1?: string, supplParam2?: string) => void;
    supplParam1?: string;
    supplParam2?: string;
    onlyTextBox?: boolean;
    objRE?: CResultEntry;
}

export const CustomDate = (props: ICustomDate) => {
    const dateInput = useRef<HTMLInputElement>();
    const [controlDate, setControlDate] = useState(props.defaulValue);
    const [showCalendar, setShowCalendar] = useState(false);
    const [calDate, setCalDate] = useState(new Date());


    const onBlur = async (e: any) => {

        if (!ValidateDate(e.target.value)) {
            e.preventDefault();
            setTimeout(() => { if (dateInput.current) { console.log('focus'); dateInput.current.focus() } }, 200);
            return false;
        }

    };

    useEffect(() => {
        //console.log('useEffect: ', props.source, props.defaulValue);
        setControlDate(props.defaulValue);
    }, [props])

    useEffect(() => {
        if (props.objRE !== undefined) {

            props.objRE.sRawResult = controlDate;
            props.objRE.sRecStatus = "CHG"
            
        }
        console.log("UE", props.objRE, controlDate)
    }, [controlDate])

    const handleChange = (e: any) => {
        const type = e.target.type
        const value = type === "checkbox"
            ? e.target.checked
            : e.target.value;
        const result = value.replace(/[^0-9\.]/gi, '');
        setControlDate(result);
    }

    const key_down = (ev: any) => {
        var someDate = new Date();
        var numberOfDaysToAdd: number;
        var keyCode: string = ev.keyCode.toString();
        if (keyCode === '65') {
            setControlDate(formatDate(new Date().toDateString()));
            ev.preventDefault();
            return false;
        }
        else if (keyCode === '66') {
            numberOfDaysToAdd = 1;
            var result = new Date(someDate.setDate(someDate.getDate() + numberOfDaysToAdd));
            setControlDate(formatDate(result.toDateString()));
            ev.preventDefault();
            return false;
        }
        else if (keyCode === '90') {
            numberOfDaysToAdd = -1;
            var result = new Date(someDate.setDate(someDate.getDate() + numberOfDaysToAdd));
            setControlDate(formatDate(result.toDateString()));
            ev.preventDefault();
            return false;
        };
    };

    const calendarSelection = () => {
        if (controlDate === '') {
            setControlDate(formatDate(new Date().toDateString()));
        }
        else {
            var dtDate: Date | null = dateInternalToSystem(controlDate);
            if (dtDate === null)
                setControlDate(formatDate(new Date().toDateString()));
            else
                setControlDate(formatDate(dtDate.toDateString()));
        }
        setShowCalendar(true);
    };

    useEffect(() => {
        const lang: string = UserSession.getLanguage();
        if (lang === "de") {
            loadMessages(deMessages, "de-DE");
        }
        else if (lang === "it") {
            loadMessages(itMessages, "it-IT");
        }
        else if (lang === "fr") {
            loadMessages(frMessages, "fr-FR");
        }

        let element = document.getElementById(props.id);
        if (element) {
            element.addEventListener('keydown', key_down);
        }

        return () => {
            document.removeEventListener('keydown', key_down);
        };

    })

    function ValidateDate(dt: string): boolean {
        if (dt === '') {
            props.dateChanged("", props.source, props.supplParam1, props.supplParam2);
            setControlDate('');
            return true;
        }
        var curDate = new Date();
        var delimCount: number = dt.split(".").length - 1;
        if (delimCount > 2) {
            setControlDate('');
            props.dateChanged("", props.source, props.supplParam1, props.supplParam2);
            return false;
        }
        dt = dt.split(".").join("");
        var newDate: Date;
        if (dt.length === 1 || dt.length === 2) { // only day
            dt = dt.length === 1 ? "0" + dt : dt;
            if (Number(dt) < 1 || Number(dt) > 31) {
                setControlDate('');
                props.dateChanged("", props.source, props.supplParam1, props.supplParam2);
                return false;
            }
            newDate = new Date(curDate.getFullYear(), curDate.getMonth(), Number(dt));
            setControlDate(formatDate(newDate.toDateString()));
            props.dateChanged(formatDate(newDate.toDateString()), props.source, props.supplParam1, props.supplParam2);
        }
        else if (dt.length === 3 || dt.length === 4) { // day and month
            var dd: string = dt.substring(0, 2);
            var mm: string = dt.substring(2);
            mm = mm.length === 1 ? "0" + mm : mm;
            if (Number(dd) < 1 || Number(dd) > 31) {
                setControlDate('');
                props.dateChanged("", props.source, props.supplParam1, props.supplParam2);
                return false;
            }
            if (Number(mm) < 1 || Number(mm) > 12) {
                setControlDate('');
                props.dateChanged("", props.source, props.supplParam1, props.supplParam2);
                return false;
            }
            newDate = new Date(curDate.getFullYear(), Number(mm) - 1, Number(dd));
            setControlDate(formatDate(newDate.toDateString()));
            props.dateChanged(formatDate(newDate.toDateString()), props.source, props.supplParam1, props.supplParam2);
        }
        else { // day month and year                
            var dd: string = dt.substring(0, 2);
            var mm: string = dt.substring(2, 4);
            var yy: string = dt.substring(4);
            var yearString: string = curDate.getFullYear().toString();
            if (yy.length === 2)
                yy = yearString.substring(0, 2) + yy;
            else if (yy.length === 4)
                yy = yy;
            else {
                setControlDate('');
                props.dateChanged("", props.source, props.supplParam1, props.supplParam2);
                return false;
            }
            if (Number(dd) < 1 || Number(dd) > 31) {
                setControlDate('');
                props.dateChanged("", props.source, props.supplParam1, props.supplParam2);
                return false;
            }
            if (Number(mm) < 1 || Number(mm) > 12) {
                setControlDate('');
                props.dateChanged("", props.source, props.supplParam1, props.supplParam2);
                return false;
            }
            newDate = new Date(Number(yy), Number(mm) - 1, Number(dd));
            setControlDate(formatDate(newDate.toDateString()));
            props.dateChanged(formatDate(newDate.toDateString()), props.source, props.supplParam1, props.supplParam2);
        }
        return true;
    }

    const closeClicked = (event: DialogCloseEvent) => {
        setShowCalendar(false);
    };

    const noClicked = () => {
        setShowCalendar(false);
    };

    const yesClicked = () => {
        setControlDate(formatDate(calDate.toDateString()));
        props.dateChanged(formatDate(calDate.toDateString()), props.source, props.supplParam1, props.supplParam2);
        setShowCalendar(false);
    };

    const onChange = (event: any) => {
        setCalDate(event.value);
    };

    return (
        <div style={{ display: "inline-flex", alignItems: 'center' }}>
            <div className="vertical-wrapper">
                {props.label !== "" && (<div className="vertical-wrapper-child"><Label className={props.required === true ? "mandatory" : ""}>{props.label}</Label></div>)} 
               
                <div className="vertical-wrapper-child">
                   

                    {props.onlyTextBox === false || props.onlyTextBox === undefined ?
                        <>
                            <Input ref={dateInput} style={{ width: props.width }} onBlur={onBlur} className={props.className} disabled={props.disabled} required={props.required} maxLength={10}
                                autoComplete="off" id={props.id} type='text' defaultValue={props.defaulValue} value={controlDate} onChange={handleChange} /> &nbsp;
                            <span style={{ verticalAlign: 'middle' }} onClick={calendarSelection} className={"k-icon k-font-icon k-i-calendar mouse-pointer " + props.className}></span>
                        </>
                      
                        :
                    <Input ref={dateInput} style={{ width: props.width }} onBlur={onBlur} disabled={props.disabled} required={props.required} maxLength={10}
                        autoComplete="off" id={props.id} type='text' defaultValue={props.defaulValue} value={controlDate} onChange={handleChange} />
                    }

                    
                </div>
            </div>

            {
                showCalendar && (
                    <Dialog  title={'Calendar'} onClose={closeClicked}>
                        <div className="container" style={{ overflowX: 'hidden', width: 337 }}>
                            <div className="row" style={{ padding: 0 }} >
                                <div className="col" style={{ padding: 0 }}>
                                    <LocalizationProvider language={globalThis.locale}>
                                        <IntlProvider locale={globalThis.locale}>
                                            <Calendar value={calDate} onChange={onChange} />
                                        </IntlProvider>
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col" style={{ padding: 0, marginTop: 5 }}>
                                    <div className="button-right">
                                        <Button type={"button"} style={{ width: '150px' }} themeColor={'error'} onClick={() => {
                                            noClicked()
                                        }}>{'Cancel'}</Button>&nbsp;
                                        <Button type={"button"} style={{ width: '150px' }} themeColor={'success'} onClick={() => {
                                            yesClicked()
                                        }}>{'Select'}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                )
            }

            </div>
    );
}

export default CustomDate;
