import React, { useCallback, useEffect, useRef, useState, createRef } from 'react'
import axios from 'axios'
import UserSession from '../UserSession';
import { selCodeDataType } from '../../types';
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { ListBox, ListBoxItemClickEvent } from '@progress/kendo-react-listbox';

interface SelectCodeProps {
    tablename: string;
    code: string;
    desc: string;
    selCode: string;
    mandantChanged: number;
    setSelectedTable(tableid: string, controlID: string, obj: selCodeDataType, supplParam1: string, supplParam2: string): any;
    width: string;
    id: string;
    supplParam1: string;
    supplParam2: string;
    disabled: boolean;
}

export const SelectList = (props: SelectCodeProps) => {
    const columns = [
        { field: "code", header: "Code" },
        { field: "desc", header: "Description" },
    ];

    // Default empty selected CT state
    var selCodeData = {
        mandantid: "",
        code: "",
        desc: "",
        selected: false
    };
    const [selected, setSelected] = useState(selCodeData);
    // Array of CT data for the specified CT
    const [selCode, setSelCode] = useState<selCodeDataType[]>([]);

    if (props.tablename === "Room") {
        columns.push({ field: "addnfield", header: "Station" });
    };

    async function GetTableData() {
        const GetTableData_URL = UserSession.getSystemSettings().API_URL + 'SelectCode/gettabledata';

        const response = await axios.post(GetTableData_URL,
            JSON.stringify({
                mandantid: UserSession.getLoggedInUser().CurrentMandant, "tableid": props.tablename,
                "code": props.code, "desc": props.desc, "supplParam1": props.supplParam1, "supplParam2": props.supplParam2,
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        var binddata: selCodeDataType[] = [];
        binddata.push({ DisplayString: "", mandantid: "", code: "", desc: "", addnfield: "", selected: false });
        if (response?.data !== null)
            response?.data.forEach((o: selCodeDataType) => {
                binddata.push({
                    DisplayString: o.code + ' - ' + o.desc,
                    mandantid: o.mandantid, code: o.code, desc: o.desc, addnfield: o.addnfield, selected: false
                });
            });
        setSelCode(binddata);
        console.log('columns', columns);
        //console.log(binddata)
        binddata.forEach((o: selCodeDataType) => {
            if (o.code === props.selCode) {
                setSelected(o)
            }
        });
    }

    useEffect(() => {
        // console.log('CodeTableSel UE: props.mandantChanged');
        GetTableData();
    }, [props.mandantChanged, props.supplParam1])

    useEffect(() => {
        if (selCode === null || selCode === undefined) return;
        let selcCode: selCodeDataType | undefined = selCode.find((item: selCodeDataType) => item.code === props.selCode)
        if (selcCode !== undefined) {
            setSelected(selcCode);
        }
    }, [props.selCode])

    const SELECTED_FIELD = "selected";

    const [filter, setFilter] = React.useState<FilterDescriptor>();
    const lastSelectedIndex = React.useRef(0);

    const [selectedFields, setSelectedFields] = useState<selCodeDataType[]>([]);  

    const handleItemClick = (event: ListBoxItemClickEvent, allData: selCodeDataType[], selData: selCodeDataType[]) => {
        const newData: selCodeDataType[] = allData.map((item: selCodeDataType) => {
            if (item.code === event.dataItem.code) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        });
        const chosenData: selCodeDataType[] = selData.map((item: selCodeDataType) => {
            if (item.code === event.dataItem.code) {
                item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
                item[SELECTED_FIELD] = false;
            }
            return item;
        });

        const current = newData.findIndex(
            (dataItem) => dataItem === event.dataItem
        );
        lastSelectedIndex.current = current;

        chosenData.forEach((item) => {
            if (item.code === event.dataItem.code) {
                
            } else {
                item.selected = false
            }
            return item;
        });

        setSelCode(chosenData);

        console.log(event.dataItem, chosenData, newData)
        props.setSelectedTable(props.tablename, props.id, event.dataItem, props.supplParam1, props.supplParam2);
        setSelected(event.dataItem);

    };
 
    const MyCustomItem = (props: any) => {
        //console.log("MyCustomItem", props)
        let { dataItem, selected, ...others } = props;
        return (
            <li {...others} style={{ width: "100%" }}>
                <div className="row" style={{ width: "100%" }}>
                    <div className="col-2">{props.dataItem.code}</div>
                    <div className="col-10">{props.dataItem.desc}</div>
                </div>
               
            </li>
        );
    };

    return (
       <>
            <ListBox
                style={{ height: 350, width: props.width }}
                data={filter ? filterBy(selCode, filter) : selCode}
                textField="DisplayString"
                selectedField={SELECTED_FIELD}
                key="sCode"
                onItemClick={(e: ListBoxItemClickEvent) => handleItemClick(e, selectedFields, selCode)}
                item={MyCustomItem}
            /></>


        
    )

}