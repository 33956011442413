import { Input, InputChangeEvent, Checkbox } from '@progress/kendo-react-inputs';
import React from "react";
import {useRef, useEffect, useState } from "react";
import axios from 'axios';
import { Label } from "@progress/kendo-react-labels";
import { Grid, GridCellProps, GridColumn, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import UserSession from '../UserSession';
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Typography } from "@progress/kendo-react-common";
import { Loader } from '@progress/kendo-react-indicators';
import { CUserRightsAppl, matInLabOutput } from '../../types';
import { mui } from '../../Helper';
import { useLocation } from 'react-router-dom';
import ProgressBar from '../Misc/ProgressBar';

const initialSort: Array<SortDescriptor> = [
    { field: "reqid", dir: "desc" },
];

let source: any = null;

const MatInLab = (props: any) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'matinlab' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const TooltipContentTemplate = (props: any) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: props.title }} />
        );
    }
   
    const barcodeRef = useRef<HTMLInputElement>(null);
    const [barcode, setBarcode] = React.useState<any | undefined>(undefined);
    const [sort, setSort] = useState(initialSort);
    const [isLoading, setIsLoading] = useState(false);
    const [matInLabData, setMatInLabData] = useState<matInLabOutput[]>([]);
    const [msg, setMsg] = useState("");
    
    const ProcessBarcode = async (e:any) => {
        try {
            e.preventDefault();    
            setMsg("")
            if (barcodeRef.current?.value !== "" && barcodeRef.current?.value !== null) {
                // console.log('barcode - ' + barcodeRef.current?.value)
                setIsLoading(true);
                const response = await axios.post(UserSession.getSystemSettings().API_URL + 'MatInLab/setmatinlabflag',
                    JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, barcode: barcode, mbyn: UserSession.getLoggedInUser().MBYN, userid: UserSession.getLoggedInUser().UserID, groupidforcurrentmandant: '', showmatexpinlabyn: '', showautotooltipyn: '' }),
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                            'Content-Type': 'application/json'
                        },
                        withCredentials: false,
                        cancelToken: source.token
                    }
                );                
                if (response?.data !== null) {                    
                    var newMatInLab: matInLabOutput[];
                    newMatInLab = response?.data                    
                    for (let objMatInLab of newMatInLab) {
                        if (objMatInLab.msgtitle !== null && objMatInLab.msgtitle !== "") {
                            //alert(objMatInLab.msgtitle + "\n" + objMatInLab.msg);
                            setMsg(objMatInLab.msgtitle + " - " + objMatInLab.msg)
                            setBarcode("");                            
                            //setMatInLabData([]);
                            if (barcodeRef.current) barcodeRef.current.value = "";
                            //barcodeRef.current?.focus();
                            //setTimeout(() => { barcodeRef.current?.focus() }, 200);                            
                        } else {
                            var newArray = matInLabData.slice();
                            newArray.push(objMatInLab);
                            setMatInLabData(newArray);
                            setBarcode("");
                            setMsg("")
                            if (barcodeRef.current) barcodeRef.current.value = "";
                            //barcodeRef.current?.focus();
                            //setTimeout(() => { barcodeRef.current?.focus() }, 200);                            
                        }
                    }                  
                };
                setIsLoading(false);                               
            }
            return;
        } catch (err: any) {
            //console.log(err);
            if (!err?.response) {
            } else if (err.response?.status === 401) {
            } else {
            }
        }
        setIsLoading(false);
    };

    const CustomCellMat = (props: GridCellProps) => {
        const value = props.dataItem['matdetails'];
        const bgcolor = props.dataItem['tubecolor'];
        return (
            <td style={{ backgroundColor: bgcolor }}>
                {value}
            </td>
        );
    };
    
    const CustomCellReq = (props: GridCellProps) => {
        const value = props.dataItem['reqid'];
        const tttext = props.dataItem['tooltip'];
        const bgcolor = props.dataItem['emergencycolor'];
        return (
            <td style={{ backgroundColor: bgcolor }} title={tttext}>
                {value}
            </td>
        );
    };
    const CustomCellPIDDetails = (props: GridCellProps) => {
        const value = props.dataItem['piddetails'];
        const tttext = props.dataItem['piddetailsaddn'];        
        return (
            <td title={tttext}>
                {value}
            </td>
        );
    };
    const CustomCellExtDate = (props: GridCellProps) => {
        const value = props.dataItem['extdatetime'];
        const bgcolor = props.dataItem['extdatetimecolcolor'];
        return (
            <td style={{ backgroundColor: bgcolor }}>
                {value}
            </td>
        );
    };
    
    const handleChange = React.useCallback((event: InputChangeEvent) => {
        setBarcode(event.target.value);
        setMsg("");
    }, []);
        
    useEffect(() => {
        barcodeRef.current?.focus();
    });

    useEffect(() => {
        source = axios.CancelToken.source();

        return () => {
            // console.log('Unmount: MatInLab');
            if (source) {
                source.cancel("MatInLab-Component got unmounted");
            }
        };

    }, [])

    var patRequestAreaWidth: number = window.innerWidth - 80;
    var gridHeight: number = window.innerHeight;
    gridHeight -= 265;

    return (
        <>            
            <div className="container-fluid" style={{ overflowX: "hidden", minWidth: patRequestAreaWidth - 220, width: '98%' }}>
                <div className="row" style={{ border: '1px solid lightblue', margin: 5, backgroundColor: "#e0f0ff", borderRadius: 0 }}>
                    <div className="col">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child"><Label editorId={'lblbarCode'}>{mui(userRights, "common", "lblBarcode", "Barcode")}</Label></div>
                            <div className="vertical-wrapper-child"><Input autoComplete="off" id="barcode" name="barcode" ref={barcodeRef} style={{ width: '250px' }}
                                type='text' onChange={handleChange} value={barcode} onBlur={ProcessBarcode}/></div>                        
                        </div>
                    </div>
                    <div className="col">
                        <div style={{ display: 'inline-block', paddingTop: 25 }}>
                        {/*    <Checkbox name="chkShowMatExpInLabYN" label={mui(userRights, "matinlab", "chkShowMatExpInLabYN", "ShowMatExpInLabYN")} />*/}
                        </div>
                    </div>
                    <div className="col">
                        <div style={{ display: 'inline-block', paddingTop: 25 }}>
                            {/*<Checkbox name="chkShowAutoTooltipYN" label=mui(userRights, "matinlab", "chkShowAutoTooltipYN", "ShowAutoTooltipYN") />*/}
                        </div>
                    </div>
                    <div className="col">&nbsp;</div>
                    <div className="col">&nbsp;</div>
                    <div className="col">&nbsp;</div>
                    <div className="col-12">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col">
                        <Typography.h6 themeColor={'success'} textAlign={'left'} variant='subtitle1' className={msg ? "errmsg" : "offscreen"}>{msg}</Typography.h6>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {isLoading ? <ProgressBar message=""></ProgressBar> : 
                        <Tooltip style={{ width: "800px" }} openDelay={100} position="right" anchorElement="target" content={(props) => <TooltipContentTemplate title={props.title} />}>
                            <Grid
                                style={{
                                    height: gridHeight, width: '100%'
                                }}

                                data={orderBy(matInLabData, sort)}
                                sortable={true}
                                sort={sort}
                                onSortChange={(e: GridSortChangeEvent) => {
                                    setSort(e.sort);

                                }}
                            >
                                <GridColumn field="reqid" title={mui(userRights, "matinlab", "col1_ReqID", "ReqID")} cell={CustomCellReq} width="90px" />
                                <GridColumn field="reqdate" title={mui(userRights, "matinlab", "col2_ReqDate", "Request Date")} width="110px" />
                                <GridColumn field="pid" title={mui(userRights, "matinlab", "col3_PID", "PID")} width="80px" />
                                <GridColumn field="piddetails" title={mui(userRights, "matinlab", "col4_PidDetails", "PID Details")} cell={CustomCellPIDDetails} />
                                {/*<GridColumn field="piddetailsaddn" title="Addn PID Details" />*/}
                                <GridColumn field="fid" title={mui(userRights, "matinlab", "col5_FID", "FID")} width="130px" />
                                <GridColumn field="ordprovdetails" title={mui(userRights, "matinlab", "col6_OrdProvDetails", "Ord Prov Details")} width="200px" />
                                <GridColumn field="room" title={mui(userRights, "matinlab", "col7_Room", "Room")} width="70px" />
                                {/*<GridColumn field="latestantibody" title="Latest Antibody" />*/}
                                {/*<GridColumn field="remarks" title="Remarks" />*/}
                                {/*<GridColumn field="reqextaddress" title="ReqExtAddress" />*/}
                                {/*<GridColumn field="workpageinfo" title="WorkPageInfo" />*/}
                                {/*<GridColumn field="diagnose" title="Diagnose" />*/}
                                {/*<GridColumn field="omrformnr" title="OMRFormNr" />*/}
                                <GridColumn field="matdetails" title={mui(userRights, "matinlab", "col8_MatDetails", "MatDetails")} cell={CustomCellMat} />
                                {/*<GridColumn field="matdesc" title="MatDesc" cell={CustomCellMatDesc} />*/}
                                <GridColumn field="extdatetime" title={mui(userRights, "matinlab", "col9_ExtDateTime", "ExtDateTime")} cell={CustomCellExtDate} />
                                <GridColumn field="paramresult" title={mui(userRights, "matinlab", "col10_ParamResult", "ParamResult")} />
                                <GridColumn field="collectorid" title={mui(userRights, "matinlab", "col11_CollectorID", "CollectorID")} />
                                );
                            </Grid>
                            </Tooltip>
                        }
                    </div>
                </div>
           </div>
 
           
        </>
    );
}
export default MatInLab;

