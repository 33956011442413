import { Button } from "@progress/kendo-react-buttons";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { Label } from "@progress/kendo-react-labels";
import React, { Suspense, useCallback, useEffect } from "react";
import { useRef, useState } from "react";
import { mui, useIntersection } from "../../Helper";
import { CRequestResult, CResultEntry, CResultEntryOutput, CUserRightsAppl, emptyREData, ProfileDropdownData, ReqInfoBar, resultEntryDialog, resultSelDialog, requestDialogData, COneRequest, emptyREO } from "../../types";
import RequestInfoBar from "../ReqInfoBar/RequestInfoBar";
import ResultSelDialog from "./ResultSelDialog";
import axios from 'axios';
import UserSession from "../UserSession";
import { Checkbox, CheckboxChangeEvent, Input } from "@progress/kendo-react-inputs";
import RequestDialog from "../RequestEntry/RequestDialog";
import ProgressBar from "../Misc/ProgressBar";

var mainResultData: CResultEntry[] = ([]);

const GridContext = React.createContext({});
const allInEdit = mainResultData.map((item) =>
    Object.assign({ inEdit: true }, item)
);

const GridDataCellNumResult = (cellProps: any) => {
    const [comboResultValue, setComboResultValue] = useState<CRequestResult>();;

    const { resultMainData }: any = React.useContext(GridContext);
    const { resultData }: any = React.useContext(GridContext);
    const { setResultData }: any = React.useContext(GridContext);
    const { disableCol1 }: any = React.useContext(GridContext);

    if (cellProps.dataItem.bDisableResultEntry === true) {
        return (
            <td></td>
        );
    };

    const GridDataCellChange = (e: any) => {
        const dataItem = e.dataItem ?? e.target.props.dataItem;
        const field = e.field ?? e.target.props.field;
        setResultData((oldGridData: any) =>
            oldGridData.map((item: any) => {
                if (item.sParamID === dataItem.sParamID) {
                    item[field || ''] = e.value;
                }
                return item;
            })
        );
    };

    const handleResultComboChange = (e: DropDownListChangeEvent) => {
        var obj: CRequestResult = e.value
        setComboResultValue(e.value);
        setResultData((oldGridData: any) =>
            oldGridData.map((item: any) => {
                if (item.sParamID === obj.sParamId) {
                    item.sSerIDToValidate = obj.sSerID;
                    item.sResultComm = obj.sResultComm;
                    item.sCommText = obj.sCommText;
                    item.sResultText = obj.sResultText;
                    item.sResultFlag = obj.sResultFlag;
                    item.sInterpretationDesc = obj.sResult;
                }
                return item;
            })
        );
    };

    if (cellProps.dataItem.ExistingResult.length > 1 && (cellProps.dataItem.sParamStatus === '3' || cellProps.dataItem.sParamStatus === '63')) {
        return (
            <td>
                <DropDownList
                    data={cellProps.dataItem.ExistingResult}
                    textField="sRawResult"
                    dataItemKey="sSerID"
                    value={comboResultValue}
                    onChange={handleResultComboChange}
                    style={{ width: "100%" }}
                />
            </td>
        );
    } else if (cellProps.dataItem.sParamStatus === '1' || cellProps.dataItem.sParamStatus === '2') {
        var sStr: string = "";
        if (cellProps.dataItem.ExistingResult.length > 0) {
            sStr = cellProps.dataItem.ExistingResult[0].sResultComm !== undefined ? cellProps.dataItem.ExistingResult[0].sResultComm : ""
        };
        return (
            <td title={cellProps.dataItem.sInterpretationDesc1 + '\n' + sStr}>
                <Input
                    {...cellProps}
                    disabled={disableCol1}
                    value={cellProps.dataItem.sRawResult}
                    onChange={GridDataCellChange}
                    onBlurCapture={() => ValidateNumResult(cellProps.dataItem, resultData, setResultData, resultMainData, 0)}
                    style={{ textAlign: 'right' }}
                    width="100%"
                />
            </td>
        );
    } else {
        var sStr: string = "";
        if (cellProps.dataItem.ExistingResult.length > 0) {
            sStr = cellProps.dataItem.ExistingResult[0].sResultComm !== undefined ? cellProps.dataItem.ExistingResult[0].sResultComm : ""
        };
        return (
            <td title={cellProps.dataItem.sInterpretationDesc1 + '\n' + sStr}>
                <Input
                    {...cellProps}
                    disabled={true}
                    value={cellProps.dataItem.sRawResult}
                    style={{ textAlign: 'right' }}
                    width="100%"
                />
            </td>
        );
    };

};
const GridDataCellNumResult1 = (cellProps: any) => {
    const [comboResultValue, setComboResultValue] = useState<CRequestResult>();;

    const { resultMainData }: any = React.useContext(GridContext);
    const { resultData }: any = React.useContext(GridContext);
    const { setResultData }: any = React.useContext(GridContext);
    const { disableCol2 }: any = React.useContext(GridContext);

    if (cellProps.dataItem.bDisableResultEntry === true) {
        return (
            <td></td>
        );
    };

    const GridDataCellChange = (e: any) => {
        const dataItem = e.dataItem ?? e.target.props.dataItem;
        const field = e.field ?? e.target.props.field;
        setResultData((oldGridData: any) =>
            oldGridData.map((item: any) => {
                if (item.sParamID === dataItem.sParamID) {
                    item[field || ''] = e.value;
                }
                return item;
            })
        );
    };

    const handleResultComboChange = (e: DropDownListChangeEvent) => {
        var obj: CRequestResult = e.value
        setComboResultValue(e.value);
        setResultData((oldGridData: any) =>
            oldGridData.map((item: any) => {
                if (item.sParamID === obj.sParamId) {
                    item.sSerIDToValidate = obj.sSerID;
                    item.sResultComm = obj.sResultComm;
                    item.sCommText = obj.sCommText;
                    item.sResultText = obj.sResultText;
                    item.sResultFlag = obj.sResultFlag;
                    item.sInterpretationDesc = obj.sResult;
                }
                return item;
            })
        );
    };

    if (cellProps.dataItem.ExistingResult1.length > 1 && (cellProps.dataItem.sParamStatus === '3' || cellProps.dataItem.sParamStatus === '63')) {
        return (
            <td>
                <DropDownList
                    data={cellProps.dataItem.ExistingResult1}
                    textField="sRawResult1"
                    dataItemKey="sSerID"
                    value={comboResultValue}
                    onChange={handleResultComboChange}
                    style={{ width: "100%" }}
                />
            </td>
        );
    } else if (cellProps.dataItem.sParamStatus === '1' || cellProps.dataItem.sParamStatus === '2' || cellProps.dataItem.sParamStatus === '3') {
        var sStr: string = "";
        if (cellProps.dataItem.ExistingResult1.length > 0) {
            sStr = cellProps.dataItem.ExistingResult1[0].sResultComm !== undefined ? cellProps.dataItem.ExistingResult1[0].sResultComm : ""
        };
        return (
            <td title={cellProps.dataItem.sInterpretationDesc2 + '\n' + sStr}>
                <Input
                    {...cellProps}
                    disabled={disableCol2}
                    value={cellProps.dataItem.sRawResult1}
                    onChange={GridDataCellChange}
                    onBlurCapture={() => ValidateNumResult(cellProps.dataItem, resultData, setResultData, resultMainData, 1)}
                    style={{ textAlign: 'right' }}
                    width="100%"
                />
            </td>
        );
    } else {
        var sStr: string = "";
        if (cellProps.dataItem.ExistingResult1.length > 0) {
            sStr = cellProps.dataItem.ExistingResult1[0].sResultComm !== undefined ? cellProps.dataItem.ExistingResult1[0].sResultComm : ""
        };
        return (
            <td title={cellProps.dataItem.sInterpretationDesc2 + '\n' + sStr} >
                <Input
                    {...cellProps}
                    disabled={true}
                    value={cellProps.dataItem.sRawResult1}
                    style={{ textAlign: 'right' }}
                    width="100%"
                />
            </td>
        );
    };

};
const GridDataCellNumResult2 = (cellProps: any) => {
    const [comboResultValue, setComboResultValue] = useState<CRequestResult>();;

    const { resultMainData }: any = React.useContext(GridContext);
    const { resultData }: any = React.useContext(GridContext);
    const { setResultData }: any = React.useContext(GridContext);
    const { disableCol3 }: any = React.useContext(GridContext);

    if (cellProps.dataItem.bDisableResultEntry === true) {
        return (
            <td></td>
        );
    };

    const GridDataCellChange = (e: any) => {
        const dataItem = e.dataItem ?? e.target.props.dataItem;
        const field = e.field ?? e.target.props.field;
        setResultData((oldGridData: any) =>
            oldGridData.map((item: any) => {
                if (item.sParamID === dataItem.sParamID) {
                    item[field || ''] = e.value;
                }
                return item;
            })
        );
    };

    const handleResultComboChange = (e: DropDownListChangeEvent) => {
        var obj: CRequestResult = e.value
        setComboResultValue(e.value);
        setResultData((oldGridData: any) =>
            oldGridData.map((item: any) => {
                if (item.sParamID === obj.sParamId) {
                    item.sSerIDToValidate = obj.sSerID;
                    item.sResultComm = obj.sResultComm;
                    item.sCommText = obj.sCommText;
                    item.sResultText = obj.sResultText;
                    item.sResultFlag = obj.sResultFlag;
                    item.sInterpretationDesc = obj.sResult;
                }
                return item;
            })
        );
    };

    if (cellProps.dataItem.ExistingResult2.length > 1 && (cellProps.dataItem.sParamStatus === '3' || cellProps.dataItem.sParamStatus === '63')) {
        return (
            <td>
                <DropDownList
                    data={cellProps.dataItem.ExistingResult2}
                    textField="sRawResult2"
                    dataItemKey="sSerID"
                    value={comboResultValue}
                    onChange={handleResultComboChange}
                    style={{ width: "100%" }}
                />
            </td>
        );
    } else if (cellProps.dataItem.sParamStatus === '1' || cellProps.dataItem.sParamStatus === '2' || cellProps.dataItem.sParamStatus === '3') {
        var sStr: string = "";
        if (cellProps.dataItem.ExistingResult2.length > 0) {
            sStr = cellProps.dataItem.ExistingResult2[0].sResultComm !== undefined ? cellProps.dataItem.ExistingResult2[0].sResultComm : ""
        };
        return (
            <td title={cellProps.dataItem.sInterpretationDesc3 + '\n' + sStr}>
                <Input
                    {...cellProps}
                    disabled={disableCol3}
                    value={cellProps.dataItem.sRawResult2}
                    onChange={GridDataCellChange}
                    onBlurCapture={() => ValidateNumResult(cellProps.dataItem, resultData, setResultData, resultMainData, 2)}
                    style={{ textAlign: 'right' }}
                    width="100%"
                />
            </td>
        );
    } else {
        var sStr: string = "";
        if (cellProps.dataItem.ExistingResult2.length > 0) {
            sStr = cellProps.dataItem.ExistingResult2[0].sResultComm !== undefined ? cellProps.dataItem.ExistingResult2[0].sResultComm : ""
        };
        return (
            <td title={cellProps.dataItem.sInterpretationDesc3 + '\n' + sStr}>
                <Input
                    {...cellProps}
                    disabled={true}
                    value={cellProps.dataItem.sRawResult2}
                    style={{ textAlign: 'right' }}
                    width="100%"
                />
            </td>
        );
    };

};
const ValidateNumResult = async (cellProps: any, mainResultData: CResultEntry[], setResultData: any, resultMainData: any, nCol: number) => {
    const obj: CResultEntry = cellProps;
    const binddata: CResultEntry[] = [...mainResultData];
    const response = await axios.post(UserSession.getSystemSettings().API_URL + 'hamat/validateresult',
        JSON.stringify({
            objResultEntry: obj, collRatioValLevel: resultMainData.collRatioValLevel, col: nCol
        }),
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                'Content-Type': 'application/json'
            },
            withCredentials: false
        }
    );
    if (response?.data !== null) {
        var objREO = response?.data
        if (objREO.bInvalidCode === true) alert(mui(userRights, "common", "msgInvalidCode", "Invalid Code!"));
        for (let i = 0; i < binddata.length; i++) {
            if (binddata[i].sParamID === objREO.sParamID) {
                binddata[i] = JSON.parse(JSON.stringify(objREO));
                break;
            };
        };
        setResultData(binddata);
    };
};

const GridDataCellResultComm = (cellProps: any) => {
    const { resultMainData }: any = React.useContext(GridContext);
    const { resultData }: any = React.useContext(GridContext);
    const { setResultData }: any = React.useContext(GridContext);
    var disableValidationButton: boolean = false;

    if (cellProps.dataItem.bDisableResultEntry === true) {
        return (
            <td></td>
        );
    };

    const GridDataCellChange = (e: any) => {
        const dataItem = e.dataItem ?? e.target.props.dataItem;
        const field = e.field ?? e.target.props.field;
        setResultData((oldGridData: any) =>
            oldGridData.map((item: any) => {
                if (item.sParamID === dataItem.sParamID) {
                    item[field || ''] = e.value;
                }
                return item;
            })
        );
    };

    var sResultComm: string = "";
    //if ((cellProps.dataItem.sParamStatus === '1' || cellProps.dataItem.sParamStatus === '2')) {
    //    sResultComm = cellProps.dataItem.sResultComm;
    //} else if (cellProps.dataItem.sParamStatus === '3' && cellProps.dataItem.ExistingResult.length === 1) {
    //    sResultComm = cellProps.dataItem.sResultComm;
    //} else if (cellProps.dataItem.ExistingResult.length === 1) {
    //    sResultComm = cellProps.dataItem.ExistingResult[0].sResultComm;
    //} else if (cellProps.dataItem.ExistingResult.length > 1) {
    //    sResultComm = cellProps.dataItem.sResultComm;
    //};
    sResultComm = cellProps.dataItem.sResultComm !== undefined ? cellProps.dataItem.sResultComm : "";
    if (cellProps.dataItem.ExistingResult2.length > 0) disableValidationButton = true;

    return (
        <td>
            <Input
                {...cellProps}
                disabled={disableValidationButton}
                value={sResultComm}
                onChange={GridDataCellChange}
                onBlurCapture={() => ValidateResultComm(cellProps.dataItem, resultData, setResultData, resultMainData)}
                width="100%"
            />
        </td>
    );
};

const ValidateResultComm = async (cellProps: any, mainResultData: CResultEntry[], setResultData: any, resultMainData: any) => {
    var code: string = "";
    var binvalidcode: boolean;
    const obj: CResultEntry = cellProps;
    const binddata: CResultEntry[] = [...mainResultData];
    const response = await axios.post(UserSession.getSystemSettings().API_URL + 'general/validateresultcomm',
        JSON.stringify({
            mandantid: obj.sMandantID, resultcomm: obj.sResultComm, desc: "", binvalidcode: false
        }),
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                'Content-Type': 'application/json'
            },
            withCredentials: false
        }
    );
    if (response?.data !== null) {
        var objRCO = response?.data
        if (objRCO.binvalidcode === true) {
            alert(mui(userRights, "common", "msgInvalidCode", "Invalid Code!"));
        } else {
            for (let i = 0; i < binddata.length; i++) {
                if (binddata[i].sParamID === obj.sParamID) {
                    binddata[i].sResultComm = objRCO.resultcomm;
                    break;
                };
            };
            setResultData(binddata);
        };
    };
};

const HamatResAndValComponent = (props: resultEntryDialog) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'HamatResultAndValidation' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const ref_divButtons = useRef<HTMLDivElement>(null);
    const ref_divReqInfoBar = useRef<HTMLDivElement>(null);
    const ref_divGrid = useRef<HTMLDivElement>(null);

    const dlgEmptyState: resultSelDialog = {
        selType: "", objRE: emptyREData, selqualresult: false, showDialog: false, CodeSelected: () => { }, col: 1
    }
    const [resultSelDialogData, setResultSelDialogData] = useState<resultSelDialog>(dlgEmptyState);
    const [resultMainData, setResultMainData] = useState<CResultEntryOutput>(emptyREO);
    const [resultData, setResultData] = useState<CResultEntry[]>([]);
    const [profileData, setProfileData] = useState<ProfileDropdownData[]>([]);
    const [selProfile, setSelProfile] = useState<ProfileDropdownData>();

    const [visible, setVisible] = useState<boolean>(props.showDialog);
    const [isLoading, setIsLoading] = useState(false)
    const [reqInfo, setReqInfo] = useState<ReqInfoBar | null>(null);

    const [disableSaveButton, setDisableSaveButton] = useState<boolean | false>(true);
    const [disableValidationButton, setDisableValidationButton] = useState<boolean | false>(true);

    const [disableCol1, setDisableCol1] = useState<boolean | false>(false);
    const [disableCol2, setDisableCol2] = useState<boolean | false>(false);
    const [disableCol3, setDisableCol3] = useState<boolean | false>(false);
    const [disableNewResult, setDisableNewResult] = useState<boolean | false>(true);

    const [resultSaved, setResultSaved] = useState<boolean | false>(false);
    const [resultValidated, setResultValidated] = useState<boolean | false>(false);

    // for request dialog
    const ref = useRef<HTMLDivElement>(null);
    const inViewport = useIntersection(ref, '0px'); // Trigger as soon as the element becomes visible
    const [requestDialogData, setRequestDialogData] = useState<requestDialogData>({ reqid: '', pid: '', fid: '', bloodProduct: false, showDialog: false, inViewport: inViewport, extcall: false });

    useEffect(() => {
        var reqTemp = { ...requestDialogData, inViewport: inViewport }
        setRequestDialogData(reqTemp);
    }, [inViewport]);
    function AddParamClick() {
        if (reqInfo === null || reqInfo === undefined) return;
        setRequestDialogData({ reqid: reqInfo.reqid, pid: reqInfo.pid, fid: reqInfo.fid, bloodProduct: false, showDialog: true, inViewport: inViewport, extcall: false })
    };

    const callbackRequestDialogClosed = (objRequest: COneRequest | null) => {
        setRequestDialogData({ reqid: '', pid: '', fid: '', bloodProduct: false, showDialog: false, inViewport: inViewport, extcall: false });
        if (selProfile !== undefined) GetResultData(selProfile, 3);
    };

    const callbackReqInfo = async (objReqInfo: ReqInfoBar) => {
        setReqInfo(objReqInfo);
    };


    const NewResultClick = async () => {
        setDisableNewResult(true);
        if (selProfile !== undefined) GetResultData(selProfile, 2);
    };

    const ValidateClick = async () => {
        var bRefreshGrid: boolean = false;
        if (VerifyData("V") === true) {
            setIsLoading(true);
            if (resultMainData !== undefined) resultMainData.listResEntry = resultData;
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'hamat/validaterequestresultdata',
                JSON.stringify({
                    listResEntry: resultData, sQCMaxTimeWarningParams: resultMainData?.sQCMaxTimeWarningParams, sParamsInGrid: resultMainData?.sParamsInGrid,
                    collPrevalResult: resultMainData?.collPrevalResult, collResultCodeComments: resultMainData?.collResultCodeComments,
                    collRatioValLevel: resultMainData?.collRatioValLevel, collParamUnderProfile: resultMainData?.collParamUnderProfile,
                    bQualResultColVisible: resultMainData?.bQualResultColVisible, collValidatedParams: resultMainData?.collValidatedParams,
                    sProfileID: selProfile?.code, sUserID: UserSession.getLoggedInUser().UserID, sValType: resultMainData?.sValType, bRefreshGrid: false,
                    bMachineYN: resultMainData.bMachineYN, sErrorMsg: ""
                }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );

            var maindata: CResultEntryOutput | undefined;
            var binddata: CResultEntry[] = [];
            if (response?.data !== null) maindata = response?.data;
            if (maindata !== undefined) {
                if (maindata.sErrorMsg !== "") {
                    alert(maindata.sErrorMsg);
                } else {
                    bRefreshGrid = maindata.bRefreshGrid;
                    maindata.listResEntry.forEach((o: CResultEntry) => {
                        if (o.ExistingResult2.length === 0 || o.sParamStatus === "1" || o.sParamStatus === "2") setDisableSaveButton(false);
                        if (o.sParamStatus === "3") {
                            setDisableValidationButton(false);
                            if (o.ExistingResult2.length > 0) setDisableNewResult(false);
                        };
                        binddata.push({ ...o });
                    });

                    setResultData(binddata);
                    setResultValidated(true);
                    setDisableSaveButton(true);
                    setDisableValidationButton(true);
                    setDisableNewResult(true);
                };
            };
        };

        if (bRefreshGrid === true) {
            CancelClick();
        } else {
            GetProfiles();
        };
    };

    function VerifyData(Mode: string) {
        var bChkSumResult: boolean = false;
        var dblSumResult: number = 0;
        var bok: boolean = true;
        var obj: CResultEntry;

        if (Mode === "S") {
            for (obj of resultData) {
                if (obj.ExistingResult.length === 0) {
                    if (obj.sOrderedYN === "1" && obj.sPercentYN === "1" && typeof (obj.sRawResult) === 'number') {
                        dblSumResult += Number(obj.sRawResult);
                        bChkSumResult = true;
                    };
                } else if (obj.ExistingResult1.length === 0) {
                    if (obj.sOrderedYN === "1" && obj.sPercentYN === "1" && typeof (obj.sRawResult1) === 'number') {
                        dblSumResult += Number(obj.sRawResult1);
                        bChkSumResult = true;
                    };
                } else if (obj.ExistingResult2.length === 0) {
                    if (obj.sOrderedYN === "1" && obj.sPercentYN === "1" && typeof (obj.sRawResult2) === 'number') {
                        dblSumResult += Number(obj.sRawResult1);
                        bChkSumResult = true;
                    };
                };
            };
            if (bChkSumResult === true && Math.round(dblSumResult) !== 100) {
                bok = false;
                alert(mui(userRights, "HamatResultAndValidation", "msgTotalNotHundred", "Result total is not hundred!"));
            };
        };

        return bok;
    };

    const SaveClick = async (sMode: string) => {
        var col1datasaved: boolean = false;
        var col2datasaved: boolean = false;
        const binddata: CResultEntry[] = [...resultData];
        var obj: CResultEntry;
        var sAddnURL: string;
        if (sMode === 'S') {
            sAddnURL = 'hamat/saverequestresultdata';
        } else {
            sAddnURL = 'hamat/rerunparam';
        };
        for (obj of binddata) {
            if (obj.sRawResult === "" && (obj.sParamStatus === "1" || obj.sParamStatus === "2") && obj.bDisableResultEntry === false) {
                alert(mui(userRights, "common", "msgNoBlankResult", "Please enter all results!"));
                return;
            };
            if (obj.ExistingResult.length > 0) col1datasaved = true;
        };
        if (sMode === 'S' && col1datasaved === true) {
            for (obj of binddata) {
                if (obj.sRawResult1 === "" && obj.bDisableResultEntry === false) {
                    alert(mui(userRights, "common", "msgNoBlankResult", "Please enter all results!"));
                    return;
                };
                if (obj.ExistingResult1.length > 0) col2datasaved = true;
            };
        };
        if (sMode === 'S' && col2datasaved === true) {
            for (obj of binddata) {
                if (obj.sRawResult2 === "" && obj.bDisableResultEntry === false) {
                    alert(mui(userRights, "common", "msgNoBlankResult", "Please enter all results!"));
                    return;
                };
            };
        };
        if (sMode === 'S') {
            if (VerifyData("S") === false) return;
        };

        setIsLoading(true);
        if (resultMainData !== undefined) resultMainData.listResEntry = resultData;
        const response = await axios.post(UserSession.getSystemSettings().API_URL + sAddnURL,
            JSON.stringify({
                listResEntry: resultData, sQCMaxTimeWarningParams: resultMainData?.sQCMaxTimeWarningParams, sParamsInGrid: resultMainData?.sParamsInGrid,
                collPrevalResult: resultMainData?.collPrevalResult, collResultCodeComments: resultMainData?.collResultCodeComments,
                collRatioValLevel: resultMainData?.collRatioValLevel, collParamUnderProfile: resultMainData?.collParamUnderProfile,
                bQualResultColVisible: resultMainData?.bQualResultColVisible, collValidatedParams: resultMainData?.collValidatedParams,
                sProfileID: selProfile?.code, sUserID: UserSession.getLoggedInUser().UserID, bMachineYN: resultMainData.bMachineYN, sErrorMsg: ""
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );

        var maindata: CResultEntryOutput | undefined;
        if (response?.data !== null) maindata = response?.data;
        if (maindata !== undefined) {
            if (maindata.sErrorMsg !== "") {
                alert(maindata.sErrorMsg);
            } else {
                setDisableSaveButton(true);
                setDisableValidationButton(true);
                binddata.length = 0;
                maindata.listResEntry.forEach((o: CResultEntry) => {
                    if (o.ExistingResult2.length === 0 || o.sParamStatus === "1" || o.sParamStatus === "2") setDisableSaveButton(false);
                    if (o.sParamStatus === "3") setDisableValidationButton(false);
                    setDisableCol1(true);
                    setDisableCol2(true);
                    setDisableCol3(true);
                    if (o.ExistingResult.length === 0) {
                        setDisableCol1(false);
                        setDisableCol2(true);
                        setDisableCol3(true);
                    } else if (o.ExistingResult1.length === 0) {
                        setDisableCol1(true);
                        setDisableCol2(false);
                        setDisableCol3(true);
                    } else if (o.ExistingResult2.length === 0) {
                        setDisableCol1(true);
                        setDisableCol2(true);
                        setDisableCol3(false);
                    };
                    binddata.push({ ...o });
                });

                setResultData(binddata);
            };
        };

        setIsLoading(false);
    };

    const CancelClick = async () => {
        var bok: boolean = false;
        if (resultSaved === true || resultValidated === true) bok = true;
        props.ResultSaved(bok);
        setVisible(false);
    };

    const SetSelProfile = (event: DropDownListChangeEvent) => {
        setSelProfile(event.value);
        GetResultData(event.value, 3);
    };

    const ProfileClick = (code: string) => {
        if (code !== selProfile?.code) {
            var arr: CResultEntry[] = []
            setResultData(arr);
            profileData.forEach((obj: ProfileDropdownData) => {
                if (obj.code === code) {
                    setSelProfile(obj);
                    GetResultData(obj, 3);
                    obj.color = "inverse"
                } else obj.color = "primary";
            });
        };
    };

    const CustomCellEditReRun = (props: GridCellProps) => {
        const objRE: CResultEntry = props.dataItem;
        if (objRE.bDisableResultEntry === true) {
            return (
                <td></td>
            );
        };
        var disabled: boolean = true;
        if (Number(objRE.sParamStatus) > 2) disabled = false;
        if (objRE.ExistingResult.length > 0 && objRE.ExistingResult1.length > 0 && objRE.ExistingResult2.length > 0) disabled = false
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <Button disabled={disabled} title={mui(userRights, "common", "ttRerunParam", "Rerun Param")} className="buttons-container-button" icon="refresh"
                    onClick={() => {
                        SaveClick("RR")
                    }}
                >
                </Button>
            </td>
        );
    };

    const CustomCellEditParam = (props: GridCellProps) => {
        const paramid = props.dataItem['sParamID'];
        const paramdesc = props.dataItem['sParamDesc'];
        const bDisableResultEntry = props.dataItem['bDisableResultEntry'];
        return (
            <td style={{ fontWeight: bDisableResultEntry === true ? 'bold' : '' }} title={paramid}>
                {paramdesc}
            </td>
        );
    };

    const CustomCellOrderedYN = (props: GridCellProps) => {
        const value = props.dataItem['sParamID'];
        const checkvalue = props.dataItem['sOrderedYN'];
        const sPercentYN = props.dataItem['sPercentYN'];
        const bDisableResultEntry = props.dataItem['bDisableResultEntry'];
        if (bDisableResultEntry === true) {
            return (
                <td></td>
            );
        };
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <Checkbox disabled={sPercentYN === "1" ? true : false} onChange={(event: CheckboxChangeEvent) => handleOrdYNCheck(event, value)} checked={checkvalue === "1" ? true : false} id='sOrderedYN'></Checkbox>
            </td>
        );
    };

    const handleOrdYNCheck = (event: CheckboxChangeEvent, paramid: string) => {
        const binddata: CResultEntry[] = [...resultData];
        var obj: CResultEntry;
        for (obj of binddata) {
            if (obj.sParamID === paramid) {
                obj.sOrderedYN = event.value ? "1" : "0";
                break;
            }
        }
        setResultData(binddata);
    };

    const CustomCellPreVal1 = (props: GridCellProps) => {
        const value = props.dataItem['sPreVal1'];
        const tttext = props.dataItem['sPreVal1Tooltip'];
        return (
            <td title={tttext}>
                {value}
            </td>
        );
    };

    const CustomCellPreVal2 = (props: GridCellProps) => {
        const value = props.dataItem['sPreVal2'];
        const tttext = props.dataItem['sPreVal2Tooltip'];
        return (
            <td title={tttext}>
                {value}
            </td>
        );
    };

    const CustomCellPreVal3 = (props: GridCellProps) => {
        const value = props.dataItem['sPreVal3'];
        const tttext = props.dataItem['sPreVal3Tooltip'];
        return (
            <td title={tttext}>
                {value}
            </td>
        );
    };

    const CustomCellEditResultSel = useCallback((propsG: any) => {
        const status = propsG.dataItem['sParamStatus'];
        const bDisableResultEntry = propsG.dataItem['bDisableResultEntry'];
        if (bDisableResultEntry === true) {
            return (
                <td></td>
            );
        };
        return (
            <td>
                <Button
                    type={"button"}
                    style={{ textAlign: 'center', width: '25px' }}
                    themeColor={'primary'}
                    onClick={() => { onResSelClick(propsG.dataItem, 'open', 1) }}
                    disabled={disableCol1}
                >
                    ...
                </Button>

            </td>
        );
    }, [resultData]);

    const CustomCellEditResult1Sel = useCallback((propsG: GridCellProps) => {
        const status = propsG.dataItem['sParamStatus'];
        const bDisableResultEntry = propsG.dataItem['bDisableResultEntry'];
        if (bDisableResultEntry === true) {
            return (
                <td></td>
            );
        };
        return (
            <td>
                <Button
                    type={"button"}
                    style={{ textAlign: 'center', width: '25px' }}
                    themeColor={'primary'}
                    onClick={() => { onResSelClick(propsG.dataItem, 'open', 2) }}
                    disabled={disableCol2}
                >
                    ...
                </Button>

            </td>
        );
    }, [resultData]);

    const CustomCellEditResult2Sel = useCallback((propsG: GridCellProps) => {
        const status = propsG.dataItem['sParamStatus'];
        const bDisableResultEntry = propsG.dataItem['bDisableResultEntry'];
        if (bDisableResultEntry === true) {
            return (
                <td></td>
            );
        };
        return (
            <td>
                <Button
                    type={"button"}
                    style={{ textAlign: 'center', width: '25px' }}
                    themeColor={'primary'}
                    onClick={() => { onResSelClick(propsG.dataItem, 'open', 3) }}
                    disabled={disableCol3}
                >
                    ...
                </Button>

            </td>
        );
    }, [resultData]);

    const onResSelClick = useCallback((objRE: CResultEntry, action: string, nCol: number) => {
        const CodeSel = (Code: string, Desc: string) => {
            if (Code !== "") {
                if (nCol === 1) {
                    objRE.sRawResult = Code;
                    if (Code.toUpperCase() === "FT") {
                        objRE.sResultText = Desc;
                    } else {
                        objRE.sResultText = "";
                    };
                } else if (nCol === 2) {
                    objRE.sRawResult1 = Code;
                    if (Code.toUpperCase() === "FT") {
                        objRE.sResultText1 = Desc;
                    } else {
                        objRE.sResultText1 = "";
                    };
                } else if (nCol === 3) {
                    objRE.sRawResult2 = Code;
                    if (Code.toUpperCase() === "FT") {
                        objRE.sResultText2 = Desc;
                    } else {
                        objRE.sResultText2 = "";
                    };
                };

                ValidateResult(objRE, false, nCol);
            }
            setResultSelDialogData(dlgEmptyState);
        }
        //if (action === 'open')
        //    setBackdrop('');
        //else
        //    setBackdrop('none');
        setResultSelDialogData({ selType: "Result", objRE: objRE, selqualresult: false, showDialog: action === 'open' ? true : false, CodeSelected: CodeSel, col: nCol });
    }, [resultData]);

    const ValidateResult = async (objRE: CResultEntry, bQualResult: boolean, nCol: number) => {
        const binddata: CResultEntry[] = [...resultData];

        setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'hamat/validateresult',
            JSON.stringify({
                objResultEntry: objRE, collRatioValLevel: resultMainData?.collRatioValLevel, validatequalresult: bQualResult, col: nCol
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== null) {
            var objREO = response?.data
            if (objREO.bInvalidCode === true) alert(mui(userRights, "common", "msgInvalidCode", "Invalid Code!"));
            for (let i = 0; i < binddata.length; i++) {
                if (binddata[i].sParamID === objREO.sParamID) {
                    binddata[i] = JSON.parse(JSON.stringify(objREO));
                    break;
                };
            };
            setResultData(binddata);
        };
        setIsLoading(false);
    };

    const CustomCellEditInterp = (props: GridCellProps) => {
        const desc = props.dataItem['sInterpretationDesc'];
        const color = props.dataItem['sResultColColor'];
        return (
            <td style={{ backgroundColor: color }} title={desc}>
                {desc}
            </td>
        );
    };

    const CustomCellEditResultComm = (props: GridCellProps) => {
        const status = props.dataItem['sParamStatus'];
        const bDisableResultEntry = props.dataItem['bDisableResultEntry'];
        if (bDisableResultEntry === true) {
            return (
                <td></td>
            );
        };
        var disableValidationButton: boolean = false;
        if (props.dataItem.ExistingResult2.length > 0) disableValidationButton = true;

        return (
            <td>
                <Button
                    type={"button"}
                    style={{ textAlign: 'center', width: '25px' }}
                    themeColor={'primary'}
                    onClick={() => { onResCommSelClick(props.dataItem, 'open') }}
                    disabled={disableValidationButton}
                >
                    ...
                </Button>

            </td>
        );
    };

    const onResCommSelClick = useCallback((objRE: CResultEntry, action: string) => {
        const CodeSel = (Code: string, Desc: string) => {
            if (Code !== "") {
                const binddata: CResultEntry[] = [...resultData];
                var obj: CResultEntry;
                for (obj of binddata) {
                    if (obj.sParamID === objRE.sParamID && obj.bDisableResultEntry === false) {
                        obj.sResultComm = Code;
                        if (Code.toUpperCase() === "FT") {
                            objRE.sCommText = Desc;
                        } else {
                            objRE.sCommText = "";
                        };
                        break;
                    }
                }
                setResultData(binddata);
            }
            setResultSelDialogData(dlgEmptyState);
        };
        setResultSelDialogData({ selType: "ResultComm", objRE: objRE, selqualresult: false, showDialog: action === 'open' ? true : false, CodeSelected: CodeSel });
    }, [resultData]);

    const GetProfiles = async () => {
        //setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'general/getprofiles',
            JSON.stringify({
                mandantid: props.mandantid, reqid: props.reqid, pid: props.pid, fid: props.fid, reqdatetime: props.reqdatetime,
                valtype: props.valtype, addedparams: props.addedparams, pregnancyweek: props.pregnancyweek, mbyn: props.mbyn, userid: props.userid
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== null) {
            var binddata: ProfileDropdownData[] = response?.data;
            var obj: ProfileDropdownData;
            setProfileData(binddata);
            for (obj of binddata) {
                if (obj.selected === true) {
                    obj.color = "inverse";
                    setSelProfile(obj);
                    GetResultData(obj, 3);
                };
            };
        };
        //setIsLoading(false);
    };

    const GetResultData = async (obj: ProfileDropdownData, nrofresults: number) => {
        setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'hamat/gethamatdata',
            JSON.stringify({
                mandantid: props.mandantid, reqid: props.reqid, pid: props.pid, fid: props.fid, reqdatetime: props.reqdatetime, reqprovider: props.reqprovider,
                valtype: props.valtype, addedparams: props.addedparams, pregnancyweek: props.pregnancyweek, mbyn: props.mbyn, userid: props.userid, obj: obj, nrofresults: nrofresults
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        var binddata: CResultEntry[] = [];
        if (response?.data !== null) {
            setDisableValidationButton(true);
            setDisableSaveButton(true);
            setDisableNewResult(true)
            var objREO: CResultEntryOutput = response?.data;
            setResultMainData(objREO);
            response?.data.listResEntry.forEach((o: CResultEntry) => {
                if (o.ExistingResult2.length === 0 || o.sParamStatus === "1" || o.sParamStatus === "2") setDisableSaveButton(false);
                if (o.sParamStatus === "3") setDisableValidationButton(false);
                o.sOrderedYN = "1";
                if (obj.code === resultMainData.sMachineProfile) {
                    if (o.sMachineDiffYN === "1") {
                        o.sOrderedYN = objREO.bMachineYN === true ? "1" : "0";
                    };
                };
                setDisableCol1(true);
                setDisableCol2(true);
                setDisableCol3(true);
                if (o.ExistingResult.length === 0) {
                    setDisableCol1(false);
                    setDisableCol2(true);
                    setDisableCol3(true);
                } else if (o.ExistingResult1.length === 0) {
                    setDisableCol1(true);
                    setDisableCol2(false);
                    setDisableCol3(true);
                } else if (o.ExistingResult2.length === 0) {
                    setDisableCol1(true);
                    setDisableCol2(true);
                    setDisableCol3(false);
                };
                binddata.push({ ...o });
            });
            if (nrofresults === 3 && binddata[0].ExistingResult2.length > 0 && binddata[0].sParamStatus !== "4") setDisableNewResult(false);
            setResultData(binddata);
        };
        setIsLoading(false);
    };

    const handleMachineYNCheck = (event: CheckboxChangeEvent) => {
        setResultMainData(prevData => ({
            ...prevData,
            ['bMachineYN']: event.value
        }));
        ChangeOrderedYNFlag(event.value);
    };

    const ChangeOrderedYNFlag = (value: boolean) => {
        if (selProfile?.code !== resultMainData.sMachineProfile) return;

        const binddata: CResultEntry[] = [...resultData];
        var obj: CResultEntry;
        for (obj of binddata) {
            obj.sOrderedYN = "1";
            if (obj.sMachineDiffYN === "1") {
                obj.sOrderedYN = value === true ? "1" : "0";
            };
        };
        setResultData(binddata);
    };

    useEffect(() => {
        GetProfiles();
        setVisible(props.showDialog);
    }, [props.showDialog])

    var rootHeight = window.innerHeight - 175;
    var buttonHeight = 0;
    var reqInfoBarHeight = 0;
    var gridHt = 0;

    if (ref_divButtons !== undefined) buttonHeight = Number(ref_divButtons.current?.clientHeight);
    if (ref_divReqInfoBar !== undefined) reqInfoBarHeight = Number(ref_divReqInfoBar.current?.clientHeight);

    gridHt = rootHeight - (buttonHeight + reqInfoBarHeight + 110);

    return (
        <>
            <div>
                {visible && (
                    <div className="container-fluid" style={{ overflowX: 'hidden', width: '97%', marginLeft: 10, marginRight: 1 }}>
                        
                        <div ref={ref_divButtons} className="row">
                            <div className="col" style={{ textAlign: "right" }}>
                                
                                <Button disabled={disableNewResult} type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                    NewResultClick()
                                }}>{mui(userRights, "common", "cmdNewResult", "New Result")}</Button>&nbsp;
                                <Button disabled={false} type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                    AddParamClick()
                                }}>{mui(userRights, "common", "cmdAddParam", "Add Param")}</Button>&nbsp;
                                <Button disabled={disableValidationButton} type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                    ValidateClick()
                                }}>{mui(userRights, "common", "cmdValidate", "Validate")}</Button>&nbsp;
                                <Button disabled={disableSaveButton} type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                    SaveClick("S")
                                }}>{mui(userRights, "common", "cmdSave", "Save")}</Button>&nbsp;
                                {props.dayWorkSpace === false ?
                                    <Button type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                        CancelClick()
                                    }}>{mui(userRights, "common", "cmdCancel", "Cancel")}</Button>
                                    :
                                    ""
                                }
                            </div>
                        </div>
                        <div className="row" style={{ height: 1 }}></div>
                        <div ref={ref_divReqInfoBar} className="row">
                            <RequestInfoBar reqid={props.reqid} valtype={props.valtype} showmatdata={true} setSelectedReqInfo={callbackReqInfo}></RequestInfoBar>
                        </div>
                        <div className="row" style={{ paddingBottom: 10 }}>
                            <div className="col">
                                <div className="vertical-wrapper">
                                    <div className="vertical-wrapper-child"><Label editorId={'profileid'}>{mui(userRights, "common", "ProfileID", "ProfileID")}</Label></div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="vertical-wrapper">
                                    <div className="vertical-wrapper-child">
                                        <tr>
                                            {
                                                profileData !== null && profileData.map((obj: ProfileDropdownData) => (
                                                    <td>
                                                        <Button type={"button"} style={{ width: '150px', marginRight: 10 }} themeColor={obj.color} onClick={() => {
                                                            ProfileClick(obj.code)
                                                        }}>{obj.code}</Button>
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    </div>
                                </div>
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                            <div className="col"></div>
                            <div className="col">
                                <div style={{ textAlign: 'right' }}>
                                    <Checkbox onChange={handleMachineYNCheck} id="MachineYN" name="MachineYN"
                                        value={resultMainData.bMachineYN}
                                        label="MachineYN" />
                                </div>
                            </div>
                        </div>
                        <div ref={ref_divGrid} className="row">
                            <div className="col">
                                {isLoading ? <ProgressBar message=""></ProgressBar> : 
                                <GridContext.Provider
                                    value={{ setResultData, resultData, resultMainData, disableCol1, disableCol2, disableCol3 }}
                                >
                                    
                                    <Grid
                                        style={{
                                            height: gridHt, width: '100%', marginLeft: 2, marginRight: 0
                                        }}
                                        data={resultData}
                                        dataItemKey={"sGridKey"}
                                        editField="inEdit"
                                    >
                                        <GridColumn field="" title={mui(userRights, "HamatResultAndValidation", "col_btnReRun", "")} width="50px" cell={CustomCellEditReRun} />
                                        <GridColumn field="sParamDesc" title={mui(userRights, "HamatResultAndValidation", "col_ParamDesc", "Param")} width="150px" cell={CustomCellEditParam} />
                                        <GridColumn field="sOrderedYN" title={mui(userRights, "HamatResultAndValidation", "col_OrderedYN", "OrderedYN")} cell={CustomCellOrderedYN} />
                                        <GridColumn field="sPreVal1" title={mui(userRights, "HamatResultAndValidation", "col_PreVal1", "PreVal1")} cell={CustomCellPreVal1} />
                                        <GridColumn field="sPreVal2" title={mui(userRights, "HamatResultAndValidation", "col_PreVal2", "PreVal2")} cell={CustomCellPreVal2} />
                                        <GridColumn field="sPreVal3" title={mui(userRights, "HamatResultAndValidation", "col_PreVal3", "PreVal3")} cell={CustomCellPreVal3} />
                                        <GridColumn field="sRawResult" title={mui(userRights, "HamatResultAndValidation", "col_Result", "Result")} width="125px" cell={GridDataCellNumResult} />
                                        <GridColumn field="" title={mui(userRights, "HamatResultAndValidation", "col_ResultSel", " ")} width="50px" cell={CustomCellEditResultSel} />
                                        <GridColumn field="sRawResult1" title={mui(userRights, "HamatResultAndValidation", "col_Result", "Result")} width="125px" cell={GridDataCellNumResult1} />
                                        <GridColumn field="" title={mui(userRights, "HamatResultAndValidation", "col_ResultSel", " ")} width="50px" cell={CustomCellEditResult1Sel} />
                                        <GridColumn field="sRawResult2" title={mui(userRights, "HamatResultAndValidation", "col_Result", "Result")} width="125px" cell={GridDataCellNumResult2} />
                                        <GridColumn field="" title={mui(userRights, "HamatResultAndValidation", "col_ResultSel", " ")} width="50px" cell={CustomCellEditResult2Sel} />
                                        <GridColumn field="sInterpretationDesc" title={mui(userRights, "HamatResultAndValidation", "col_Interpretation", "Interpretation")} width="200px" cell={CustomCellEditInterp} />
                                        {/*<GridColumn field="sResultColColor" title={mui(userRights, "HamatResultAndValidation", "col_ResColor", " ")} width="50px" cell={CustomCellEditResultColor} />*/}
                                        <GridColumn field="sResultComm" title={mui(userRights, "HamatResultAndValidation", "col_ResultComm", "Result Comm")} width="100px" cell={GridDataCellResultComm} />
                                        <GridColumn field="" title={mui(userRights, "HamatResultAndValidation", "col_ResultCommSel", " ")} width="50px" cell={CustomCellEditResultComm} />
                                        <GridColumn field="sRefValue" title={mui(userRights, "HamatResultAndValidation", "col_RefValue", "Ref Value")} width="90px" />
                                        <GridColumn field="sUnit" title={mui(userRights, "HamatResultAndValidation", "col_Unit", "Unit")} />
                                        );
                                    </Grid>
                                    </GridContext.Provider>
                                }
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Suspense fallback={<Loader />}>
                <ResultSelDialog selType={resultSelDialogData.selType} objRE={resultSelDialogData.objRE} selqualresult={resultSelDialogData.selqualresult} showDialog={resultSelDialogData.showDialog}
                    CodeSelected={resultSelDialogData.CodeSelected} col={resultSelDialogData.col}></ResultSelDialog>
                <RequestDialog key={'R'} data={requestDialogData} callbackRequestDialogClosed={callbackRequestDialogClosed}></RequestDialog>
            </Suspense>
        </>
    );
}
export default HamatResAndValComponent;

