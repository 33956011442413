import * as React from 'react';
import logo from '../../assets/logo.png';
import { AppBar, AppBarSection, AppBarSpacer, DrawerItem, DrawerItemProps, MenuSelectEvent } from '@progress/kendo-react-layout';
import { CUserRightsAppl, dashboardDialogData, SystemUser } from '../../types';
import UserSession from '../UserSession';
import { saveToStorage, mui } from '../../Helper';
import { useNavigate } from 'react-router-dom';
import {
    Drawer,
    DrawerContent,
    DrawerSelectEvent,
} from "@progress/kendo-react-layout";
import { CurrentMandant } from '../CurrentMandant/CurrentMandant';
import { Menu } from "@progress/kendo-react-layout";
import { Suspense, useEffect, useState } from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import DashboardDialog from '../Dashboard/DashboardDialog';
import { Button } from '@progress/kendo-react-buttons';

const NavBar = (props: any) => {
    const [dashboardDialogData, setDashboardDialogData] = useState<dashboardDialogData>({ dashboarditem: 0, showDialog: false });
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'navbar' === ura.sFormName || 'Dashboard' === ura.sFormName;
        }) : null;

    var items: any = [];

    const cancelDialog = () => {
        //props.callbackhintDialogClosed();
        setDashboardDialogData({ dashboarditem: 0, showDialog: false });
    };
    if (UserSession.getSystemSettings().CMMode === 0)
        items = [
            {
                text: mui(userRights, "navbar", "mnuDashboard", "Dashboard"), icon: "k-i-toolbar-float", selected: true,
                route: "/dashboard",
                style: { position: 'absolute', bottom: '50px', width: '100%' },

            },                
            { separator: true },
            {
                text: mui(userRights, "navbar", "mnuPatRequest", "Patient Request"), icon: "k-i-txt", selected: true,
                route: "/requestmainparent",
                style: { position: 'absolute', bottom: '91px', width: '100%'},
            },            
            { separator: true },
            { text: mui(userRights, "navbar", "mnuCobraMonitor", "Cobra Monitor"), icon: "k-i-eye", selected: true, route: "/systemfunction/cobramonitor" },
            { separator: true },
            { text: mui(userRights, "navbar", "mnuResultCriteria", "Result Criteria"), icon: "k-i-track-changes-enable", selected: true, route: "/systemfunction/resultcriteria" },
            { separator: true },
            { text: mui(userRights, "navbar", "mnuTechVal", "Technical Validation"), icon: "k-i-checkmark-outline", selected: true, route: "/systemfunction/technicalvalidation" },
            { separator: true },
            { text: mui(userRights, "navbar", "mnuWorklist", "Worklist"), icon: "k-i-list-ordered", selected: true, route: "/systemfunction/worklist" },
            { separator: true },
            { text: mui(userRights, "navbar", "mnuResrvComps", "Reserved Components"), icon: "k-i-aggregate-fields", selected: true, route: "/systemfunction/reservedcomponents" },
            { separator: true },
            { text: mui(userRights, "navbar", "mnuMatInLab", "Mat In Lab"), icon: "k-i-bell", selected: true, route: "/systemfunction/matinlab" },
            { separator: true },
            { text: mui(userRights, "navbar", "mnuAddnReqParam", "Additional Request Param"), icon: "k-i-file-ppt", selected: true, route: "/systemfunction/addnreqparam" },
            { separator: true },
            { text: mui(userRights, "navbar", "mnuMatInLabList", "Mat In Lab List"), icon: "k-i-paste-markdown", selected: true, route: "/systemfunction/matinlablist" },
            { separator: true },
            { text: mui(userRights, "navbar", "mnuComponentOrderList", "Component Order List"), icon: "k-i-blur", selected: true, route: "/systemfunction/comporderlist" },
            { separator: true },           
            UserSession.getSystemSettings().hideInternalMenus === "0" && { text: "Test: Leena", icon: "k-i-user", selected: true, route: "/leena" },
            UserSession.getSystemSettings().hideInternalMenus === "0" && { separator: true },
            UserSession.getSystemSettings().hideInternalMenus === "0" && { text: "Test: Mahesh", icon: "k-i-user", selected: true, route: "/mahesh" },
            UserSession.getSystemSettings().hideInternalMenus === "0" && { separator: true },
            UserSession.getSystemSettings().hideInternalMenus === "0" && { text: "Test: Sachin", icon: "k-i-user", selected: true, route: "/sachin" },
        ];
    else
        items = [
            { text: "Dashboard", icon: "k-i-toolbar-float", selected: true, route: "/dashboard" }
        ];

    const [menuItems, setMenuItems] = useState<any[]>([]);

    useEffect(() => {
        //console.log('NavBar Mount');
        const mi: any[] = [];
        //mi.push(
        //    {
        //        "text": "Dashboard",
        //        "items": [{ data: "cobramonitor", text: mui(userRights, "Dashboard", "btnCobraMonitor", "Cobra Monitor")},
        //            { data: "resultcriteria", text: mui(userRights, "Dashboard", "btnResultCriteria", "Result Criteria")},
        //            { data: "technicalvalidation", text: mui(userRights, "Dashboard", "btnTechVal", "Technical Validation")},
        //            { data: "worklist", text: mui(userRights, "Dashboard", "btnWorklist", "Worklist")},
        //            { data: "reservedcomponents", text: mui(userRights, "Dashboard", "btnResrvComps", "Reserved Components")},
        //            { data: "matinlab", text: mui(userRights, "Dashboard", "btnMatInLab", "Mat In Lab")},
        //            { data: "addnreqparam", text: mui(userRights, "Dashboard", "btnAddnReqParam", "Additional Request Param")},
        //        ]
        //    }
        //);
        mi.push(
            {
                "text": mui(userRights, "navbar", "mnuQC", "QC"),
                "items": [{ data: "QCRL", text: mui(userRights, "navbar", "mnuQCRL", "QC Result List") },
                    { data: "QCVL", text: mui(userRights, "navbar", "mnuQVRL", "QC Validation List") }]
            }
        );
        mi.push(
            {
                "text": mui(userRights, "navbar", "mnuAdministration", "Administration"),
                "items": [  { data: "CT", text: mui(userRights, "navbar", "mnuCT", "Manage Code tables") },
                    { data: "UR", "text": mui(userRights, "navbar", "mnuURA", "Manage User rights") },
                    { data: "SP", "text": mui(userRights, "navbar", "mnuSysParameters", "Manage SysParameters") }]
            }
        );
        setMenuItems(mi);

        return () => {
            //console.log('NavBar Un-Mount');
        };

    }, [])

    const navigate = useNavigate();

    const [expanded, setExpanded] = React.useState(false);

    const handleClick = () => {
        setExpanded(!expanded);
    };

    const onSelect = (e: DrawerSelectEvent) => {
        console.log('onSelect: ', e.itemTarget.props.route);
        if (e.itemTarget.props.route === '/systemfunction/cobramonitor') {
            setDashboardDialogData({ dashboarditem: 1, showDialog: true })
            return;
        }
        else if (e.itemTarget.props.route === '/systemfunction/resultcriteria') {
            setDashboardDialogData({ dashboarditem: 2, showDialog: true })
            return;
        }
        else if (e.itemTarget.props.route === '/systemfunction/technicalvalidation') {
            setDashboardDialogData({ dashboarditem: 3, showDialog: true })
            return;
        }
        else if (e.itemTarget.props.route === '/systemfunction/worklist') {
            setDashboardDialogData({ dashboarditem: 4, showDialog: true })
            return;
        }
        else if (e.itemTarget.props.route === '/systemfunction/reservedcomponents') {
            setDashboardDialogData({ dashboarditem: 5, showDialog: true })
            return;
        }
        else if (e.itemTarget.props.route === '/systemfunction/matinlab') {
            setDashboardDialogData({ dashboarditem: 6, showDialog: true })
            return;
        }
        else if (e.itemTarget.props.route === '/systemfunction/addnreqparam') {
            setDashboardDialogData({ dashboarditem: 7, showDialog: true })
            return;
        }
        else if (e.itemTarget.props.route === '/systemfunction/matinlablist') {
            setDashboardDialogData({ dashboarditem: 8, showDialog: true })
            return;
        }
        else if (e.itemTarget.props.route === '/systemfunction/comporderlist') {
            setDashboardDialogData({ dashboarditem: 9, showDialog: true })
            return;
        }
        setExpanded(!expanded);
        navigate (e.itemTarget.props.route);
    };

    const logoutUser = () => {
        var user: SystemUser = UserSession.getLoggedInUser();
        if (user) {
            user.UserID = '';
            user.AdministratorYN = "0";
            user.Language = globalThis.defaultLanguage;
            UserSession.setLoggedInUser(user);
        }
        saveToStorage("orderProvider", "");
        navigate('/login', { replace: true })
    };

    const CustomItem = (props: DrawerItemProps) => {
        var p;
        if (props.route === "/dashboard" || props.route === "/requestmainparent")
            p = { ...props, className: "drawer_dashboard" };
        else
            p = {...props};
        return (
            <DrawerItem {...p}>
                <span data-tooltip={props.text} title={props.text} className={"k-icon k-font-icon " + props.icon} />
                <div style={{ marginLeft: 10 }}>{props.text}</div>
            </DrawerItem>
        );
    };

    const handleMenuSelect = (e: MenuSelectEvent) => {
        console.log('handleMenuSelect', e.item.data);
        if (e.item.data === 'QCRL') {
            navigate('/qcresultlist', { replace: true, state: 1});            
        } else if (e.item.data === 'QCVL') {
            navigate('/qcresultlist', { replace: true, state: 2});           
        } else if (e.item.data === 'CT') {
            navigate('/codetablemanagement', { replace: true });
        } else if (e.item.data === 'UR') {
            navigate('/userrightsmanagement', { replace: true });
        } else if (e.item.data === 'SP') {
            navigate('/SysParametersManagement', { replace: true });
        } else if (e.item.data === 'cobramonitor') {
            navigate('/systemfunction/cobramonitor', { replace: true });
        } else if (e.item.data === 'resultcriteria') {
            navigate('/systemfunction/resultcriteria', { replace: true });
        } else if (e.item.data === 'technicalvalidation') {
            navigate('/systemfunction/technicalvalidation', { replace: true });
        } else if (e.item.data === 'worklist') {
            navigate('/systemfunction/worklist', { replace: true });
        } else if (e.item.data === 'reservedcomponents') {
            navigate('/systemfunction/reservedcomponents', { replace: true });
        } else if (e.item.data === 'matinlab') {           
            navigate('/systemfunction/matinlab', { replace: true });
        } else if (e.item.data === 'addnreqparam') {
            navigate('/systemfunction/additionalrequestparam', { replace: true });
        } ;
    };

    return (
        <React.Fragment>
            <AppBar>
                <AppBarSection>
                    <Button fillMode="flat" className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base">
                        <span onClick={handleClick} className="k-icon k-font-icon k-i-menu mouse-pointer" />
                    </Button>
                </AppBarSection>

                <AppBarSpacer style={{ width: 4 }} />

                <AppBarSection>
                    {<img src={logo} alt="logo" />}
                    <h1 className="title">Laboratory Information System</h1>
                </AppBarSection>

                <AppBarSpacer style={{ width: 32 }} />

                <AppBarSection style={{ visibility: UserSession.getSystemSettings().CMMode === 1 ? 'hidden' : 'inherit' }}>
                    <Menu onSelect={handleMenuSelect} items={menuItems} />
                </AppBarSection>

                <AppBarSpacer />

                <AppBarSection>
                    <span className="k-icon k-font-icon k-i-user mouse-pointer"></span> {UserSession.getLoggedInUser().UserLastName}, {UserSession.getLoggedInUser().UserFirstName}
                </AppBarSection>

                <AppBarSection>
                    <CurrentMandant callbackMandantChanged={props.callbackMandantChanged}></CurrentMandant>
                </AppBarSection>

                <AppBarSection>
                    <span data-tooltip={'Logout'} title={'Logout'} onClick={logoutUser} className="k-icon k-font-icon k-i-logout mouse-pointer"></span>
                </AppBarSection>
            </AppBar>
            <Drawer
                expanded={expanded}
                position={"start"}
                mode={"push"}
                mini={true}
                items={items.map((item: any) => ({
                    ...item,
                }))}
                onSelect={onSelect}
                item={CustomItem}
                className="k-drawer-full-menu"
            >
                <DrawerContent>{props.children}</DrawerContent>
            </Drawer>
            <Suspense fallback={<Loader />}>
                <DashboardDialog data={dashboardDialogData} callbackDialogClosed={cancelDialog } ></DashboardDialog>
            </Suspense>
        </React.Fragment >
       
    );
}
export default NavBar;