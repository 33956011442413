import { ComboBoxChangeEvent, ComboBoxCloseEvent, ComboBoxFilterChangeEvent, MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useEffect, useState } from "react";
import { LabelBold, mui } from "../../../Helper";
import { CAddressData, CPLZCat, ctDataType, PLZObject } from "../../../types";
import { CodeTableSel } from "../../CodeTableSel/CodeTableSel";
import UserSession from "../../UserSession";
import axios from 'axios';
import CustomDate from "../../Misc/CustomDate";

interface AddressTabProps {
    data: CAddressData;
    callbackCTSelected(ctid: string, controlID: string, objCT: ctDataType): any;
    callbackControlChange(e: any): any;
    callbackHandleChangeDate(value: string, source: string, supplParam1?: string, supplParam2?: string): any;
    callbaclPLZChange(value: any): any;
    contentContainerWidth: number
}

let AddressTab_source: any = null;

const AddressTab = (props: AddressTabProps) => {
    const [birthdate, setBirthdate] = useState<string | "">("");  
    const [firstUseDate, setFirstUseDate] = useState<string | "">("");

    useEffect(() => {
        AddressTab_source = axios.CancelToken.source();

        return () => {
            if (AddressTab_source) {
                AddressTab_source.cancel("Component got unmounted");
            }
        };

    }, [])

    useEffect(() => {
        setPLZData([]);
        setPLZDataSelFromGrid(null);
        if (props.data.sDomicileID !== "") {
            setPLZSearchTerm(props.data.sDomicileID)
        };
        setBirthdate(props.data.sBirthDate);
        setFirstUseDate(props.data.sFirstUseDate);
    }, [props.data])

    // ***************************************************************************
    // For PLZ Search on first tab of address form
    const [plzSearchTerm, setPLZSearchTerm] = useState<string>("");
    const plzDataCols = [
        { field: "sCode", header: "Code" },
        { field: "sCountry", header: "Country" },
        { field: "sPLZ", header: "PLZ" },
        { field: "sCity", header: "City" },
    ];
    
    // user typed something in combo
    const plzFilterChange = (event: ComboBoxFilterChangeEvent) => {
        setPLZSearchTerm(event.filter.value);
    };
    const [plzData, setPLZData] = useState<PLZObject[]>([]);
    const [plzDataSelFromGrid, setPLZDataSelFromGrid] = useState<PLZObject | null>(null);
    // multi sel combo item change
    const plzOnChange = (event: ComboBoxChangeEvent) => {        
        setPLZDataSelFromGrid(event.target.value);
        props.callbaclPLZChange(event.target.value);                
    };
    const handleOnClose = (event: ComboBoxCloseEvent) => {        
        setPLZDataSelFromGrid(event.target.value);
        props.callbaclPLZChange(event.target.value);
    };
    // Delay the api call to search address (ext lib) by 500 ms
    useEffect(() => {
        const delayedSearchPLZ = setTimeout(() => {
            if (plzSearchTerm !== "") {
                filterPLZ();
            }
        }, 500);
        return () => clearTimeout(delayedSearchPLZ);
    }, [plzSearchTerm]);

    // filter data from api
    const filterPLZ = async () => {
        if (plzSearchTerm === "") return;
        if (plzSearchTerm.length < 2) return;
        try {
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'address/getPLZData',
                JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, code: "", searchstring: plzSearchTerm }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json',
                    },
                    withCredentials: false,
                    cancelToken: AddressTab_source.token
                }
            );
            var binddata: PLZObject[] = [];
            if (response?.data !== null) {
                response?.data.forEach((o: CPLZCat) => {
                    binddata.push({
                        sCode: o.sCode,
                        sCity: o.sCity,
                        sPLZ: o.sPLZ,
                        sCountry: o.sCountry,
                        sDisplayString: o.sCode + ', ' + o.sCountry + ', ' + o.sCity + ', ' + o.sPLZ,
                        selected: true
                    });
                });
            }
            setPLZData(binddata);
            setPLZDataSelFromGrid(binddata[0]);

        } catch (err: any) {
            console.log(err);
            if (!err?.response) {
            } else if (err.response?.status === 401) {
            } else {
            }
        }

    };

    const HandleChangeDate = (value: string, source: string, supplParam1?: string, supplParam2?: string) => {
        if (source === 'birthdate') {
            setBirthdate(value);
            props.callbackHandleChangeDate(value, source, supplParam1, supplParam2);
        } else if (source === 'firstusedate') {
            setFirstUseDate(value);       
            props.callbackHandleChangeDate(value, source, supplParam1, supplParam2);
        };
    };

    return (
        <>
            <div className="container pt5 " style={{ maxWidth: props.contentContainerWidth }}>
                {/*Patient Information*/}
                <div className="row pb5">
                    <div className="col form-section-header">
                        <LabelBold>Patient Information</LabelBold>
                    </div>
                    <div className="col-10"></div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Salutation</Label></div>
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} setSelectedCT={props.callbackCTSelected} mandantChanged={0} id="sSalutation"
                                    codeTableID='303' selCode={props.data.sSalutation} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'initials'}>Initials</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="sInitials" name="sInitials" type='text' onChange={props.callbackControlChange} value={props.data.sInitials} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'lastname'}>Lastname</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="sLastName" name="sLastName" type='text' onChange={props.callbackControlChange} value={props.data.sLastName} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'firstname'}>Firstname</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="sFirstName" name="sFirstName" type='text' onChange={props.callbackControlChange} value={props.data.sFirstName} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'shortname'}>Shortname</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="sShortName" name="sShortName" type='text' onChange={props.callbackControlChange} value={props.data.sShortName} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        {/*<MyDatePicker label={mui(userRights, "common", "lblBirthdate", "Birthdate")} source={'birthdate'}*/}
                        {/*    parentOnChange={props.callbackHandleChangeDate}*/}
                        {/*    required={false} pickerValue={birthdate} setPickerValue={setBirthdate} id={'birthdate'} supplParam1="" supplParam2=""></MyDatePicker>*/}
                        <CustomDate dateChanged={HandleChangeDate} width='150px' className='' defaulValue={birthdate} id={'txtBirthDate'} source={'birthdate'} label={mui(userRights, "common", "lblBirthdate", "Birthdate")} required={false} disabled={false} supplParam1="" supplParam2=""></CustomDate>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'birthname'}>Birthname</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="sBirthName" name="sBirthName" type='text' onChange={props.callbackControlChange} value={props.data.sBirthName} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Gender</Label></div>
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} setSelectedCT={props.callbackCTSelected} mandantChanged={0} id="sSex"
                                    codeTableID='100' selCode={props.data.sSex} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                            </div>
                        </div>
                    </div>
                    <div className="col-8"></div>
                </div>

                {/*Address Information*/}
                <div className="row pt5 pb5">
                    <div className="col form-section-header">
                        <LabelBold>Address Information</LabelBold>
                    </div>
                    <div className="col-10"></div>
                </div>

                <div className="row">
                    <div className="col-4">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'address3'}>Address 3</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input width='100%' autoComplete="off" id="sAddrLine3" name="sAddrLine3" type='text' onChange={props.callbackControlChange} value={props.data.sAddrLine3} />
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'address4'}>Address 4</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="sAddrLine4" name="sAddrLine4" type='text' onChange={props.callbackControlChange} value={props.data.sAddrLine4} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'street'}>Street</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="sStreet" name="sStreet" type='text' onChange={props.callbackControlChange} value={props.data.sStreet} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'pobox'}>P.O. Box</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="sPOBox" name="sPOBox" type='text' onChange={props.callbackControlChange} value={props.data.sPOBox} />
                            </div>
                        </div>
                    </div>
                </div>

                {/*Contact Information*/}
                <div className="row pt5 pb5">
                    <div className="col form-section-header">
                        <LabelBold>Contact Information</LabelBold>
                    </div>
                    <div className="col-10"></div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'sTelPrivate'}>Tel. private</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="sTelPrivate" name="sTelPrivate" type='text' onChange={props.callbackControlChange} value={props.data.sTelPrivate} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'sTelOffice'}>Tel. office</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="sTelOffice" name="sTelOffice" type='text' onChange={props.callbackControlChange} value={props.data.sTelOffice} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'sFaxPrivate'}>Fax Private</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="sFaxPrivate" name="sFaxPrivate" type='text' onChange={props.callbackControlChange} value={props.data.sFaxPrivate} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'sFaxOffice'}>Fax Office</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="sFaxOffice" name="sFaxOffice" type='text' onChange={props.callbackControlChange} value={props.data.sFaxOffice} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'sTelInternal'}>Tel. internal</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="sTelInternal" name="sTelInternal" type='text' onChange={props.callbackControlChange} value={props.data.sTelInternal} />
                            </div>
                        </div>
                    </div>
                    <div className="col-2"></div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'email'}>E-Mail</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input width='100%' autoComplete="off" id="sEMailAddr" name="sEMailAddr" type='text' onChange={props.callbackControlChange} value={props.data.sEMailAddr} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'website'}>Website</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="sWebsite" name="sWebsite" type='text' onChange={props.callbackControlChange} value={props.data.sWebsite} />
                            </div>
                        </div>
                    </div>
                </div>

                {/*Administration Information*/}
                <div className="row pt5 pb5">
                    <div className="col form-section-header">
                        <LabelBold>Admin Information</LabelBold>
                    </div>
                    <div className="col-10"></div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'ahvnr'}>AHV Nr</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input width='100%' autoComplete="off" id="sAhvNr" name="sAhvNr" type='text' onChange={props.callbackControlChange} value={props.data.sAhvNr} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'passportid'}>Passport ID</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="sPassportID" name="sPassportID" type='text' onChange={props.callbackControlChange} value={props.data.sPassportID} />
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'key2'}>Key 2</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="sKey2" name="sKey2" type='text' onChange={props.callbackControlChange} value={props.data.sKey2} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'key3'}>Key 3</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="sKey3" name="sKey3" type='text' onChange={props.callbackControlChange} value={props.data.sKey3} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'transfercode'}>Transfer Code</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="sTransferCode" name="sTransferCode" type='text' onChange={props.callbackControlChange} value={props.data.sTransferCode} />
                            </div>
                        </div>
                    </div>
                    <div className="col"></div>
                </div>

                {/*Other/Miscellaneous Information*/}
                <div className="row pt5 pb5">
                    <div className="col form-section-header">
                        <LabelBold>Miscellaneous</LabelBold>
                    </div>
                    <div className="col-10"></div>
                </div>

                <div className="row">
                    <div className="col">
                        {/*<MyDatePicker label="First use date" source={'firstusedate'}*/}
                        {/*    parentOnChange={props.callbackHandleChangeDate}*/}
                        {/*    required={false} pickerValue={firstUseDate} setPickerValue={setFirstUseDate} id={'firstusedate'} supplParam1="" supplParam2=""></MyDatePicker>*/}
                        <CustomDate dateChanged={HandleChangeDate} width='150px' className='' defaulValue={firstUseDate} id={'firstusedate'} source={'firstusedate'} label={mui(userRights, "common", "lblFirstusedate", "FirstUseDate")} required={false} disabled={false}></CustomDate>
                    </div>
                    <div className="col-4">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Component group</Label></div>
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} setSelectedCT={props.callbackCTSelected} mandantChanged={0} id="sCompGroup"
                                    codeTableID='609' selCode={props.data.sCompGroup} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>PLZ</Label></div>
                            <div className="vertical-wrapper-child">
                                <MultiColumnComboBox
                                    id="cboPLZ"
                                    data={plzData}
                                    value={plzDataSelFromGrid}
                                    columns={plzDataCols}
                                    filterable={true}
                                    textField={"sDisplayString"}
                                    onFilterChange={plzFilterChange}
                                    onChange={plzOnChange}
                                    onClose={handleOnClose}
                                    placeholder="Search PLZ ..." />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container pt5">
                    <div className="row">
                        <div className="col">
                            <div className="vertical-wrapper">
                                <div style={{ display: 'inline-block' }}>
                                    <Checkbox onChange={props.callbackControlChange} id="BillYN" name="BillYN" value={props.data.BillYN}
                                        label="Bill YN" />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="vertical-wrapper">
                                <div style={{ display: 'inline-block' }}>
                                    <Checkbox onChange={props.callbackControlChange} id="DoNotUseForStatsYN" name="DoNotUseForStatsYN"
                                        value={props.data.DoNotUseForStatsYN}
                                        label="Do not use for stats" />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="vertical-wrapper">
                                <div style={{ display: 'inline-block' }}>
                                    <Checkbox onChange={props.callbackControlChange} id="DoNotUseEPDYN" name="DoNotUseEPDYN"
                                        value={props.data.DoNotUseEPDYN}
                                        label="Do not use epd" />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="vertical-wrapper">
                                <div style={{ display: 'inline-block' }}>
                                    <Checkbox onChange={props.callbackControlChange} id="GeneralConsentYN" name="GeneralConsentYN"
                                        value={props.data.GeneralConsentYN}
                                        label="General consent" />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="vertical-wrapper">
                                <div style={{ display: 'inline-block' }}>
                                    <Checkbox onChange={props.callbackControlChange} id="WarmTransfusionYN" name="WarmTransfusionYN"
                                        value={props.data.WarmTransfusionYN}
                                        label="Warm transfusion" />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default AddressTab;