import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { Grid, GridCellProps, GridColumn, GridRowProps, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { Label } from "@progress/kendo-react-labels";
import React, { Suspense } from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { mui } from "../../Helper";
import { CUserRightsAppl, emptyQCResultList, qcResultEntryDialog, qcResultList, selCodeDataType } from "../../types";
import { SelectCode } from "../SelectCode/SelectCode";
import UserSession from "../UserSession";
import axios from 'axios';
import NavBar from "../NavBar/NavBar";
import { Button } from "@progress/kendo-react-buttons";
import QCResultEntryDialog from "./QCResultEntryDialog";
import ProgressBar from "../Misc/ProgressBar";

const initialSort: Array<SortDescriptor> = [
    /* { field: "reqid", dir: "asc" },*/
];

type QcFormState = {
    loadFor: number;  
};

const QCResultList = (props: QcFormState) => {
    const { state } = useLocation();
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'QCResultList' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const [qcData, setQCData] = useState<qcResultList[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [workplaceID, setWorkplaceID] = useState<string | "">(""); 
    const [sort, setSort] = useState(initialSort);

    const dlgQCResultEntryEmptyState: qcResultEntryDialog = {
        mandantid: "", userid: "", formstate:0, showDialog: false, obj: emptyQCResultList, ResultSaved: () => { }       
    }
    const [qcResultDialogData, setQCResultDialogData] = useState<qcResultEntryDialog>(dlgQCResultEntryEmptyState);    

    const callbackCodeSelected = async (tableid: string, controlID: string, obj: selCodeDataType) => {
        setWorkplaceID(obj.code);
        fillQCResultListData(obj.code);
    };

    const rowRender = (
        trElement: React.ReactElement<HTMLTableRowElement>,
        props: GridRowProps
    ) => {
        const rowColor = { backgroundColor: props.dataItem.rowcolor };
        const trProps: any = { style: rowColor };
        return React.cloneElement(
            trElement,
            { ...trProps }
        );
    };

    const fillQCResultListData = async (workplaceID: string) => {        
        if (workplaceID === null) return;
        if (workplaceID !== '') {           
            setIsLoading(true);
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'QCResultList/getQCResultListData',
                JSON.stringify({ sMandantID: UserSession.getLoggedInUser().CurrentMandant, sWorkPlaceID: workplaceID, enmQcFormState: state-1 }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );           
            setQCData(response?.data);
            setIsLoading(false);
        };
    };

    const callbackMandantChanged = async () => {
    };

    const handleEditClick = (obj: qcResultList, action: string) => {        
        const ResultSaved = async (save: boolean) => {            
            setQCResultDialogData(dlgQCResultEntryEmptyState);            
            if (save === true) {
                if (workplaceID!=="") fillQCResultListData(workplaceID);
            };
        };       
        setQCResultDialogData({
            mandantid: UserSession.getLoggedInUser().CurrentMandant, userid: UserSession.getLoggedInUser().UserID,
            formstate: state - 1, showDialog: action === 'open' ? true : false, obj: obj, ResultSaved: ResultSaved           
        })
    };  
    const CustomCellEdit = (props: GridCellProps) => {        
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <Button className="buttons-container-button" icon="zoom-in"
                    onClick={() => {
                        handleEditClick(props.dataItem, 'open');
                    }}
                >
                </Button>
            </td>
        );
    };

    useEffect(() => {
        setWorkplaceID("");
        setQCData([]);        
    }, [state]);
      
    return (
        <>
            <NavBar callbackMandantChanged={callbackMandantChanged}>
                <div className="pane-content" style={{ height: 'calc(100vh - 80px)', width:'99%', padding: "5px", border: '0px solid red' }}>
                    {/*<div className="row">*/}
                    {/*    <div className="col">*/}
                    {/*        <b><Label editorId={'valCriteria'}>{UserSession.getLoggedInUser().CurrentMandant}</Label></b>*/}
                    {/*    </div>*/}
                    {/*</div>               */}
                    <div className="row" style={{ marginTop: 5 }}>
                        <div className="col-1">
                            <Label editorId={'QCResultList'}>{mui(userRights, "QCResultList", "lblWorkplaceID", "WorkplaceID")}</Label>
                        </div>
                        <div className="col-3">
                            <SelectCode autocomplete={false} disabled={false} setSelectedTable={callbackCodeSelected} id="sWorkplaceID"
                                tablename="Workplace" code="WorkPlaceID" desc="Descr" selCode={workplaceID}
                                mandantChanged={0} width={"100%"} supplParam1="" supplParam2=""></SelectCode>
                        </div>
                    </div>
                    <div className="row"><div className="col">&nbsp;</div></div>
                    <div className="row">
                        <div className="col">
                            {isLoading ? <ProgressBar message=""></ProgressBar> : 
                            <Grid
                                style={{
                                    height: '100%', width: '100%'
                                }}
                                data={orderBy(qcData, sort)}
                                sortable={true}
                                sort={sort}
                                onSortChange={(e: GridSortChangeEvent) => {
                                    setSort(e.sort);
                                }}
                                rowRender={rowRender}
                            >
                                <GridColumn field="" title={""} width="50px" cell={CustomCellEdit} />
                                <GridColumn field="sBarcode" title={mui(userRights, "QCResultList", "col_Barcode", "Barcode")} />
                                <GridColumn field="sQCMaterialID" title={mui(userRights, "QCResultList", "col_QCMaterialID", "QCMaterialID")} />
                                <GridColumn field="sLotID" title={mui(userRights, "QCResultList", "col_LotID", "LotID")} />
                                <GridColumn field="sValidFrom" title={mui(userRights, "QCResultList", "col_ValidFrom", "Valid From")} width="100px" />
                                <GridColumn field="sValidTo" title={mui(userRights, "QCResultList", "col_ValidTo", "Valid To")} width="100px" />
                                <GridColumn field="sInUseFrom" title={mui(userRights, "QCResultList", "col_InUseFrom", "InUseFrom")} width="100px" />
                                <GridColumn field="sInUseTo" title={mui(userRights, "QCResultList", "col_InUseTo", "InUseTo")} width="100px" />
                                <GridColumn field="sExpiryDate" title={mui(userRights, "QCResultList", "col_ExpiryDate", "Expiry Date")} width="100px" />
                                <GridColumn field="sQCMatDescr" title={mui(userRights, "QCResultList", "col_QCMatDescr", "QCMatDescr")} width="500px" />
                                );
                                </Grid>
                            }
                        </div>
                    </div>
                </div>               
            </NavBar>
            <Suspense fallback={<Loader />}>
                <QCResultEntryDialog mandantid={qcResultDialogData.mandantid} userid={qcResultDialogData.userid}
                    formstate={qcResultDialogData.formstate} showDialog={qcResultDialogData.showDialog} obj={qcResultDialogData.obj}
                    ResultSaved={qcResultDialogData.ResultSaved}></QCResultEntryDialog>
            </Suspense>
        </>
    );
}
export default QCResultList;