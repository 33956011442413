import * as React from 'react';
import { TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch";
import './Sachin.css'
import NavBar from '../NavBar/NavBar';
import ViewPDF from '../ModalForms/ViewPDF/ViewPDF';
import { Button } from '@progress/kendo-react-buttons';
import { useCallback, useEffect, useState } from 'react';
import { AutoComplete, AutoCompleteChangeEvent } from '@progress/kendo-react-dropdowns';
import UserSession from '../UserSession';
import axios from 'axios'
import CustomDate from '../Misc/CustomDate';
import { formatDate, formatTime } from '../../Helper';
import CustomTime from '../Misc/CustomTime';

export const SampleFileBase64 = '';

const debounce = (fn: Function, ms = 300) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
};

const Sachin = (props: any) => {
    const [controlDate, setControlDate] = useState(formatDate(new Date().toDateString()));
    const [controlTime, setControlTime] = useState(formatTime(new Date().toLocaleString(), true));
    const fetchData = async (filter: string) => {
        // console.log(filter, GetParamData_URL + '&f=' + filter + '&cnt=true&s=10&t=10');
        const response = await axios.get(GetParamData_URL + '&f=' + filter + '&cnt=true&s=10&t=10', {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                'Content-Type': 'application/json'
            },
            withCredentials: false
        });
        // console.log(response?.data);
        setState((currState) => ({
            ...currState,
            data: response?.data
        }));
    }

    const optimizedFn = useCallback(debounce(fetchData), []);

    useEffect(() => {
        return () => {
        };

    }, [])
    const GetParamData_URL = UserSession.getSystemSettings().API_URL + 'ParamSel/getparamsearchdata?mandantid=' + UserSession.getLoggedInUser().CurrentMandant;
    const init = { method: 'GET', accept: 'application/json', headers: {} };
    const [state, setState] = useState({ data: [], value: '', loading: false });

    const [showPDF, setShowPDF] = React.useState(false);

    const callbackPDFDialogClosed = () => {
        setShowPDF(false);
    }


    const onChange = (event: AutoCompleteChangeEvent) => {
        // console.log(event);
        setState({ data: state.data, value: event.target.value, loading: state.loading });
        if (event.target.value) {
            optimizedFn(event.target.value);
        }     
    }

    const consoleLogAllElements = () => {
        var ancestor = document.getElementById('root');
        if (ancestor !== null) {
            var descendents = ancestor.getElementsByTagName('input');
            var i, e;
            for (i = 0; i < descendents.length; ++i) {
                e = descendents[i];
                console.log(e.value, e.id);
            }
            var autocomp: any = document.getElementById('txtAutoComplete')
            if (autocomp !== null) {
                console.log('>>> ', autocomp.value, autocomp.tabindex);
            }
        }
    }

    const onDateChange = (value: string, source: string) => {
        setControlDate(value);
    };

    const onTimeChange = (value: string, source: string) => {
        setControlTime(value);
    };

    const Controls = () => {
        const { zoomIn, zoomOut, resetTransform } = useControls();
        return (
            <>
                <button style={{width: 100, height: 30}} onClick={() => zoomIn()}>+</button>
                <button style={{ width: 100, height: 30 }} onClick={() => zoomOut()}>-</button>
                <button style={{ width: 100, height: 30 }} onClick={() => resetTransform()}>Reset</button>
            </>
        );
    };

    return (
        <>
            <NavBar callbackMandantChanged={() => { }}>
                <Button themeColor={'primary'} onClick={() => { setShowPDF(true) }}>Show PDF</Button>
                <ViewPDF callbackPDFDialogClosed={callbackPDFDialogClosed} url={""} base64PDF={SampleFileBase64} showDialog={showPDF}></ViewPDF>
                <AutoComplete style={{ width: 400 }}
                    data={state.data}
                    value={state.value}
                    onChange={onChange}
                    textField='sNameOnRep'
                    id="txtAutoComplete"
                    tabIndex={-1}
                />
                <Button tabIndex={-1} themeColor={'primary'} onClick={consoleLogAllElements}>Iterate Elements</Button>
                <div className="row">
                    <div className="col-2"><CustomDate dateChanged={onDateChange} width='150px' className='k-input-header' defaulValue={controlDate} id={'txtBirthDate'} source={'address'} label={'Birthdate'} required={false} disabled={false}></CustomDate></div>
                    <div className="col-2"><CustomTime allowSecond={true} timeChanged={onTimeChange} width='150px' className='k-input-header' defaulValue={controlTime} id={'txtBirthTime'} source={'address'} label={'Birthtime'} required={false} disabled={false}></CustomTime></div>
                    <div className="col-8"></div>
                </div>
                <div className="row">
                    <TransformWrapper>
                        <div className="row">
                            <div className="col">
                                <Controls />
                            </div>
                        </div>
                        <TransformComponent>
                            <div className="row">
                                <div className="col">
                                    <img height="500px" width="500px" src="http://www.baroc.co.in/images/slider/bg1.jpg" alt="test" />
                                </div>
                            </div>
                    </TransformComponent>
                    </TransformWrapper>
                </div>
            </NavBar>
        </>
    );
}

export default Sachin;

