import React, { useRef, useEffect, useState } from 'react'
import axios from 'axios';
import UserSession from '../UserSession';
import { Loader } from "@progress/kendo-react-indicators";
import { ReverseDateForSorting } from '../../Helper';
import { Grid, GridCellProps, GridColumn, GridSortChangeEvent, GridSelectionChangeEvent, getSelectedState } from "@progress/kendo-react-grid";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { Checkbox } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { Label } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { MultiSelect, MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { MasterTableSel } from "../MasterTableSel/MasterTableSel";
import { masterTableDataType } from "../../types";
import ViewPDF from '../ModalForms/ViewPDF/ViewPDF';
import {
    load,
    loadMessages, } from '@progress/kendo-react-intl';

import deMessages from "../../de.json";
import itMessages from "../../it.json";
import frMessages from "../../fr.json";

/* CLDR Data */
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";

import gbCaGregorian from "cldr-dates-full/main/en-GB/ca-gregorian.json";
import gbDateFields from "cldr-dates-full/main/en-GB/dateFields.json";

import deCaGregorian from "cldr-dates-full/main/de/ca-gregorian.json";
import deDateFields from "cldr-dates-full/main/de/dateFields.json";

import itCaGregorian from "cldr-dates-full/main/it/ca-gregorian.json";
import itDateFields from "cldr-dates-full/main/it/dateFields.json";

import frCaGregorian from "cldr-dates-full/main/fr/ca-gregorian.json";
import frDateFields from "cldr-dates-full/main/fr/dateFields.json";
import CustomDate from '../Misc/CustomDate';
import ProgressBar from '../Misc/ProgressBar';

load(
    likelySubtags,
    currencyData,
    weekData,
    deCaGregorian,
    deDateFields,
    gbCaGregorian,
    gbDateFields,
    itCaGregorian,
    itDateFields,
    frCaGregorian,
    frDateFields,
);

type workListEntry = {
    worklistnr: string;
    workselectid: string;
    workgroup: string;
    checkprocessday: string;
    refdatetime: string;
    workgroupid: string;
    dtrefdatetime: string;
    paramid: string;
}

const initialSort: Array<SortDescriptor> = [
    { field: "worklistnr", dir: "desc" },
];

interface WorkListProps {
    mandantChanged: number;
    loadfor: string;
}

var emptyform = {
    emptyform: true,    
    worklistnr: "",
    workselectid: "",
    workgroup: "",
    checkprocessday: "0",
    refdatetime: "",
    workgroupid: "",
    dtrefdatetime: "",    
    paramid:"",
};

type workListOutput = {
    errdesc: string;
    msg: string;
    worklistnrs: string;
    pdffile: string;
    pdfbase64: string;
    worklistdeleted: boolean;
    newworklists: workListEntry[]; 
};
          
const WorkList = (props: WorkListProps) => {
    var objWorklistOutput:workListOutput;
    
    const [isLoading, setIsLoading] = useState(false);
    const worklistnrRef = useRef<HTMLInputElement>(null);
    const [sort, setSort] = React.useState(initialSort);
    const [wlist, setWlist] = useState<workListEntry[]>([]);  
    const [selectedwlist, setSelectedwlist] = useState<workListEntry | null | undefined>(null);
    const [selectedState, setSelectedState] = useState<{
        [id: string]: boolean | number[];
    }>({});

    useEffect(() => {        
        const lang: string = UserSession.getLanguage();
        if (lang === "de") {
            loadMessages(deMessages, "de-DE");
        }
        else if (lang === "it") {
            loadMessages(itMessages, "it-IT");
        }
        else if (lang === "fr") {
            loadMessages(frMessages, "fr-FR");
        }

        worklistnrRef.current?.focus();

        return () => {
        };
    }, [])        

    //useEffect(() => {
    //    stateRefData.current = data;
    //}, [data]);

    const [qcParamIDs, setqcParamIDs] = React.useState([]);
    const [selQCParamIDs, setSelQCParamIDs] = React.useState([]);    
    const [selectedCodewg, setSelectedCodewg] = useState("");
    //const [MasterTableDatawg, setMasterTableDatawg] = useState<masterTableDataType | null>(null);
    const [selectedCodewgs, setSelectedCodewgs] = useState("");
    const [MasterTableDatawgs, setMasterTableDatawgs] = useState<masterTableDataType | null>(null);    
    const [refDate, setrefDate] = React.useState<string | "">(""); 
    const [disabled, setDisabled] = React.useState(false);
    const [pdfBase64, setPdfBase64] = useState("");
    const [showPDF, setShowPDF] = React.useState(false);
    
    const callbackMTSelectedwg = async (tablename: string, objMT: masterTableDataType) => {
        setSelectedCodewg(objMT.sCode);
        //setMasterTableDatawg(objMT);
        setqcParamIDs([]);
        setSelQCParamIDs([]);
        setIsLoading(false);
    };

    const callbackMTSelectedwgs = async (tablename: string, objMT: masterTableDataType) => {
        setSelQCParamIDs([]);
        setSelectedCodewgs(objMT.sCode);
        setMasterTableDatawgs(objMT);   
        setIsLoading(false);
    };

    useEffect(() => {        
        getQCParams();
        setIsLoading(false);
    }, [MasterTableDatawgs]);

    const fillwlist = async () => {
        try {
            setIsLoading(true);
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'WorklistCriteria/getworklistdata',
                { mandantid: UserSession.getLoggedInUser().CurrentMandant },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
            var binddata: workListEntry[] = [];
            if (response?.data !== null)
                response?.data.forEach((o: workListEntry) => {
                    binddata.push({ ...o, dtrefdatetime: ReverseDateForSorting(o.refdatetime) });
                });
            setWlist(binddata);
            setIsLoading(false);
            return;
        } catch (err: any) {
            console.log(err);
            if (!err?.response) {
            } else if (err.response?.status === 401) {
            } else {
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fillwlist();
    }, [props.mandantChanged]);

    useEffect(() => {
        if (wlist != null) {
            if (wlist.length === 1) {
                var oneWorklist: workListEntry = wlist[0];
                setSelectedwlist(oneWorklist);
            }
        }
        //setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wlist])

    useEffect(() => {
        fillwlist();
    }, [])


    const CustomCellDelete = (props: GridCellProps) => {
        const worklistnr = props.dataItem['worklistnr'];
        const workselectid = props.dataItem['workselectid'];
        const workgroup = props.dataItem['workgroupid'];
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <Button className="buttons-container-button" icon="delete"
                    onClick={async () => {
                        DeleteQCWorklist(worklistnr, workselectid, workgroup);
                    }}
                >
                </Button>
            </td>
        );
    };

    const onSelectionChange = (event: GridSelectionChangeEvent) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: 'worklistnr',
        });
        let selectedIds = []
        for (const property in newSelectedState) {
            if (newSelectedState[property]) {
                let product = wlist.find(item => item.worklistnr === property.toString())
                selectedIds.push(product)
            }
        }
        if (selectedIds.length > 0) {
            setSelectedwlist(selectedIds[0]);
            console.log(selectedIds[0])
            if (selectedIds[0]?.workgroupid !== undefined) {
                setSelectedCodewg(selectedIds[0]?.workgroupid)
                setSelectedCodewgs(selectedIds[0]?.workselectid)         
                if (selectedIds[0]?.refdatetime !== '')
                    setrefDate(selectedIds[0]?.refdatetime.substring(0, 10));
                else
                    setrefDate('');
                setDisabled(true)
                getQCParams();
                setSelQCParamIDs(qcParamIDs)
            }            
        }
        setSelectedState(newSelectedState);   
        setIsLoading(false);
    };

    //const handleCheckPDCheck = (event: CheckboxChangeEvent, wlnumber: string) => {
    //    const binddata: workListEntry[] = [...wlist];
    //    var tabElement: workListEntry;
    //    for (tabElement of binddata) {
    //        if (tabElement.worklistnr === wlnumber) {
    //            tabElement.checkprocessday = event.value ? "1" : "0";
    //            break;
    //        }
    //    }
    //    setWlist(binddata);
    //};

    const handleChange = (event: MultiSelectChangeEvent) => {
        setSelQCParamIDs(event.value);
    };

    async function clearForm(): Promise<void> {        
        setSelectedwlist(emptyform);
        setSelectedCodewg("");
        setSelectedCodewgs("");
        setrefDate("");
        setDisabled(false)
        setqcParamIDs([])
        setSelQCParamIDs([])
        setTimeout(() => { worklistnrRef.current?.focus() }, 200);
        setIsLoading(false);
    };

    const getQCParams = async () => {
        try {
            setIsLoading(true);
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'WorklistCriteria/getparamsforqcworklist',
                JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, worklistnr: selectedwlist?.worklistnr, workselectid: selectedCodewgs, workgroup: selectedCodewg}),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
            setqcParamIDs(response?.data);       
            return;

        } catch (err: any) {
            console.log(err);
            if (!err?.response) {
            } else if (err.response?.status === 401) {
            } else {
            }
        }
        setIsLoading(false);
    };

    const DeleteQCWorklist = async (worklistnr: string, workselectid: string, workgroup:string) => {
        try {
            setIsLoading(true);
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'WorklistCriteria/deleteqcworklist',
                JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, worklistnr: worklistnr, workselectid: workselectid, workgroup: workgroup }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );            
            objWorklistOutput = response?.data
            if (objWorklistOutput.worklistdeleted == true) {
                var arr: workListEntry[];
                arr = wlist.filter(item => item.worklistnr !== worklistnr);
                setWlist(arr);
            } else {
                if (objWorklistOutput.msg !== '') {
                    alert(objWorklistOutput.msg);
                } else {
                    if (objWorklistOutput.errdesc !== '') {
                        alert(objWorklistOutput.errdesc);
                    }
                }
            };

        } catch (err: any) {
            console.log(err);
            if (!err?.response) {
            } else if (err.response?.status === 401) {
            } else {
            }
        }
        setIsLoading(false);
    };

    const PrintWorkList = async () => {
        try {      
            setIsLoading(true);
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'WorklistCriteria/printworklist',
                JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, workgroup: selectedwlist?.workgroupid, selectedworkgroups: '', worklistnrs: selectedwlist?.worklistnr, showwithpositiveresults: false, extraworkpagerequired: false, fromrequestentryform: false }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
            objWorklistOutput = response?.data
            if (objWorklistOutput.pdfbase64 !== '') {
                setPdfBase64(objWorklistOutput.pdfbase64);
                setIsLoading(false);
                setShowPDF(true);

            } else {
                if (objWorklistOutput.errdesc !== '') {
                    alert(objWorklistOutput.errdesc);
                }
            };
            setIsLoading(false);
            return;
        } catch (err: any) {
            console.log(err);
            if (!err?.response) {
            } else if (err.response?.status === 401) {
            } else {
            }
        }
        setIsLoading(false);
    };

    async function GenerateWorkList(): Promise<void> {
        try {
            setIsLoading(true);
            var worklistnr, workgroup, workselectid, checkprocessday: boolean, qcparams 
            console.log('refDate - ' + refDate)
            if (selectedwlist?.worklistnr === undefined || selectedwlist?.worklistnr === null || selectedwlist?.worklistnr === '') {                
                worklistnr = "";                
                workgroup = selectedCodewg;                
                workselectid = selectedCodewgs;                
                checkprocessday = false;
                qcparams = selQCParamIDs;
            } else {
                console.log('selectedwlist is not nothing')
                worklistnr = selectedwlist?.worklistnr;
                workgroup = selectedwlist?.workgroupid;
                workselectid = selectedwlist?.workselectid;
                checkprocessday = (selectedwlist?.checkprocessday === "1" ? true : false);
                qcparams = selectedwlist?.paramid;
            };            
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'WorklistCriteria/generateworklist',
                JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, worklistnr: worklistnr, workgroup: workgroup, workselectid: workselectid, refdatetime: refDate, language: 1, checkprocessingday: checkprocessday, userid: UserSession.getLoggedInUser().UserID, qcparams: qcparams }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
            if (response?.data !== null) {
                objWorklistOutput = response?.data
                if (objWorklistOutput.worklistnrs !== '') {
                    var newArray = wlist.slice();
                    objWorklistOutput.newworklists.forEach((o: workListEntry) => {
                        newArray.push({ ...o, dtrefdatetime: ReverseDateForSorting(o.refdatetime) });
                    });
                    setWlist(newArray);

                } else {
                    if (objWorklistOutput.errdesc !== '') {
                        alert(objWorklistOutput.errdesc);
                    } else {
                        if (objWorklistOutput.msg !== '') {
                            alert(objWorklistOutput.msg);
                        }
                    }
                }
           
            };
            setIsLoading(false);
            return;
        } catch (err: any) {
            console.log(err);
            if (!err?.response) {
            } else if (err.response?.status === 401) {
            } else {
            }
        }
        setIsLoading(false);
    };

    const callbackPDFDialogClosed = () => {
        setShowPDF(false);
    };
    const handleDateChange = (value: string) => {
        setrefDate(value);
    };
    var contentWidth: number = window.innerWidth
    const location: string = props.loadfor;
    var formHeight: number = window.innerHeight - 120;
    var gridHeight: number = formHeight;
    if (location === "modal") {
        gridHeight -= 170; // as dialog
        contentWidth -= 400;
    }
    else {
        formHeight -= 50;
        gridHeight -= 220; // as dashboard
        contentWidth -= 120;
    }
    

    return (
        <>
            <div className="container-fluid" style={{ overflowX: "hidden", height: formHeight, width: '98%', minWidth: contentWidth }}>
                <div className="row" style={{ border: '1px solid lightblue', margin: 5, backgroundColor: "#e0f0ff", borderRadius: 0 }}>
                    <div className="row">
                        <div className="col-12">&nbsp;</div>
                    <div className="col">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child"><Label editorId={'WorklistNr'}>WorklistNr</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input disabled={disabled} autoComplete="off" id="txtworklistnr" name="WorklistNr" ref={worklistnrRef} value={selectedwlist?.worklistnr} style={{ width: '250px' }}
                                    type='text' />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child"><Label editorId={'workgroup'}>WorkGroup</Label></div>
                            <div className="vertical-wrapper-child">
                                <MasterTableSel disabled={disabled} setSelectedValue={callbackMTSelectedwg} mandantChanged={props.mandantChanged}
                                    tableName='WorkGroup' selCode={selectedCodewg} dependentCode={null} width="300px"></MasterTableSel>
                            </div>
                        </div>                   
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child"><Label editorId={'workselectid'}>WorkSelectID</Label></div>
                            <div className="vertical-wrapper-child">
                                <MasterTableSel disabled={disabled} setSelectedValue={callbackMTSelectedwgs} mandantChanged={props.mandantChanged}
                                    tableName='WorkGroupSelect' selCode={selectedCodewgs} dependentCode={selectedCodewg} width="300px"></MasterTableSel>
                            </div>
                        </div>        
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child"><Label editorId={'paramid'}>ParamID</Label></div>
                            <div className="vertical-wrapper-child">
                                <MultiSelect
                                    disabled={disabled}
                                    data={qcParamIDs}
                                    value={selQCParamIDs}
                                    onChange={handleChange}
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder=""
                                />
                            </div>
                        </div>        
                    </div>
                    <div className="col"></div>
                    <div className="col"></div>
                </div>
                <div className="row">
                    <div className="col-2">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child"><Label editorId={'refdate'}>RefDate</Label></div>                            
                            <CustomDate defaulValue={refDate} id={'refdate'} source={'refdate'} label={""}
                                required={false} disabled={false} width={'150px'} supplParam1="" supplParam2="" className="k-input-header"
                                    dateChanged={handleDateChange}></CustomDate>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper">                            
                            <div style={{display: 'inline-block', paddingTop: 25 }}>
                                <Checkbox checked={disabled} value={selectedwlist?.checkprocessday === "1" ? true : false} label={'Check Processing Day'}></Checkbox>
                            </div>
                        </div>
                    </div>                        
                    <div className="col-4">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child">
                                <Button type={"button"} style={{ width: '150px', marginTop: 20, marginRight: 10 }} themeColor={'primary'}
                                    onClick={() => {
                                        PrintWorkList();
                                    }}
                                >Print</Button>
                                <Button type={"button"} style={{ width: '150px', marginTop: 20, marginRight: 10 }} themeColor={'primary'}
                                    onClick={() => {
                                        GenerateWorkList();
                                    }}
                                >OK</Button>
                                <Button type={"button"} style={{ width: '150px', marginTop: 20 }} themeColor={'secondary'}
                                    onClick={() => {
                                        clearForm();
                                    }}
                                >Clear</Button>
                            </div>
                        </div>
                    </div>                    
                    </div>
                    <div className="col-12">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col">
                        {isLoading ? <ProgressBar message=""></ProgressBar> : 
                        <Grid
                            style={{
                                height: gridHeight, width:'100%'
                            }}
                            data={orderBy(wlist, sort)}
                            selectable={{
                                enabled: true,
                                drag: false,
                                cell: false,
                                mode: "single",
                            }}
                            onSelectionChange={onSelectionChange}
                            sortable={true}
                            sort={sort}
                            onSortChange={(e: GridSortChangeEvent) => {
                                setSort(e.sort);
                            }}
                        >
                            <GridColumn field="" title="" width="50px" cell={CustomCellDelete} />
                            <GridColumn field="worklistnr" title="Worklist Nr." width="150px" />
                            <GridColumn field="workselectid" title="Work select id" width="150px" />
                            <GridColumn field="workgroup" title="Workgroup" width="250px" />
                            <GridColumn field="checkprocessday" title="Check process day" width="200px" />
                            <GridColumn field="refdatetime" title="Ref. date/time" width="150px" />
                            <GridColumn field="workgroupid" title="Workgroup id" width="150px" />
                            );
                            </Grid>
                        }
                    </div>
                </div>
            </div>
            
            <ViewPDF callbackPDFDialogClosed={callbackPDFDialogClosed} url={""} base64PDF={pdfBase64} showDialog={showPDF}></ViewPDF>            
            </>
    );
}

export default WorkList;