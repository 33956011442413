import { Suspense, useEffect, useRef, useState, useCallback } from "react";
import { MasterTableSel } from "../MasterTableSel/MasterTableSel";
import { dlgEmptyInputBoxState, enmInputBoxType, inputBoxDialog, masterTableDataType, ReqInfoBar, resultEntryDialog } from "../../types";
import { Button } from "@progress/kendo-react-buttons";
import { FIDWiseReqInfo } from "../FIDWiseReqInfo/FIDWiseReqInfo";
import { useNavigate } from 'react-router-dom';
import UserSession from '../UserSession';
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import RequestInfoBar from "../ReqInfoBar/RequestInfoBar";
import NavBar from "../NavBar/NavBar";
import IHValidation from "../ResultAndValidation/IHValidation";
import { InputBoxDialog } from "../InputBoxDialog/InputBoxDialog";
import QCResultList from "../QCResult/QCResultList";
import { TabStrip, TabStripTab, Timeline, sortEventList, TabStripSelectEventArguments } from "@progress/kendo-react-layout";
import { events } from "../../events";
import { Popover, PopoverActionsBar } from "@progress/kendo-react-tooltip";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Window } from "@progress/kendo-react-dialogs";
import { TabTitle } from "../../Helper";

const Mahesh = (props: any) => {
    const dlgEmptyResState: resultEntryDialog = {
        mandantid: "", reqid: "", pid: "", fid: "", paramid: "", reqdatetime: "", reqprovider: "", valtype: "", addedparams: "", pregnancyweek: "", mbyn: "", userid: "", showDialog: false, ResultSaved: () => { }, selprofilecode: "", dayWorkSpace: false
    };

    const sortedEvents = sortEventList(events);
    const sortedEvents1 = sortEventList(events);
    const [ihDialogData, setIHDialogData] = useState<resultEntryDialog>(dlgEmptyResState);
    const [inputBoxDialogData, setInputBoxDialogData] = useState<inputBoxDialog>(dlgEmptyInputBoxState);

    const [gridData, setGridData] = useState<[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        console.log('Mount: Mahesh');

        return () => {
            console.log('Unmount: Mahesh');
        };

    }, [])
    const anchor = useRef<Button>(null);
    const anchor1 = useRef<Button>(null);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [clientID, setclientID] = useState("");
    const [selectedCodewg, setSelectedCodewg] = useState("");
    const [MasterTableDatawg, setMasterTableDatawg] = useState<masterTableDataType | null>(null);
    const [selectedCodewgs, setSelectedCodewgs] = useState("");
    const [MasterTableDatawgs, setMasterTableDatawgs] = useState<masterTableDataType | null>(null);
    const [disabled, setDisabled] = useState(false);
    const [reqInfo, setReqInfo] = useState<ReqInfoBar | null>(null);

    const [tabTLvalue, setTabTLValue] = useState(0);

    const handleTLTabSelect = (e: TabStripSelectEventArguments) => {
        setTabTLValue(e.selected);
    };

    const callbackMTSelectedwg = async (tablename: string, objMT: masterTableDataType) => {
        setSelectedCodewg(objMT.sCode);
        setMasterTableDatawg(objMT);
    };

    const callbackMTSelectedwgs = async (tablename: string, objMT: masterTableDataType) => {
        setSelectedCodewgs(objMT.sCode);
        setMasterTableDatawgs(objMT);
    };

    const callbackReqInfo = async (objReqInfo: ReqInfoBar) => {
        setReqInfo(objReqInfo);
    };

    const ShowValue = () => {
        alert(MasterTableDatawg?.DisplayString);
    };

    const ResultSaved = async (validated: boolean) => {
        setIHDialogData(dlgEmptyResState);
    };
    const setText = async (inputText: string) => {
        setInputBoxDialogData(dlgEmptyInputBoxState);
    };
    const ShowIHVal = () => {
        setIHDialogData({
            mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: '10002492', pid: '200000024', fid: '1000000029',
            paramid: "", reqdatetime: '21.09.2022 16:45:07', reqprovider: 'LABO', valtype: 'ih', addedparams: "", pregnancyweek: '', mbyn: "0",
            userid: UserSession.getLoggedInUser().UserID, showDialog: true, ResultSaved: ResultSaved, selprofilecode: "", dayWorkSpace: false
        });
    };

    const ShowInputBox = () => {
        setInputBoxDialogData({ promptText: "Enter Free Text", title: "Free Text", defText: "", enm: enmInputBoxType.FreeText, maxLength: 255, setText: setText, showDialog: true })
    };
    const ShowTimeline = () => {
        setVisible(!visible);
    };
    const onActionClick = (e: any) => {
        const event = e.syntheticEvent;
        event.preventDefault();
        window.open(event.target.getAttribute("href"));
    };
    const onClick =useCallback(() => {
        setShow(!show);
    }, [setShow, show]);

    const onClick1 = useCallback(() => {
        setShow1(!show1);
    }, [setShow1, show1]);

    const FireURL = () => {
        window.open("http://www.google.com");    
    };

    useEffect(() => {        
        setclientID(UserSession.getLoggedInUser().ClientName);
        console.log(reqInfo);
        var binddata: [] = [];        
        setGridData(binddata);
    }, [])      

    return (
        <>
        <NavBar callbackMandantChanged={() => { }}>
                <br></br>
               
                {/*<MasterTableSel disabled={disabled} setSelectedValue={callbackMTSelectedwg} mandantChanged={props.mandantChanged}*/}
                {/*    tableName='WorkGroup' selCode={selectedCodewg} dependentCode={null} width="300px"></MasterTableSel>*/}
                {/*<MasterTableSel disabled={disabled} setSelectedValue={callbackMTSelectedwgs} mandantChanged={props.mandantChanged}*/}
                {/*    tableName='WorkGroupSelect' selCode={selectedCodewgs} dependentCode={selectedCodewg} width="300px"></MasterTableSel>*/}
                {/*<br></br>*/}
                {/*<br></br>*/}
                {/*<Button onClick={ShowValue}> Show Sel Value</Button>*/}
                {/*<br></br>*/}
                {/*<br></br>*/}
                {/*<FIDWiseReqInfo pid='1' fid=''></FIDWiseReqInfo>*/}
                {/*<br></br>*/}
                {/*<br></br>*/}
                {/*<Button type={"button"} style={{ width: '150px', marginTop: 20 }} themeColor={'primary'}*/}
                {/*    onClick={() => {*/}
                {/*        navigate('/matinlab', { replace: true });*/}
                {/*    }}*/}
                {/*>MatInLab*/}
                {/*</Button>*/}
               
                {/*<Button type={"button"} style={{ width: '150px', marginTop: 20 }} themeColor={'primary'}*/}
                {/*    onClick={() => {*/}
                {/*        navigate('/codetablemanagement', { replace: true });*/}
                {/*    }}*/}
                {/*>CodeTableManagement*/}
                {/*</Button>*/}
                {/*<br></br>*/}
                {/*<br></br>*/}
                {/*<Label>ClientID</Label>*/}
                {/*<Input style={{ width: '250px' }} value={clientID}></Input>*/}
                {/*<br></br>*/}
                {/*<br></br>*/}
                {/*<Button type={"button"} style={{ width: '150px', marginTop: 20 }} themeColor={'primary'}*/}
                {/*    onClick={() => {*/}
                {/*        navigate('/resultcriteria', { replace: true });*/}
                {/*    }}*/}
                {/*>ResultCriteria*/}
                {/*</Button>*/}
                {/*<br></br>*/}
                {/*<br></br>*/}
                {/*<Button type={"button"} style={{ width: '150px', marginTop: 20 }} themeColor={'primary'}*/}
                {/*    onClick={() => {*/}
                {/*        navigate('/userrightsmanagement', { replace: true });*/}
                {/*    }}*/}
                {/*>UserRightsManagement*/}
                {/*</Button>*/}
                {/*<br></br>*/}
                {/*<br></br>*/}
                {/*<div className="row">*/}
                {/*    <Button type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {*/}
                {/*        ShowIHVal()*/}
                {/*    }}>{"IH Val"}</Button>&nbsp;*/}
                {/*    <Button type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {*/}
                {/*        ShowInputBox()*/}
                {/*    }}>{"ShowInputBox"}</Button>                   */}
                {/*</div>*/}
                {/*<div className="row">*/}
                {/*    <RequestInfoBar reqid={ihDialogData.reqid} valtype={ihDialogData.valtype} showmatdata={true} setSelectedReqInfo={callbackReqInfo}></RequestInfoBar>*/}
                {/*</div>              */}
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div className="row">&nbsp;</div>
                <div className="row">
                    <div className="col" style={{ textAlign: 'center' }}>
                        <Button type={"button"} style={{ width: '150px' }} ref={anchor} themeColor={'primary'} onClick={() => {
                            onClick()
                        }}>{"ShowTimelineSample1"}</Button>
                    </div>
                    <div className="col" style={{ textAlign: 'center' }}>
                        <Button type={"button"} style={{ width: '150px' }} ref={anchor1} themeColor={'primary'} onClick={() => {
                            onClick1()
                        }}>{"ShowTimelineSample2"}</Button>
                    </div>
                    <div className="col" style={{ textAlign: 'center' }}></div>
                </div>
                {show && (<Window title={"Timeline"} onClose={onClick} initialHeight={400} initialWidth={700} initialLeft={100} initialTop={300}>
                    <div>
                        <Timeline                            
                            events={sortedEvents}
                            alterMode={false}
                            collapsibleEvents={false}
                            onActionClick={onActionClick}
                            horizontal={false}
                        />
                    </div>
                </Window>)}
                <Popover
                    show={show1}
                    anchor={anchor1.current && anchor1.current.element}
                >
                    <div>
                        <Grid
                            style={{
                                height: '100%', width: '100%'
                            }}
                            data={gridData}
                            sortable={false}
                        >
                            <GridColumn field="" title={"Date"} width="150px" />
                            <GridColumn field="" title={"User"} width="150px" />
                            <GridColumn field="" title={"Action"} width="150px" />
                        </Grid>
                    </div>
                    <PopoverActionsBar>
                        <Button onClick={onClick1} fillMode={"flat"}>
                            Close
                        </Button>
                    </PopoverActionsBar>
                </Popover>
                <div className="row">&nbsp;</div>
                <div className="row">
                    <div className="col">
                        <TabStrip style={{ width: '1500px', height: "300px" }} animation={false} scrollable={false} selected={tabTLvalue} onSelect={handleTLTabSelect} keepTabsMounted={true} >
                            <TabStripTab title={<TabTitle title={"Request Timeline"} selected={tabTLvalue === 0 ? true : false} />}>
                                <Timeline className="tl-width"                                      
                                        events={sortedEvents}                                        
                                        horizontal={true}
                                    />
                            </TabStripTab>
                            <TabStripTab title={<TabTitle title={"Material Timeline"} selected={tabTLvalue === 1 ? true : false} />}>
                                <Timeline className="tl-width"                  
                                        events={sortedEvents1}                                      
                                        horizontal={true}
                                    />
                            </TabStripTab>
                            <TabStripTab title={<TabTitle title={"Param Timeline Grid"} selected={tabTLvalue === 2 ? true : false} />}>
                                    <Grid data={sortedEvents}>
                                        <GridColumn field="description" title={'description'} width="200px" />
                                        <GridColumn field="date" title={'Date'} width="400px" />
                                        <GridColumn field="title" title={'title'} width="200px" />
                                    </Grid>
                            </TabStripTab>
                            <TabStripTab title={<TabTitle title={"Extra Timeline Grid"} selected={tabTLvalue === 3 ? true : false} />}>
                                    <Grid data={sortedEvents}>
                                        <GridColumn field="description" title={'description'} width="200px" />
                                        <GridColumn field="date" title={'Date'} width="400px" />
                                        <GridColumn field="title" title={'title'} width="200px" />
                                    </Grid>
                            </TabStripTab>
                        </TabStrip>            
                    </div>
                </div>
                <div className="row">&nbsp;</div>
                <a href="https://www.google.com">
                    <Button style={{ marginLeft:'10px' }} themeColor={'primary'}>
                        Click me!
                    </Button>
                </a>&nbsp;
                <Button style={{ marginLeft: '10px' }} onClick={FireURL} themeColor={'primary'}>
                    FireURL
                </Button>
                {/*<Timeline*/}
                {/*    events={sortedEvents}*/}
                {/*    alterMode={true}*/}
                {/*    collapsibleEvents={true}*/}
                {/*    onActionClick={onActionClick}*/}
                {/*    horizontal={false}*/}
                {/*/>*/}
                <Suspense>
                    <IHValidation mandantid={ihDialogData.mandantid} reqid={ihDialogData.reqid} pid={ihDialogData.pid} fid={ihDialogData.fid}
                        paramid={ihDialogData.paramid} reqdatetime={ihDialogData.reqdatetime} reqprovider={ihDialogData.reqprovider} valtype={ihDialogData.valtype} addedparams={ihDialogData.addedparams}
                        pregnancyweek={ihDialogData.pregnancyweek} mbyn={ihDialogData.mbyn} userid={ihDialogData.userid}
                        showDialog={ihDialogData.showDialog} ResultSaved={ihDialogData.ResultSaved} selprofilecode={""} dayWorkSpace={false}></IHValidation>
                    <InputBoxDialog promptText={inputBoxDialogData.promptText} title={inputBoxDialogData.title} defText={inputBoxDialogData.defText}
                        maxLength={inputBoxDialogData.maxLength} enm={inputBoxDialogData.enm} setText={inputBoxDialogData.setText}
                        showDialog={inputBoxDialogData.showDialog} ></InputBoxDialog>                
                </Suspense>                
            </NavBar>
            
        </>  
    );
}

export default Mahesh;
