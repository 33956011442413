
import axios from 'axios';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridDetailRowProps, GridToolbar } from "@progress/kendo-react-grid";
import { DataResult, SortDescriptor, State, process } from "@progress/kendo-data-query";
import UserSession from '../UserSession';
import { Typography } from "@progress/kendo-react-common";
import { Loader } from '@progress/kendo-react-indicators';
import { CMatInLabData, COneRequest, CUserRightsAppl, matInLabOutput, requestDialogData } from '../../types';
import { mui, useIntersection } from '../../Helper';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import MatDetailComponent from './MatDetailComponent';
import { IntlService } from '@progress/kendo-react-intl';
import RequestDialog from '../RequestEntry/RequestDialog';
import { Button } from '@progress/kendo-react-buttons';
import ProgressBar from '../Misc/ProgressBar';

const initialSort: Array<SortDescriptor> = [
    { field: "sReqID", dir: "desc" },
];

interface MatInLabListProps {
    loadfor: string;
    mandantChanged: number;
}

const MatInLabList = (props: MatInLabListProps) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'matinlab' === ura.sFormName || 'matinlablist' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const [matInLabData, setMatInLabData] = useState<CMatInLabData[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    // for request dialog
    const ref = useRef<HTMLDivElement>(null);
    const inViewport = useIntersection(ref, '0px'); // Trigger as soon as the element becomes visible
    const [requestDialogData, setRequestDialogData] = useState<requestDialogData>({ reqid: '', pid: '', fid: '', bloodProduct: false, showDialog: false, inViewport: inViewport, extcall: false });

    const handleRequestEditClick = (data: CMatInLabData) => {
        console.log("handleRequestEditClick", data);

        if (data === null || data === undefined) return;
        console.log("handleRequestEditClick 2");
        setRequestDialogData({ reqid: data.collMatData[0].reqid, pid: data.collMatData[0].pid, fid: data.collMatData[0].fid, bloodProduct: false, showDialog: true, inViewport: inViewport, extcall: false })
    };

    const callbackRequestDialogClosed = (objRequest: COneRequest | null) => {
        console.log('callbackRequestDialogClosed', objRequest);
        GetmatInLabData()
        setRequestDialogData({ reqid: '', pid: '', fid: '', bloodProduct: false, showDialog: false, inViewport: inViewport, extcall: false })
    }
    // for request dialog

    const updateMasterGrid = (dataItem: matInLabOutput, objMainData: CMatInLabData) => {
        const binddata: CMatInLabData[] = [...matInLabData];
        var tabElement: CMatInLabData;
        for (tabElement of binddata) {
            if (tabElement.sReqID === objMainData.sReqID) {
                const detmatbinddata: matInLabOutput[] = [...tabElement.collMatData];
                var tabdetmatElement: matInLabOutput;
                for (tabdetmatElement of detmatbinddata) {
                    if (tabdetmatElement.matdesc === dataItem.matdesc) {
                        tabdetmatElement.extractiontype = dataItem.extractiontype
                        tabdetmatElement.inlaboryn = dataItem.inlaboryn
                        tabdetmatElement.resultyn = dataItem.resultyn
                        tabdetmatElement.extrdate = dataItem.extrdate
                        tabdetmatElement.extrtime = dataItem.extrtime

                        if (tabdetmatElement.extractiontype === "" &&
                            tabdetmatElement.inlaboryn === "" &&
                            tabdetmatElement.resultyn === "" &&
                            tabdetmatElement.extrdate === null &&
                            tabdetmatElement.extrtime === null) {
                            tabdetmatElement.update = false
                        }
                        else {
                            tabdetmatElement.update = true
                        }

                    }
                }
                break;
            }
        }
        /*console.log("updateMasterGrid", binddata)*/
        //check if to be done..sently not done to avoid grid flickering
        //setMatInLabData(binddata);
    }

    //grouping
    const DetailComponent = (props: GridDetailRowProps) => {
        
        const dataItem = props.dataItem;
        //console.log("detail", dataItem)
        return (
            <MatDetailComponent mandantid={'20'} MatInLabDetailData={dataItem.collMatData} data={dataItem} contentContainerWidth={1200} updateMasterGrid={updateMasterGrid}></MatDetailComponent>
        );
    };

    const [dataState, setDataState] = React.useState<State>({
        skip: 0,
        take: 20,
        sort: [{ field: "sReqID", dir: "asc" }],

    });

    const [dataResult, setDataResult] = React.useState<DataResult>(
        process(matInLabData, dataState)
    );

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        //console.log("dataStateChange")
        setDataResult(process(matInLabData, event.dataState));
        setDataState(event.dataState);
    };

    const expandChange = (event: { value?: boolean; dataItem: any; }) => {
        const isExpanded =
            event.dataItem.expanded === undefined
                ? event.dataItem.aggregates
                : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;
        setDataResult({ ...dataResult, data: [...dataResult.data] });
    };
    //grouping

    async function GetmatInLabData() {
       
        const matInLabData_URL = UserSession.getSystemSettings().API_URL + 'MatInLab/getMatInLabListData';
        const response = await axios.post(matInLabData_URL,
            JSON.stringify({
                "mandantid": UserSession.getLoggedInUser().CurrentMandant, "reqid": '', "matid": '', "bcurrentmandant": false,
                "bexternmandant": false, "ballreq": true, "bextractionN": false, "bextractionY": false, "pid": '', "fid": '', "extrdatefrom": '',
                "extrdateto": '', "borderbyorderprovider": false, "borderbyreqid": true, "borderbypatname": false, "borderbyroomno": false,
                "borderbyextractiondatetime": false, "borderbyreqdatetime": false, "mbyn": UserSession.getLoggedInUser().MBYN,
                "userid": UserSession.getLoggedInUser().UserID, "groupidforcurrentmandant": ''
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        const DATE_FORMAT = "dd.MM.yyyy hh:mm:ss";
        const intl = new IntlService("en");

        var binddata: CMatInLabData[] = [];
        if (response?.data !== null)
            response?.data.forEach((o: CMatInLabData) => {
                console.log("GetmatInLabData", o.sReqDate)
                o.sReqDate = intl.formatDate(
                    o.sReqDate ? o.sReqDate : "20.20.2020",
                    DATE_FORMAT
                );

                binddata.push({ ...o });
            });
        //console.log("GetmatInLabData", binddata)
        setMatInLabData(binddata);
    }

    useEffect(() => {
        //console.log("useEffect-matInLabData")
        setDataResult(process(matInLabData, dataState));
        setDataState(dataState);

    }, [matInLabData])

    // on mandant change
    useEffect(() => {
        console.log("GetmatInLabData", "mandantChanged")
        GetmatInLabData();
    }, [props.mandantChanged]);     

      const saveChanges = () => {
        console.log("saveChanges", matInLabData)
        SaveMatInLabData()
    };

    async function SaveMatInLabData() {

        const SaveMatInLabData_URL_URL = UserSession.getSystemSettings().API_URL + 'MatInLab/savedatafrommatinlablist';
        const response = await axios.post(SaveMatInLabData_URL_URL,
            JSON.stringify({ "mandantid": UserSession.getLoggedInUser().CurrentMandant, "returnlist": matInLabData, "mbyn": UserSession.getLoggedInUser().MBYN, "userid": UserSession.getLoggedInUser().UserID, "groupidforcurrentmandant": '' }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        const DATE_FORMAT = "dd.MM.yyyy hh:mm:ss";
        const intl = new IntlService("en");

        var binddata: CMatInLabData[] = [];
        if (response?.data !== null)
            response?.data.forEach((o: CMatInLabData) => {
                o.sReqDate = intl.parseDate(
                    o.sReqDate ? o.sReqDate : "20.20.2020",
                    DATE_FORMAT
                );

                binddata.push({ ...o });
            });


        setMatInLabData(binddata);
    }

    const cancelChanges = () => {
       
    };

    const CustomCellEdit = (props: GridCellProps) => {
        
        return (
            <td style={{ padding: '1px' }} >
                <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" icon="pencil" style={{ marginRight: "10px" }} title="Edit" themeColor={'primary'}
                    onClick={() => {
                        handleRequestEditClick(props.dataItem);
                    }}
                >
                </Button>
            </td>
        );
    };

    const [msg, setMsg] = useState("");
    const [sort, setSort] = useState(initialSort);
    var patRequestAreaWidth: number = window.innerWidth - 80;
    const location: string = props.loadfor;
    var formHeight: number = window.innerHeight - 120;
    var gridHeight: number = formHeight;
    var contentWidth: number = 0;
    if (location === "modal") {
        gridHeight -= 20; // as dialog
        contentWidth = window.innerWidth - 500;
    }
    else {
        formHeight -= 50;
        gridHeight -= 170; // as dashboard
        contentWidth = window.innerWidth - 120;
    }

    return (
        <>
            <div className="container-fluid" style={{ overflowX: "hidden", height: formHeight, width: '98%', minWidth: contentWidth }}>                
                <div className="row">
                    <div className="col">
                        <Typography.h6 themeColor={'success'} textAlign={'left'} variant='subtitle1' className={msg ? "errmsg" : "offscreen"}>{msg}</Typography.h6>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {isLoading ? <ProgressBar message=""></ProgressBar> : 
                             <Grid
                                style={{
                                    height: gridHeight, width: '100%' }}
                                sortable={true}
                                filterable={true}
                                reorderable={true}
                                dataItemKey={'sKey'}
                                data={process(matInLabData, dataState)}
                                {...dataState}
                                onDataStateChange={dataStateChange}
                                detail={DetailComponent}
                                expandField="expanded"
                                onExpandChange={expandChange}                                
                            ><GridToolbar>
                                    <div style={{ display: 'inline-block' }}>
                                        <Button type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                            saveChanges()
                                        }}>{mui(userRights, "common", "cmdSave", "Save")}</Button>
                                    </div>
                                   
                                </GridToolbar>
                                <GridColumn field="" title="" width="40px" filterable={false} cell={CustomCellEdit} />
                                <GridColumn field="sReqID" width="350px" filter={ "numeric"} filterable={true} title={mui(userRights, "matinlablist", "col1_ReqID", "ReqID")} />
                                <GridColumn
                                    field="sReqDate"
                                    format="{0:dd.MM.yyyy hh:mm:ss}"
                                    width="200px"
                                    filterable={false}
                                    filter={"date"} 
                                />
                                <GridColumn field="sPID" width="300px" filter={"numeric"} filterable={true} title={mui(userRights, "matinlablist", "col2_PID", "PID")} />
                                <GridColumn field="sFID" width="300px" filter={"numeric"} filterable={true} title={mui(userRights, "matinlablist", "col3_FID", "FID")} />
                                <GridColumn field="sRoom" width="300px" filterable={true} title={mui(userRights, "matinlablist", "col4_Room", "Room")} />
                                <GridColumn field="sRequestExtractionYN" width="200px" filterable={true} title={mui(userRights, "matinlablist", "col5_RequestExtractionYN", "RequestExtractionYN")} />

                            </Grid>
                        }
                        <RequestDialog key={'R'} data={requestDialogData} callbackRequestDialogClosed={callbackRequestDialogClosed}></RequestDialog>
                    </div>
                </div>
            </div>


        </>
    );

}
export default MatInLabList;