import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { Suspense, useEffect, useState } from "react";
import { CConfirmationMsg, CRequest, CResultEntry, CResultEntryOutput, CVerifyValidationData, confirmationDialog, emptyRequest, emptyVVD, resultEntryDialog, selCodeDataType } from "../../types";
import UserSession from "../UserSession";
import SerologyResultAndValidation from "./SerologyResultAndValidation";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import CustomResultEntry from "../Misc/CustomResultEntry";
import { mui } from "../../Helper";
import { SelectCode } from "../SelectCode/SelectCode";

interface IHProfileBlockProps {
    obj: CResultEntryOutput;    
    callbackhandleGroupCheckbox(e: CheckboxChangeEvent, sProfileID: string, sResultType: string): any; 
    callbackRefreshData(obj: CResultEntryOutput, action: string):any;
}

const IHProfileBlock = (props: IHProfileBlockProps) => {    
    const dlgEmptyResState: resultEntryDialog = {
        mandantid: "", reqid: "", pid: "", fid: "", paramid: "", reqdatetime: "", reqprovider: "", valtype: "", addedparams: "", pregnancyweek: "", mbyn: "", userid: "", showDialog: false, ResultSaved: () => { }, selprofilecode:"", dayWorkSpace: false
    };
    const [seroresultEntryDialogData, setSeroResultEntryDialogData] = useState<resultEntryDialog>(dlgEmptyResState);
    const [reqData, setReqData] = useState<CRequest>(emptyRequest);

    const dlgEmptyConfirmationDialogState: confirmationDialog = {
        obj: emptyVVD, showDialog: false, SelectedOptions: () => { }
    };
    const [confirmationDialogData, setConfirmationDialogData] = useState<confirmationDialog>(dlgEmptyConfirmationDialogState);

    let paramLen: number;
    let paramwithcomm: number;
    let adminLen: number;
    let admincommLen: number;
    let maxCols: number;
    let cellWidth: number = 100;
    if (props.obj.sProfileResultType.toUpperCase() === 'BC') {        
        paramLen = 3;
        paramwithcomm = props.obj.listResEntry.filter(objRE => objRE.sResultComm !== "").length;
        adminLen = 0;
        admincommLen = 0;        
        if (paramwithcomm > 0) paramLen += 1;
        cellWidth = 150;
    } else {
        paramLen = props.obj.listResEntry.length;
        paramwithcomm =  props.obj.listResEntry.filter(objRE => objRE.sResultComm !== "").length;
        adminLen = 0
        admincommLen =  props.obj.listResEntry.filter(objRE => objRE.sResultComm !== "").length;        
    };
    
    maxCols = paramLen > adminLen ? paramLen : adminLen;      
    if (maxCols === 1) {
        maxCols += 1.75
    }
    
    const EnableResultEdit = () => {
        props.obj.inEdit = true
        props.obj.disableEdit = false
        props.callbackRefreshData(props.obj, '2'); 
    };

    const DisableResultEdit = () => {
        props.obj.inEdit = false
        props.obj.disableEdit = false
        props.callbackRefreshData(props.obj, '3');
    };

    const SaveResult = () => {
        props.obj.inEdit = false
        props.obj.disableEdit = false
        VerifyData("S");
 
        props.callbackRefreshData(props.obj, '1');
    };

    const VerifyData = async (sMode: string) => {
        var bok: boolean = false;
        var objVVD: CVerifyValidationData = emptyVVD;
        objVVD.sMandantID = UserSession.getLoggedInUser().CurrentMandant;
        objVVD.sMode = sMode;
        //if (props.obj !== undefined) props.obj.listResEntry = resultData;
        objVVD.objREO = props.obj;

        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'serology/verifydata',
            JSON.stringify({
                sMandantID: objVVD.sMandantID, objREO: objVVD.objREO, sMode: objVVD.sMode,
                bValidateIncompatibleComp: objVVD.bValidateIncompatibleComp, bAddBldGrpRhesusToPatient: objVVD.bAddBldGrpRhesusToPatient,
                bAddPhenoTypeToPatient: objVVD.bAddPhenoTypeToPatient, bSetCompFreeAndProceedWithValidation: objVVD.bSetCompFreeAndProceedWithValidation,
                bResultEnteredRDParam: objVVD.bResultEnteredRDParam, bResultEnteredBCParam: objVVD.bResultEnteredBCParam,
                bBloodComponentExists: objVVD.bBloodComponentExists, bDataChanged: objVVD.bDataChanged, nMsgAntigenDoesNotMatch: objVVD.nMsgAntigenDoesNotMatch,
                nMsgBldGrpRhsOfPatAndCompDoesNotMatch: objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch, nMsgBldGrpRhsOfPatAndCompDoesNotMatch1: objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch1,
                nMsgBldGrpOfResultAndComponentDoesNotMatch: objVVD.nMsgBldGrpOfResultAndComponentDoesNotMatch, nMsgRhesusOfResultAndPatientDoesNotMatch: objVVD.nMsgRhesusOfResultAndPatientDoesNotMatch,
                nMsgRhesusOfResultAndComponentDoesNotMatch: objVVD.nMsgRhesusOfResultAndComponentDoesNotMatch, nMsgAcceptBldGrpAndRhesus: objVVD.nMsgAcceptBldGrpAndRhesus,
                nMsgAcceptPhenoTypeYN: objVVD.nMsgAcceptPhenoTypeYN, nGetReactionReason: objVVD.nGetReactionReason,
                sReactionReason: objVVD.sReactionReason, sConfirmationMsg: objVVD.sConfirmationMsg
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== null) {
            bok = true;
            objVVD = response?.data;

            if (objVVD.ConfirmationMsg.length > 0 || objVVD.nGetReactionReason === 1) {
                const SelectedOpts = (obj: CVerifyValidationData) => {
                    if (obj !== undefined) {
                        console.log(obj);
                        var objCM: CConfirmationMsg;
                        for (objCM of obj.ConfirmationMsg) {
                            if (objCM.msgid === "MsgAntigenDoesNotMatch") {
                                obj.nMsgAntigenDoesNotMatch = Number(objCM.action);
                            } else if (objCM.msgid === "MsgBldGrpRhsOfPatAndCompDoesNotMatch") {
                                objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch = Number(objCM.action);
                            } else if (objCM.msgid === "MsgBldGrpRhsOfPatAndCompDoesNotMatch1") {
                                objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch1 = Number(objCM.action);
                            } else if (objCM.msgid === "MsgBldGrpOfResultAndComponentDoesNotMatch") {
                                objVVD.nMsgBldGrpOfResultAndComponentDoesNotMatch = Number(objCM.action);
                            } else if (objCM.msgid === "MsgRhesusOfResultAndPatientDoesNotMatch") {
                                objVVD.nMsgRhesusOfResultAndPatientDoesNotMatch = Number(objCM.action);
                            } else if (objCM.msgid === "MsgRhesusOfResultAndComponentDoesNotMatch") {
                                objVVD.nMsgRhesusOfResultAndComponentDoesNotMatch = Number(objCM.action);
                            } else if (objCM.msgid === "MsgAcceptBldGrpAndRhesus") {
                                objVVD.nMsgAcceptBldGrpAndRhesus = Number(objCM.action);
                            } else if (objCM.msgid === "MsgAcceptPhenoTypeYN") {
                                objVVD.nMsgAcceptPhenoTypeYN = Number(objCM.action);
                            };
                            if (objCM.action === "7") {
                                bok = false;
                            };
                        };
                    };
                    if (bok === true) {
                  
                        if (sMode === "V") {
                            //ValidateClick(obj);
                        } else {
                            SaveClick(sMode, obj);
                        };
                    };
                    setConfirmationDialogData(dlgEmptyConfirmationDialogState);
                };
                setConfirmationDialogData({
                    obj: objVVD, showDialog: true, SelectedOptions: SelectedOpts
                });
            } else {
                if (bok === true) {
    
                    if (sMode === "V") {
                        //ValidateClick(objVVD);
                    } else {
                        SaveClick(sMode, objVVD);
                    };
                };
            };
        };
   
    };

    const SaveClick = async (sMode: string, objVVD: CVerifyValidationData) => {
  
        const binddata: CResultEntry[] = objVVD.objREO.listResEntry //[...resultData];
        var obj: CResultEntry;
        
        for (obj of binddata) {
            if (obj.sRawResult === "" && obj.bDisableResultEntry === false) {
                alert(mui(userRights, "common", "msgNoBlankResult", "Please enter all results!"));
                return;
            };
        };
        var sAddnURL: string;
        if (sMode === 'S') {
            sAddnURL = 'serology/saverequestresultdata';
        } else {
            sAddnURL = 'serology/rerunparam';
        };
        //setIsLoading(true);
        if (objVVD.objREO !== undefined) {
            //resultMainData.listResEntry = resultData;
            objVVD.objREO.sUserID = UserSession.getLoggedInUser().UserID;
        };
        const response = await axios.post(UserSession.getSystemSettings().API_URL + sAddnURL,
            JSON.stringify({
                objREO: objVVD.objREO, objVVD: objVVD
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        var maindata: CResultEntryOutput | undefined;
        if (response?.data !== null) {
            maindata = response?.data.objREO;
            //setVVD(response?.data.objVVD);
        }
        if (maindata !== undefined) {
            if (maindata.sErrorMsg !== "") {
                alert(maindata.sErrorMsg);
            } else {
                //setDisableSaveButton(true);
                //setDisableValidationButton(true);
                binddata.length = 0;
                maindata.inEdit = false
                maindata.disableEdit = false
                maindata.listResEntry.forEach((o: CResultEntry) => {
                    //if (o.sParamStatus === "1" || o.sParamStatus === "2") setDisableSaveButton(false);
                    //if (o.sParamStatus === "3") setDisableValidationButton(false);
                    binddata.push({ ...o });
                });
                //setResultData(binddata);
                //setResultSaved(true);
                props.obj.inEdit = false
                props.obj.disableEdit = false
                props.callbackRefreshData(props.obj, '1');
            };
        };

        //setIsLoading(false);
    };
        
    const callbackResultSel = async (tableid: string, controlID: string, obj: selCodeDataType, supplParam1: string, supplParam2: string) => {
        console.log("callbackResultSel", obj)

        //let newData = props.obj.listResEntry.map((item: CResultEntry, index) => {
        //    if (item.sParamID === supplParam1) {
        //        item.sResultComm = obj.code
        //    }
        //    return item;
        //});
        //props.obj.listResEntry= newData
    };


    const ShowSeroVal = (profileid: string, action: string) => {        
        const ResultSaved = async (save: boolean) => {            
            setSeroResultEntryDialogData(dlgEmptyResState);            
            if (save === true) {
                props.callbackRefreshData(props.obj, '1');            
            };
        }
        setSeroResultEntryDialogData({
            mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: reqData.sReqID, pid: reqData.sPID, fid: reqData.sFID,
            paramid: "", reqdatetime: reqData.sReqDate + ' ' + reqData.sReqTime, reqprovider: reqData.sReqProvider,
            valtype: props.obj.sValType, addedparams: "", pregnancyweek: reqData.sPregnancyWeek, mbyn: "0",
            userid: UserSession.getLoggedInUser().UserID, showDialog: action === 'open' ? true : false, ResultSaved: ResultSaved,
            selprofilecode: profileid, dayWorkSpace: false
        });
    };

    const GetReqData = async () => {
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'general/getResulyEntryDialogData',
            { mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: props.obj.listResEntry[0].sReqID },
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );

        if (response?.data !== null) setReqData(response?.data);           
    };

    useEffect(() => {
        GetReqData();
        //console.log("IHProfileBlock" , props.obj)
    }, []);

    return (
        <>
            <table className="table-sm table-bordered" style={{ width: cellWidth * maxCols }}>
            <tbody>
                    <tr key={uuidv4()}>
                        <td colSpan={maxCols} title={props.obj.sProfileToolTip} style={{ fontWeight: 'bold', backgroundColor: props.obj.sProfileBackColor }}>
                            <div className="vertical-wrapper">
                                <div className="row">
                                    <div className="col-6">
                                        <Checkbox style={{ backgroundColor: props.obj.bProfileValidated === true ? 'green' : ''}} disabled={props.obj.bDisableProfileSel} onChange={(event: CheckboxChangeEvent) => props.callbackhandleGroupCheckbox(event, props.obj.sProfileID, props.obj.sProfileResultType)}
                                            value={props.obj.bProfileSel}
                                            >
                                            <label                                                                                               
                                                style={{ display: "inline-block", textWrap:'nowrap' }}
                                            >
                                                {props.obj.sProfileDesc}
                                            </label>
                                        </Checkbox>
                                    </div>
                                    <div className="col-6">
                                        {props.obj.bProfileValidated === false ?
                                            (
                                            props.obj.inEdit === true ?
                                                <>
                                                    <Button type={"button"} style={{ height: '20px', width: '20px', float: 'right', marginRight: "16px" }} themeColor={'primary'}
                                                        onClick={() => { DisableResultEdit(); }} icon="reset"></Button>
                                            <Button type={"button"} style={{ height: '20px', width: '20px', float: 'right', marginRight: "16px" }} themeColor={'primary'}
                                                        onClick={() => { SaveResult(); }} icon="floppy"></Button>
                                                    
                                            </>
                                                :
                                                <Button type={"button"} disabled={props.obj.disableEdit } style={{ height: '20px', width: '20px', float: 'right', marginRight: "16px" }} themeColor={'primary'}
                                                onClick={() => { EnableResultEdit(); }} icon="pencil"></Button>
                                           
                                        ):""
                                        }
                                    </div>
                                </div>
                                
                        </div>
                    </td>                             
                </tr>
                    {
                        props.obj.listResEntry !== null && props.obj.sProfileResultType.toUpperCase() === 'BC' && props.obj.listResEntry.map((objRE: CResultEntry, index) => (
                            <tr key={uuidv4()}>
                                <td key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>{objRE.sParamDesc}</td>
                                {/*<td key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}><AutoComplete clearButton={false} style={{ width: cellWidth}} defaultValue={objRE.sInterpretationDesc}></AutoComplete></td>*/}
                                <td title={objRE.sInterpretationDesc !== "" ? objRE.sInterpretationDesc : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>{objRE.sInterpretationDesc}</td>
                                <td title={objRE.sCompType !== "" ? objRE.sCompType : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>{objRE.sCompType}</td>
                                {paramwithcomm > 0 && <td title={objRE.sResultCommDesc !== "" ? objRE.sResultCommDesc : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>{objRE.sResultComm}</td>}
                            </tr>   
                        ))
                    }
                    <tr key={uuidv4()}>
                    {
                            props.obj.listResEntry !== null && props.obj.sProfileResultType.toUpperCase() !== 'BC' && props.obj.listResEntry.map((objRE: CResultEntry, index) => (
                                <td key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>{objRE.sParamDesc}</td>
                        ))
                    }
                </tr>
                    <tr key={uuidv4()}>
                    {
                            props.obj.listResEntry !== null && props.obj.sProfileResultType.toUpperCase() !== 'BC' && props.obj.listResEntry.map((objRE: CResultEntry, index) => (
                                props.obj.inEdit === true ? 
                                <td key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>
                                    <CustomResultEntry selType="RESULT" objRE={objRE} ></CustomResultEntry>
                                    </td>
                                    :
                                    <td title={objRE.sInterpretationDesc !== "" ? objRE.sInterpretationDesc : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>
                                        {objRE.sInterpretationDesc === "" ? <div>&nbsp;</div> : objRE.sInterpretationDesc}
                                    </td>
                                
                        ))
                    }
                </tr>
                    <tr key={uuidv4()}>
                    {
                            props.obj.listResEntry !== null && props.obj.sProfileResultType.toUpperCase() !== 'BC' && paramwithcomm > 0 && props.obj.listResEntry.map((objRE: CResultEntry, index) => (
                                props.obj.inEdit === true ? 
                                    
                                     <td title={objRE.sResultCommDesc !== "" ? objRE.sResultCommDesc : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>
                                        <CustomResultEntry selType="RESULTCOMM" objRE={objRE} ></CustomResultEntry>
                            </td>
                                :
                                <td title={objRE.sResultCommDesc !== "" ? objRE.sResultCommDesc : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>
                                {objRE.sResultComm === "" ? <div>&nbsp;</div> : objRE.sResultComm}
                            </td>
                        ))
                    }
                </tr>
                {adminLen > 0 &&
                        <>
                        <tr style={{ lineHeight: '1px' }} key={uuidv4()}>
                        <td colSpan={maxCols}>
                            &nbsp;
                        </td>
                    </tr>
                        <tr key={uuidv4()}>
                        {
                                props.obj.listResEntry !== null && props.obj.sProfileResultType.toUpperCase() !== 'BC' && props.obj.listResEntry.map((objRE: CResultEntry, index) => (
                                    <td key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>{objRE.sParamDesc}</td>
                            ))
                        }
                    </tr>
                    <tr key={uuidv4()}>
                            {
                                props.obj.listResEntry !== null && props.obj.sProfileResultType.toUpperCase() !== 'BC' && props.obj.listResEntry.map((objRE: CResultEntry, index) => (
                                    <td title={objRE.sInterpretationDesc !== "" ? objRE.sInterpretationDesc : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>
                                        {objRE.sInterpretationDesc === "" ? <div>&nbsp;</div> : objRE.sInterpretationDesc}
                                        </td>
                                ))
                            }
                        </tr>
                        <tr>
                            {
                                props.obj.listResEntry !== null && props.obj.sProfileResultType.toUpperCase() !== 'BC' && admincommLen > 0 && props.obj.listResEntry.map((objRE: CResultEntry, index) => (
                                    <td title={objRE.sResultCommDesc !== "" ? objRE.sResultCommDesc : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>
                                        {objRE.sResultComm === "" ? <div>&nbsp;</div> : objRE.sResultComm}
                                    </td>
                                ))
                            }
                        </tr>
                </>
                }
                </tbody>            
            </table>
            <Suspense fallback={<Loader />}>
                <SerologyResultAndValidation mandantid={seroresultEntryDialogData.mandantid} reqid={seroresultEntryDialogData.reqid} pid={seroresultEntryDialogData.pid} fid={seroresultEntryDialogData.fid}
                    paramid={seroresultEntryDialogData.paramid} reqdatetime={seroresultEntryDialogData.reqdatetime} reqprovider={seroresultEntryDialogData.reqprovider} valtype={seroresultEntryDialogData.valtype} addedparams={seroresultEntryDialogData.addedparams}
                    pregnancyweek={seroresultEntryDialogData.pregnancyweek} mbyn={seroresultEntryDialogData.mbyn} userid={seroresultEntryDialogData.userid}
                    showDialog={seroresultEntryDialogData.showDialog} ResultSaved={seroresultEntryDialogData.ResultSaved} selprofilecode={seroresultEntryDialogData.selprofilecode} dayWorkSpace={seroresultEntryDialogData.dayWorkSpace}></SerologyResultAndValidation>
            </Suspense>
        </>
    );
}

export default IHProfileBlock;