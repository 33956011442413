/* eslint-disable react/jsx-no-undef */
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { TextArea } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import {  useEffect, useState } from "react";
import { DialogTitleBar, formatDate, formatTime, mui } from "../../Helper";
import { CResultEntry, ctDataType, CUserRightsAppl, resultSelDialog, selCodeDataType, emptyREData } from "../../types";

import { SelectCode } from "../SelectCode/SelectCode";
import { Upload } from "@progress/kendo-react-upload";

import CustomDate from "../Misc/CustomDate";
import CustomTime from "../Misc/CustomTime";
import { SelectList } from "../SelectCode/SelectList";
import { CodeTableSelList } from "../CodeTableSel/CodeTableSelList";

const ResultSelDialog = (props: resultSelDialog) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'resseldialog' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const [resultData, setResultData] = useState<CResultEntry>(emptyREData);
    const [visible, setVisible] = useState<boolean>(props.showDialog);
    const [resDate, setResDate] = useState(formatDate(new Date().toDateString()));
    const [resTime, setResTime] = useState(formatTime(new Date().toLocaleString(), true));
    const [selCode, setSelCode] = useState<string | "">("");
    const [selDesc, setSelDesc] = useState<string | "">("");
    const [disableResSelButton, setDisableResSelButton] = useState<boolean | true>(true);
    const [disableFreeText, setDisableFreeText] = useState<boolean | true>(true);
    const [formHeight, setFormHeight] = useState<number | 540>(540);
    const [selDefCode, setSelDefCode] = useState<string | "">("");

    const OkClick = async () => {
        console.log('selCode', selCode);
        if (selCode === "") {
            alert('Please select result code!');
        } else {
            if (selCode.toLocaleUpperCase() === "FT") {
                if (props.selType === "Result") {
                    props.CodeSelected(selCode, resultData.sResultText);
                } else {
                    props.CodeSelected(selCode, resultData.sCommText);
                };
            } else {
                props.CodeSelected(selCode, selDesc);
            };            
            setVisible(false);
        }
       
    };
    const CancelClick = async () => {
        props.CodeSelected("", "");
        setVisible(false);
    };

    const handleResChangeDate = (value: string, source: string, supplParam1?: string, supplParam2?: string) => {
        console.log('event.value', value);
        setResDate(value)
        setSelCode(value);
        setSelDesc("");
        //if (value !== undefined && value !== null) {
        //    setSelCode(formatDate(value.toString()));
        //    setSelDesc("");
        //} else {
        //    setSelCode("");
        //    setSelDesc("");
        //}
    };

    const handleResChangeTime = (value: string, source: string, supplParam1?: string, supplParam2?: string) => {
        console.log('event.value', value);
        setResTime(value)
        setSelCode(value);
        setSelDesc("");
        //if (event.value !== undefined && event.value !== null) {
        //    setSelCode(event.value?.getHours() + ':' + event.value?.getMinutes() + ':' + event.value?.getSeconds());
        //    setSelDesc("");
        //} else {
        //    setSelCode("");
        //    setSelDesc("");
        //}
    };

    const callbackCTSelected = async (ctid: string, controlID: string, objCT: ctDataType, supplParam1: string, supplParam2: string) => {
        if (objCT.sCode !== undefined && objCT.sCode !== null) {
            setSelCode(objCT.sCode);
            setSelDesc(objCT.sDescDevelopment);
        } else {
            setSelCode("");
            setSelDesc("");
        }
    };

    const callbackResultSel = async (tableid: string, controlID: string, obj: selCodeDataType, supplParam1: string, supplParam2: string) => {
        console.log("callbackResultSel", obj)
        if (obj.code !== undefined && obj.code !== null) {
            setSelCode(obj.code);
            setSelDesc(obj.desc);            
            if (obj.code.toUpperCase() === 'FILE') {
                setDisableResSelButton(false);
            } else {
                setDisableResSelButton(true);
            };
            if (obj.code.toUpperCase() === 'FT') {                
                setDisableFreeText(false);
            } else {                
                setDisableFreeText(true);
                var objRE: CResultEntry = resultData;
                if (props.selType === "Result") {
                    objRE.sResultText = "";
                } else {
                    objRE.sCommText = "";
                };
                setResultData(objRE);
            };
        } else {
            setSelCode("");
            setSelDesc("");
        }
    };

    const freeTextChange = (e: any) => {
        const binddata: CResultEntry = resultData;
        const value = e.target.value;
        const name = e.target.name;        
        setResultData(prevData => ({
            ...prevData,
            [name]: value            
        }));        
    };

    useEffect(() => {
        setResultData(props.objRE);
        setSelCode("");
        setSelDesc("");
        if (props.col === 2) {
            if (props.selType === "Result" && props.objRE.sRawResult1.toUpperCase() === 'FILE') {
                setDisableResSelButton(false);
            } else {
                setDisableResSelButton(true);
            };
        } else if (props.col === 3) {
            if (props.selType === "Result" && props.objRE.sRawResult2.toUpperCase() === 'FILE') {
                setDisableResSelButton(false);
            } else {
                setDisableResSelButton(true);
            };
        } else {
            if (props.selType === "Result" && props.objRE.sRawResult.toUpperCase() === 'FILE') {
                setDisableResSelButton(false);
            } else {
                setDisableResSelButton(true);
            };
        };
       
        if (props.selType === "Result") {
            if (props.col === 2) {
                if (props.objRE.sRawResult1.toUpperCase() === 'FT') {
                    setDisableFreeText(false);
                } else {
                    setDisableFreeText(true);
                };
                setSelDefCode(props.objRE.sRawResult1);

            } else if (props.col === 3) {
                if (props.objRE.sRawResult2.toUpperCase() === 'FT') {
                    setDisableFreeText(false);
                } else {
                    setDisableFreeText(true);
                };
                setSelDefCode(props.objRE.sRawResult2);

            } else {
                if (props.objRE.sRawResult.toUpperCase() === 'FT') {
                    setDisableFreeText(false);
                } else {
                    setDisableFreeText(true);
                };
                setSelDefCode(props.objRE.sRawResult);
            };
           
        } else if (props.selType === "ResultComm") {
            if (props.col === 2) {
                if (props.objRE.sResultComm1.toUpperCase() === 'FT') {
                    setDisableFreeText(false);
                } else {
                    setDisableFreeText(true);
                };
                setSelDefCode(props.objRE.sResultComm1);

            } else if (props.col === 3) {
                if (props.objRE.sResultComm2.toUpperCase() === 'FT') {
                    setDisableFreeText(false);
                } else {
                    setDisableFreeText(true);
                };
                setSelDefCode(props.objRE.sResultComm2);

            } else {
                if (props.objRE.sResultComm.toUpperCase() === 'FT') {
                    setDisableFreeText(false);
                } else {
                    setDisableFreeText(true);
                };
                setSelDefCode(props.objRE.sResultComm);
            };           
        };   
        
        if (props.selType === "ResultComm") {
            setFormHeight(650);
        } else if (props.selType === "Result" && props.objRE.sResSelTableName === "ResultCode") {
            setFormHeight(700);
        } else if (props.selType === "Result" && props.objRE.sCodeTableID !== "") {
            setFormHeight(500);
        } else {
            setFormHeight(200);
        };

        setVisible(props.showDialog);
    }, [props.showDialog]) 
        
    const getFormTitle = () => {
        var str: string ="";
        if (props.selType === "Result") {
            str = mui(userRights, "resseldialog", "textTitleResSel", "Select Result");            
        } else if (props.selType === "ResultComm") {
            str= mui(userRights, "resseldialog", "textTitleResCommSel", "Select Result Comment");            
        };
        return str;
    };

    const getResLabelTitle = () => {
        var str: string = "";
        if (props.selType === "Result") {
            str= mui(userRights, "resseldialog", "lblResult", "Result");
        } else if (props.selType === "ResultComm") {
            str= mui(userRights, "resseldialog", "lblResultComm", "Result Comment");
        };
        return str;
    };

    const selectFileClick = (event:any) => {
        console.log(event.target.files[0]);
    };
    const selectFile = () => {
        
    };
    return (
        <>
            <div>
                {visible && (
                    <Dialog  title={<DialogTitleBar title={getFormTitle()} />} onClose={CancelClick} height={formHeight} width={900} >
                        <div className="container-fluid" style={{ overflowX: 'hidden', width: '94%' }}>
                            <div className="row">

                                        <div className="col-2">
                                        <div><Label editorId={'lblResult'}>{getResLabelTitle()}</Label></div>
                                        </div>
                                        <div className="col-10">
                                            {props.selType === "Result" && props.objRE.sResSelTableName === "Date" && (<CustomDate dateChanged={handleResChangeDate} width='125px' defaulValue="" id={'txtReqDate'} source={'selDefCode'} label="" supplParam1={props.objRE.sParamID} supplParam2=""
                                                required={true} disabled={false}></CustomDate>)}
                                            {props.selType === "Result" && props.objRE.sResSelTableName === "Time" && (<CustomTime allowSecond={true} supplParam1={props.objRE.sParamID} supplParam2="" timeChanged={handleResChangeTime} width='100px' defaulValue="" id={'ResTime'} source={'selDefCode'} label="" required={true} disabled={false}></CustomTime>)}
                                            {props.selType === "Result" && props.objRE.sCodeTableID !== "" && (<CodeTableSelList setSelectedCT={callbackCTSelected} mandantChanged={parseInt(props.objRE.sMandantID)} id={props.objRE.sCodeTableID}
                                        codeTableID={props.objRE.sCodeTableID} selCode={selDefCode} width="100%" supplParam1={props.objRE.sParamID} supplParam2=""></CodeTableSelList>)}
                                            {props.selType === "Result" && (props.objRE.sResSelTableName === "ResultCode" || props.objRE.sResSelTableName === "PhenoType" || props.objRE.sResSelTableName === "Antibodies") && (<SelectList disabled={false} setSelectedTable={callbackResultSel} id="ResultCode"
                                                tablename={props.objRE.sResSelTableName} code={props.objRE.sResSelCodeFieldName} desc={props.objRE.sResSelDescrFieldName} selCode={props.selqualresult === true ? props.objRE.sQualitativeResult : selDefCode}
                                                mandantChanged={0} width="100%" supplParam1={props.objRE.sParamID} supplParam2=""></SelectList>)}
                                            {props.selType === "ResultComm" && (<SelectList disabled={false} setSelectedTable={callbackResultSel} id="ResultComm"
                                                tablename={"ResultComm"} code={"Code"} desc={"Conclusion"} selCode={selDefCode}
                                                mandantChanged={0} width="100%" supplParam1={props.objRE.sParamID} supplParam2=""></SelectList>)}
                                        </div>  

                            </div>
                            <div className="row"><div className="col">&nbsp;</div></div>
                            <div className="row">
                                <div className="col">
                                    {props.selType === "Result" && props.objRE.sResSelTableName === "ResultCode" && (<Upload
                                        disabled={disableResSelButton}
                                        autoUpload={false}
                                        batch={false}
                                        multiple={true}
                                        defaultFiles={[]}
                                        withCredentials={false}
                                        saveUrl={""}
                                        removeUrl={""}
                                    />)}
                                </div>
                            </div>
                            <div className="row"><div className="col">&nbsp;</div></div>
                            <div className="row">

                            {((props.selType === "Result" && props.objRE.sResSelTableName === "ResultCode") || (props.selType === "ResultComm")) && (<div className="row">
                                <div className="col-2">
                                    <div><Label editorId={'lblFreeText'}>{mui(userRights, "resseldialog", "lblFreeText", "Free Text")}</Label></div>
                                </div>
                                <div className="col-10">
                                        <TextArea disabled={disableFreeText} autoComplete="off" id="ft" name={props.selType === "Result" ? "sResultText" : "sCommText"} style={{ height: '150px', width: '100%' }}
                                        onChange={freeTextChange} value={props.selType === "Result" ? resultData.sResultText : resultData.sCommText} />
                                </div>
                                </div>)}
                            </div>
                            <div className="row"><div className="col">&nbsp;</div></div>
                         
                            <div className="row">
                                <div className="col">
                                    <div className="button-right">                                                                                
                                        <Button type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                            OkClick()
                                        }}>{mui(userRights, "common", "cmdOk", "Ok")}</Button>&nbsp;
                                        <Button type={"button"} style={{ width: '150px' }} themeColor={'secondary'} onClick={() => {
                                            CancelClick()
                                        }}>{mui(userRights, "common", "cmdCancel", "Cancel")}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                )}
            </div>
        </>
    );
}

export default ResultSelDialog;