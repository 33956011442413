import { Grid, GridCellProps, GridColumn, GridRowProps, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { Checkbox, CheckboxChangeEvent, Input } from "@progress/kendo-react-inputs";
import { Suspense, useCallback, useEffect, useState } from "react";
import { ctDataType, CUserRightsAppl, resultEntryDialog, valcriteriaListEntry, viewResultDialog, viewResultDialogCriteria } from "../../types";
import { CodeTableSel } from "../CodeTableSel/CodeTableSel";
import UserSession from "../UserSession";
import axios from 'axios';
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { Label } from "@progress/kendo-react-labels";
import './ValCriteria.css'
import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import { Loader } from '@progress/kendo-react-indicators';
import ViewResultDialog from '../ViewResult/ViewResultDialog';
import { mui } from '../../Helper';
import ChemieResultEntryDialog from '../ResultAndValidation/ChemieResultEntryDialog';
import UrineSticksResultAndValidation from '../ResultAndValidation/UrineSticksResultAndValidation';
import HamatResultAndValidation from '../ResultAndValidation/HamatResultAndValidation';
import IHValidation from '../ResultAndValidation/IHValidation';
import SerologyResultAndValidation from '../ResultAndValidation/SerologyResultAndValidation';
import ProgressBar from "../Misc/ProgressBar";

const initialSort: Array<SortDescriptor> = [
   /* { field: "reqid", dir: "asc" },*/
];

interface ValCriteriaProps {
    loadfor: string;
    mandantChanged: number;
}

const ValCriteria = (props: ValCriteriaProps) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'ctm' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;
    const dlgEmptyResState: resultEntryDialog = {
        mandantid: "", reqid: "", pid: "", fid: "", paramid: "", reqdatetime: "", reqprovider: "", valtype: "", addedparams: "", pregnancyweek: "", mbyn: "", userid: "", showDialog: false, ResultSaved: () => { }, selprofilecode: "", dayWorkSpace: false

    };
    const [resultEntryDialogData, setResultEntryDialogData] = useState<resultEntryDialog>(dlgEmptyResState);
    const [urinsticksDialogData, setUrinSticksDialogData] = useState<resultEntryDialog>(dlgEmptyResState);
    const [hamatDialogData, setHamatDialogData] = useState<resultEntryDialog>(dlgEmptyResState);
    const [seroresultEntryDialogData, setSeroResultEntryDialogData] = useState<resultEntryDialog>(dlgEmptyResState);
    const [ihDialogData, setIHDialogData] = useState<resultEntryDialog>(dlgEmptyResState);
    const [sort, setSort] = useState(initialSort);
    const [CodeTableData, setCodeTableData] = useState<ctDataType | null>(null);
    const [selectedCode, setSelectedCode] = useState("");
    const [checkedExtMandant, setCheckedExtMandant] = useState(false);       
    const [valData, setvalData] = useState<valcriteriaListEntry[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const emptyVRC: viewResultDialogCriteria = {
        mandantid: "",
        mbyn: false,
        pid: "",
        fid: "",
        gendercode: "",
        birthdate: "",
        pregnancyweeks: "",
        toage: "",
        reqid: "",
        reqdate: "",
        reqtime: "",
        medvalidation: "",
        valtype: ""	
    };
    const dlgEmptyState: viewResultDialog = {
        objVRC: emptyVRC, showDialog: false, cancleClicked: () => { }
    };
    const [viewResultDialog, setViewResultDialog] = useState < viewResultDialog>(dlgEmptyState);

    const callbackCTSelected = async (ctid: string, controlID: string, objCT: ctDataType) => {
        setSelectedCode(objCT.sCode);
        setCodeTableData(objCT);
    };

    const fillValData = async () => {
        var incompatibleCompValidation = '0';
        var medicalvalidation = '0';     
        if (CodeTableData === null) return;
        if (CodeTableData.sCode !== '') {
            // when u need to do these....introduce another param
            if (props.loadfor === "MV") {
                medicalvalidation = "1";
            } else if (props.loadfor === "ICV") {
                incompatibleCompValidation = '1';
            };
            // console.log('fillValData ', JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, "valtype": CodeTableData.sCode }));  
            setIsLoading(true);
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'validationcriteria/getvalcriteriadata',
                JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, "valtype": CodeTableData.sCode, "showdataforexternmandant": checkedExtMandant === true ? '1' : '0', "incompatibleCompValidation": incompatibleCompValidation, "medicalvalidation": medicalvalidation }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
            // console.log(response?.data);
            var binddata: valcriteriaListEntry[] = [];
            if (response?.data !== null)
                response?.data.forEach((o: valcriteriaListEntry) => {
                    binddata.push({ ...o });
                });
            setvalData(binddata);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        
    }, [valData])


    useEffect(() => {
        fillValData();
    }, [CodeTableData]);

    useEffect(() => {
        fillValData();
    }, [checkedExtMandant]);

    useEffect(() => {
        setCodeTableData(null);
        setvalData([]);
    }, [props.mandantChanged]);     

    const showViewResultForm = (ReqID: string, action: string) => {
        let validationdata = valData.find((i: any) => i.reqid === ReqID);
        //console.log(validationdata);
        if (validationdata !== undefined) {
            var objVRC: viewResultDialogCriteria=emptyVRC;
            objVRC.mandantid = UserSession.getLoggedInUser().CurrentMandant
            objVRC.reqid = validationdata.reqid
            objVRC.pid = validationdata.pid
            objVRC.fid = validationdata.fid
            objVRC.reqdate = validationdata.reqdate
            objVRC.reqtime = validationdata.reqtime
            objVRC.birthdate = validationdata.birthdate
            objVRC.valtype = validationdata.valtype
            objVRC.gendercode = validationdata.gender
            objVRC.pregnancyweeks = validationdata.pregnancyweek
            setViewResultDialog({ objVRC: objVRC, showDialog: action === 'open' ? true : false, cancleClicked: cancleClicked })
            // console.log('kela set - ' + action);
        }
    };
    const handleEditClick = useCallback(async (VCLE: valcriteriaListEntry, action: string) => {
        const ResultSaved = async (validated: boolean) => {
            setResultEntryDialogData(dlgEmptyResState);
            setUrinSticksDialogData(dlgEmptyResState);
            setHamatDialogData(dlgEmptyResState);
            setSeroResultEntryDialogData(dlgEmptyResState);
            setIHDialogData(dlgEmptyResState);            
            if (validated === true) {
                if (await ResultsCanBeValidated(VCLE) === false) {
                    var binddata: valcriteriaListEntry[] = [];
                    valData.forEach((o: valcriteriaListEntry) => {
                        if (o.reqid !== VCLE.reqid) {
                            binddata.push({ ...o });
                        };
                    });
                    setvalData(binddata);
                };
            };
        }
        if (VCLE.valtypeSuppl1.trim() === 'C') {
            setResultEntryDialogData({
                mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: VCLE.reqid.toString(), pid: VCLE.pid.toString(), fid: VCLE.fid.toString(),
                paramid: "", reqdatetime: VCLE.reqdatetime, reqprovider: VCLE.reqprovider, valtype: VCLE.valtype, addedparams: "", pregnancyweek: VCLE.pregnancyweek, mbyn: "0",
                userid: UserSession.getLoggedInUser().UserID, showDialog: action === 'open' ? true : false, ResultSaved: ResultSaved, selprofilecode: "", dayWorkSpace: false
            });
        } else if (VCLE.valtypeSuppl1.trim() === 'US' || VCLE.valtypeSuppl1.trim() === 'GF' || VCLE.valtypeSuppl1.trim() === 'PU' || VCLE.valtypeSuppl1.trim() === 'LI') {
            setUrinSticksDialogData({
                mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: VCLE.reqid.toString(), pid: VCLE.pid.toString(), fid: VCLE.fid.toString(),
                paramid: "", reqdatetime: VCLE.reqdatetime, reqprovider: VCLE.reqprovider, valtype: VCLE.valtype, addedparams: "", pregnancyweek: VCLE.pregnancyweek, mbyn: "0",
                userid: UserSession.getLoggedInUser().UserID, showDialog: action === 'open' ? true : false, ResultSaved: ResultSaved, selprofilecode: "", dayWorkSpace: false
            });
        } else if (VCLE.valtypeSuppl1.trim() === 'HM' || VCLE.valtypeSuppl1.trim() === 'MD') {
            setHamatDialogData({
                mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: VCLE.reqid.toString(), pid: VCLE.pid.toString(), fid: VCLE.fid.toString(),
                paramid: "", reqdatetime: VCLE.reqdatetime, reqprovider: VCLE.reqprovider, valtype: VCLE.valtype, addedparams: "", pregnancyweek: VCLE.pregnancyweek, mbyn: "0",
                userid: UserSession.getLoggedInUser().UserID, showDialog: action === 'open' ? true : false, ResultSaved: ResultSaved, selprofilecode: "", dayWorkSpace: false
            });
        } else if (VCLE.valtypeSuppl1.trim() === 'S') {
            if (await ReqIsReadyForValidation(VCLE) === true) {
                setIHDialogData({
                    mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: VCLE.reqid.toString(), pid: VCLE.pid.toString(), fid: VCLE.fid.toString(),
                    paramid: "", reqdatetime: VCLE.reqdatetime, reqprovider: VCLE.reqprovider, valtype: VCLE.valtype, addedparams: "", pregnancyweek: VCLE.pregnancyweek, mbyn: "0",
                    userid: UserSession.getLoggedInUser().UserID, showDialog: action === 'open' ? true : false, ResultSaved: ResultSaved, selprofilecode: "", dayWorkSpace: false
                });
            } else {
                setSeroResultEntryDialogData({
                    mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: VCLE.reqid.toString(), pid: VCLE.pid.toString(), fid: VCLE.fid.toString(),
                    paramid: "", reqdatetime: VCLE.reqdatetime, reqprovider: VCLE.reqprovider, valtype: VCLE.valtype, addedparams: "", pregnancyweek: VCLE.pregnancyweek, mbyn: "0",
                    userid: UserSession.getLoggedInUser().UserID, showDialog: action === 'open' ? true : false, ResultSaved: ResultSaved, selprofilecode: "", dayWorkSpace: false
                });
            };           
        };
        
    }, [valData]);  

    async function ReqIsReadyForValidation(VCLE: valcriteriaListEntry) {
        var bok: boolean = true;
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'general/reqisreadyforvalidation',
            JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: VCLE.reqid, valtype: VCLE.valtype}),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        bok = response?.data
        return bok;
    };

    async function ResultsCanBeValidated(VCLE: valcriteriaListEntry) {
        var bok: boolean = true;
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'validationcriteria/resultscanbevalidated',
            JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: VCLE.reqid, valtype: VCLE.valtype, mbyn: false }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        bok = response?.data
        return bok;
    };

    const CustomCellEdit = (props: GridCellProps) => {
        const value = props.dataItem['reqid'];        
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <Button className="buttons-container-button" icon="zoom-in"
                    onClick={() => {
                        handleEditClick(props.dataItem, 'open');                                                
                    }}
                >
                </Button>
            </td>
        );
    };
    const CustomCellVR = (props: GridCellProps) => {
        const value = props.dataItem['reqid'];
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <Button className="buttons-container-button" icon="eye"
                    onClick={() => {
                        //alert('edit clicked for ' + value);                        
                        showViewResultForm(value, 'open');
                    }}
                >
                </Button>
            </td>
        );
    };
    const cancleClicked = () => {
        setViewResultDialog(dlgEmptyState);
    };

    const handleValYNCheck = (event: CheckboxChangeEvent, reqid: string) => {
        const binddata: valcriteriaListEntry[] = [...valData];
        var tabElement: valcriteriaListEntry;
        for (tabElement of binddata) {
            if (tabElement.reqid === reqid) {
                tabElement.valyn = event.value ? "1" : "0";
                break;
            }
        }
        setvalData(binddata);
    };

    const handleResYNCheck = (event: CheckboxChangeEvent, reqid: string) => {
        const binddata: valcriteriaListEntry[] = [...valData];
        var tabElement: valcriteriaListEntry;
        for (tabElement of binddata) {
            if (tabElement.reqid === reqid) {
                tabElement.resultyn = event.value ? "1" : "0";
                break;
            }
        }
        setvalData(binddata);
    };

    const CustomCellVC = (props: GridCellProps) => {
        const value = props.dataItem['reqid'];
        const checkvalue = props.dataItem['valyn'];
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <Checkbox onChange={(event: CheckboxChangeEvent) => handleValYNCheck(event, value)} checked={checkvalue === "1" ? true : false} id='chkvalyn'></Checkbox>
            </td>
        );
    };

    const rowRender = (
        trElement: React.ReactElement<HTMLTableRowElement>,
        props: GridRowProps
    ) => {
        const rowColor = { backgroundColor: props.dataItem.rowcolor };
        const trProps: any = { style: rowColor };
        return React.cloneElement(
            trElement,
            { ...trProps }
        );
    };

    const CustomCellRes = (props: GridCellProps) => {
        const value = props.dataItem['reqid'];
        const checkvalue = props.dataItem['resultyn'];
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <Checkbox onChange={(event: CheckboxChangeEvent) => handleResYNCheck(event, value)} checked={checkvalue === "1" ? true : false} id='resultyn'></Checkbox>
            </td>
        );
    };

    const location: string = props.loadfor;
    var formHeight: number = window.innerHeight - 120;
    var gridHeight: number = formHeight;
    var contentWidth: number = 0;
    if (location === "modal") {
        gridHeight -= 100; // as dialog
        contentWidth = window.innerWidth - 500;
    }
    else {
        formHeight -= 50;
        gridHeight -= 170; // as dashboard
        contentWidth = window.innerWidth - 120;
    }
    
    return (
        <>
            <div className="container-fluid" style={{ overflowX: "hidden", height: formHeight, width: '98%', minWidth: contentWidth }}>
                <div className="row" style={{ border: '1px solid lightblue', margin: 5, backgroundColor: "#e0f0ff", borderRadius: 0 }}>
                    <div className="col-12">&nbsp;</div>
                    <div className="col">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child"><Label editorId={'valCriteria'}>{mui(userRights, "valcriteria", "lblValType", "Val Type")}</Label></div>
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} setSelectedCT={callbackCTSelected} mandantChanged={props.mandantChanged} id="valType"
                                    codeTableID='202' selCode={selectedCode} supplParam1="" supplParam2="" width="300px"></CodeTableSel>
                            </div>
                        </div>
                    </div>
                    <div className="col-2">
                        <div style={{ display: 'inline-block', paddingTop: 25 }}>
                            <Checkbox onChange={() => setCheckedExtMandant(!checkedExtMandant)} name="showdataforexternmandant" label={mui(userRights, "valcriteria", "chkShowDataForExtnMandant", "Show Data For Extern Mandant")} />
                        </div>                        
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child"><Label editorId={'locatereqid'}>{mui(userRights, "valcriteria", "lblReqIDSearch", "ReqID search")}</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="locatereqid" name="locatereqid" type='text' style={{ width: '300px' }} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child"><Label editorId={'locatereqnr'}>{mui(userRights, "valcriteria", "lblReqNrSearch", "ReqNr search")}</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="locatereqnr" name="locatereqnr" type='text' style={{ width: '300px' }} />
                            </div>
                        </div>
                    </div>
                    <div className="col">&nbsp;</div>
                    <div className="col">&nbsp;</div>
                    <div className="col-12">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col">
                        {isLoading ? <ProgressBar message=""></ProgressBar> :
                            <Grid
                                style={{
                                    height: gridHeight, width: '100%'
                                }}
                                data={orderBy(valData, sort)}
                                sortable={true}
                                sort={sort}
                                onSortChange={(e: GridSortChangeEvent) => {
                                    setSort(e.sort);
                                }}
                                rowRender={rowRender}
                            >
                                <GridColumn field="" title={mui(userRights, "valcriteria", "col1_cmdValidate", "")} width="50px" cell={CustomCellEdit} />
                                <GridColumn field="" title={mui(userRights, "valcriteria", "col2_cmdViewResult", "")} width="50px" cell={CustomCellVR} />
                                <GridColumn field="reqid" title={mui(userRights, "valcriteria", "col3_ReqID", "ReqID")} width="150px" />
                                <GridColumn field="reqdatetime" title={mui(userRights, "valcriteria", "col4_ReqDateTime", "Req Date Time")} />
                                <GridColumn field="fid" title={mui(userRights, "valcriteria", "col5_FID", "FID")} />
                                <GridColumn field="pid" title={mui(userRights, "valcriteria", "col6_PID", "PID")} />
                                <GridColumn field="name" title={mui(userRights, "valcriteria", "col7_Name", "Name")} />
                                <GridColumn field="reqprovider" title={mui(userRights, "valcriteria", "col8_ReqProv", "Request Provider")} />
                                <GridColumn field="birthdate" title={mui(userRights, "valcriteria", "col9_Birthdate", "Birthdate")} />
                                {/*<GridColumn field="valtype" title={mui(userRights, "valcriteria", "col10_ValType", "ValType")} />*/}
                                <GridColumn field="diffnr" title={mui(userRights, "valcriteria", "col11_DiffNr", "Diff Nr.")} />
                                {/*<GridColumn field="valtype" title={mui(userRights, "valcriteria", "col12_ValYN", "ValYN")} />*/}
                                <GridColumn field="reqnr" title={mui(userRights, "valcriteria", "col13_ReqNr", "Req Nr")} />
                                <GridColumn field="resultyn" title={mui(userRights, "valcriteria", "col14_ResultYN", "ResultYN")} cell={CustomCellRes} />
                                );
                            </Grid>}
                    </div>
                </div>
            </div>
            <Suspense fallback={<Loader />}>
                <ViewResultDialog objVRC={viewResultDialog?.objVRC} showDialog={viewResultDialog?.showDialog} cancleClicked={cancleClicked}></ViewResultDialog>
                <ChemieResultEntryDialog mandantid={resultEntryDialogData.mandantid} reqid={resultEntryDialogData.reqid} pid={resultEntryDialogData.pid} fid={resultEntryDialogData.fid}
                    paramid={resultEntryDialogData.paramid} reqdatetime={resultEntryDialogData.reqdatetime} reqprovider={resultEntryDialogData.reqprovider} valtype={resultEntryDialogData.valtype} addedparams={resultEntryDialogData.addedparams}
                    pregnancyweek={resultEntryDialogData.pregnancyweek} mbyn={resultEntryDialogData.mbyn} userid={resultEntryDialogData.userid}
                    showDialog={resultEntryDialogData.showDialog} ResultSaved={resultEntryDialogData.ResultSaved} selprofilecode={""} dayWorkSpace={false}></ChemieResultEntryDialog>
                <UrineSticksResultAndValidation mandantid={urinsticksDialogData.mandantid} reqid={urinsticksDialogData.reqid} pid={urinsticksDialogData.pid} fid={urinsticksDialogData.fid}
                    paramid={urinsticksDialogData.paramid} reqdatetime={urinsticksDialogData.reqdatetime} reqprovider={urinsticksDialogData.reqprovider} valtype={urinsticksDialogData.valtype} addedparams={urinsticksDialogData.addedparams}
                    pregnancyweek={urinsticksDialogData.pregnancyweek} mbyn={urinsticksDialogData.mbyn} userid={urinsticksDialogData.userid}
                    showDialog={urinsticksDialogData.showDialog} ResultSaved={urinsticksDialogData.ResultSaved} selprofilecode={""} dayWorkSpace={false}></UrineSticksResultAndValidation>
                <HamatResultAndValidation mandantid={hamatDialogData.mandantid} reqid={hamatDialogData.reqid} pid={hamatDialogData.pid} fid={hamatDialogData.fid}
                    paramid={hamatDialogData.paramid} reqdatetime={hamatDialogData.reqdatetime} reqprovider={hamatDialogData.reqprovider} valtype={hamatDialogData.valtype} addedparams={hamatDialogData.addedparams}
                    pregnancyweek={hamatDialogData.pregnancyweek} mbyn={hamatDialogData.mbyn} userid={hamatDialogData.userid}
                    showDialog={hamatDialogData.showDialog} ResultSaved={hamatDialogData.ResultSaved} selprofilecode={""} dayWorkSpace={false}></HamatResultAndValidation>
                <SerologyResultAndValidation mandantid={seroresultEntryDialogData.mandantid} reqid={seroresultEntryDialogData.reqid} pid={seroresultEntryDialogData.pid} fid={seroresultEntryDialogData.fid}
                    paramid={seroresultEntryDialogData.paramid} reqdatetime={seroresultEntryDialogData.reqdatetime} reqprovider={seroresultEntryDialogData.reqprovider} valtype={seroresultEntryDialogData.valtype} addedparams={seroresultEntryDialogData.addedparams}
                    pregnancyweek={seroresultEntryDialogData.pregnancyweek} mbyn={seroresultEntryDialogData.mbyn} userid={seroresultEntryDialogData.userid}
                    showDialog={seroresultEntryDialogData.showDialog} ResultSaved={seroresultEntryDialogData.ResultSaved} selprofilecode={""} dayWorkSpace={false}></SerologyResultAndValidation>
                <IHValidation mandantid={ihDialogData.mandantid} reqid={ihDialogData.reqid} pid={ihDialogData.pid} fid={ihDialogData.fid}
                    paramid={ihDialogData.paramid} reqdatetime={ihDialogData.reqdatetime} reqprovider={ihDialogData.reqprovider} valtype={ihDialogData.valtype} addedparams={ihDialogData.addedparams}
                    pregnancyweek={ihDialogData.pregnancyweek} mbyn={ihDialogData.mbyn} userid={ihDialogData.userid}
                    showDialog={ihDialogData.showDialog} ResultSaved={ihDialogData.ResultSaved} selprofilecode={ihDialogData.selprofilecode} dayWorkSpace={false}></IHValidation>
            </Suspense>
        </>
    );
}

export default ValCriteria;
