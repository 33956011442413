import { getSelectedState, GridSelectionChangeEvent } from "@progress/kendo-react-grid";
import { Splitter, SplitterOnChangeEvent } from "@progress/kendo-react-layout";
import React, { useEffect, useState } from "react";
import { DialogTitleBar, mui } from "../../../Helper";
import { CCxCodeTableData, CScanData, CUserRightsAppl, viewScanDialog } from "../../../types";
import axios from 'axios';
import UserSession from "../../UserSession";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { getter,  SortDescriptor } from "@progress/kendo-data-query";
import { TransformComponent, TransformWrapper, useControls } from "react-zoom-pan-pinch";
import { PDFViewer } from "@progress/kendo-react-pdf-viewer";
import { ItemRenderProps, processTreeViewItems, TreeView, TreeViewExpandChangeEvent, TreeViewItemClickEvent } from "@progress/kendo-react-treeview";

type CTreeItem = {
    text: String;
    expanded: Boolean;
    selected: Boolean;
    objCTD: CCxCodeTableData;
    objScanData: CScanData;
};
type CCTDTree = {
    text: string;
    expanded: boolean;
    items: CTreeItem[];
    mandantid: string;
    selected: Boolean;
};

const ViewScan = (props: viewScanDialog) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'viewscan' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const [visible, setVisible] = useState<boolean>(props.showDialog);
    const [viewScanData, setViewScanData] = useState<CScanData[]>([]);

    const [scanDataTree, setScanDataTree] = useState<CCTDTree[]>([]);
    const [check, setCheck] = React.useState([]);
    const [select, setSelect] = React.useState([""]); 
    const [size, setSize] = React.useState<"small" | "medium" | "large">(
        "medium"
    );

    const [imageURL, setImageURL] = useState("");
    const [showPDF, setShowPDF] = useState<boolean>(false);

    const [panes, setPanes] = React.useState<Array<any>>([
        { size: "20%", min: "20%", collapsible: true, resizable: true },
        { collapsible: false, resizable: true },
    ]);

    const initialSort: Array<SortDescriptor> = [        

    ];

    const [selectedState, setSelectedState] = useState<{
        [id: string]: boolean | number[];
    }>({});

    //const [sort, setSort] = useState(initialSort);
    //const idGetter = getter('sKey');

    //const onSelectionChange = (event: GridSelectionChangeEvent) => {
    //    var url: string = "";
    //    const newSelectedState = getSelectedState({
    //        event,
    //        selectedState: selectedState,
    //        dataItemKey: 'sKey',
    //    });
    //    let obj: CScanData; 
    //    for (const property in newSelectedState) {
    //        if (newSelectedState[property]) {
    //            let obj = viewScanData.find(item => item.sKey === property.toString())                
    //            if (obj?.sFileType.toUpperCase() === "PDF") {
    //                setShowPDF(true);
    //            } else {
    //                setShowPDF(false);
    //            };
    //            url = UserSession.getSystemSettings().API_URL + "scan/ShowScan?sCurrentMandant=" + UserSession.getLoggedInUser().CurrentMandant
    //            url += '&sMandantID=' + obj?.sMandantID
    //            url += '&sPID=' + obj?.sPID
    //            url += '&sReqID=' + obj?.sReqID
    //            url += '&sDocumentNr=' + obj?.sDocumentNr
    //            url += '&sPageNr=' + obj?.sPageNr
    //            url += '&sExtLabName=' + obj?.sExtLabName
    //            url += '&sIDFrgCard=' + obj?.sIDFrgCard
    //            url += '&sBldBgNr=' + obj?.sBldBgNr
    //            url += '&sBldBgType=' + obj?.sBldBgType
    //            url += '&sMaterialID=' + obj?.sMaterialID
    //            url += '&sLotID=' + obj?.sLotID
    //            url += '&sQCExtCenter=' + obj?.sQCExtCenter
    //            url += '&sID=' + obj?.sID
    //            url += '&sCompID=' + obj?.sCompID
    //            url += '&sCompBar=' + obj?.sCompBar
    //            url += '&sFileType=' + obj?.sFileType
    //            url += '&enm=' + obj?.enm
    //            setImageURL (url);                
    //        }
    //    };
    //    setSelectedState(newSelectedState);
    //};
       
    //const callbackPDFDialogClosed = () => {
    //    setShowPDF(false);
    //}
    const onPaneChange = (event: SplitterOnChangeEvent) => {
        setPanes(event.newState);
    };

    const CancelClick = async () => {
        props.callbackDialogClosed();   
        setVisible(false);
    };

    const GetScanData = async () => {
        try {
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'scan/GetScanData',
                JSON.stringify({
                    sMandantID: props.sMandantID, sPID: props.sPID, sReqID: props.sReqID,
                    sBldBgNr: props.sBldBgNr, sBldBgType: props.sBldBgType, sMaterialID: props.sMaterialID,
                    sLotID: props.sLotID, enm: props.enm
                }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
            //var objScanData: CScanData[];
            if (response?.data !== null) {
                setScanDataTree(response?.data);
                //objScanData = response?.data
                //setViewScanData(objScanData);
                //let sel: any = {};
                //sel[objScanData[0].sKey] = true;
                //setSelectedState(sel);
                //var url: string = "";
                //var obj: CScanData = objScanData[0];
                //if (obj?.sFileType.toUpperCase() === "PDF") {
                //    setShowPDF(true);
                //} else {
                //    setShowPDF(false);
                //};
                //url = UserSession.getSystemSettings().API_URL + "scan/ShowScan?sCurrentMandant=" + UserSession.getLoggedInUser().CurrentMandant
                //url += '&sMandantID=' + obj?.sMandantID
                //url += '&sPID=' + obj?.sPID
                //url += '&sReqID=' + obj?.sReqID
                //url += '&sDocumentNr=' + obj?.sDocumentNr
                //url += '&sPageNr=' + obj?.sPageNr
                //url += '&sExtLabName=' + obj?.sExtLabName
                //url += '&sIDFrgCard=' + obj?.sIDFrgCard
                //url += '&sBldBgNr=' + obj?.sBldBgNr
                //url += '&sBldBgType=' + obj?.sBldBgType
                //url += '&sMaterialID=' + obj?.sMaterialID
                //url += '&sLotID=' + obj?.sLotID
                //url += '&sQCExtCenter=' + obj?.sQCExtCenter
                //url += '&sID=' + obj?.sID
                //url += '&sCompID=' + obj?.sCompID
                //url += '&sCompBar=' + obj?.sCompBar
                //url += '&sFileType=' + obj?.sFileType
                //url += '&enm=' + obj?.enm
                //console.log('url', url);
                //setImageURL(url);            
            };
            
            return;
        } catch (err: any) {
            console.log(err);
            if (!err?.response) {
            } else if (err.response?.status === 401) {
            } else {
            }
        }
    };

    const Controls = () => {
        const { zoomIn, zoomOut, resetTransform } = useControls();
        return (
            <>
                <button style={{ width: 100, height: 30 }} onClick={() => zoomIn()}>+</button>
                <button style={{ width: 100, height: 30 }} onClick={() => zoomOut()}>-</button>
                <button style={{ width: 100, height: 30 }} onClick={() => resetTransform()}>Reset</button>
            </>
        );
    };

    const onExpandChange = (event: TreeViewExpandChangeEvent) => {
        const updatedData: any = scanDataTree.slice();

        var itemIndex: number = updatedData.indexOf(event.item);
        if (itemIndex === -1) { itemIndex = 0 };
        updatedData[itemIndex] = { ...event.item };
        updatedData[itemIndex].expanded = !updatedData[itemIndex].expanded;

        setScanDataTree(updatedData);        
    };

    const editCTD = (event: TreeViewItemClickEvent) => {
        if (event.item.hasOwnProperty('objScanData')) {
            var url: string = "";
            var obj: CScanData = event.item.objScanData;
            if (obj?.sFileType.toUpperCase() === "PDF") {
                setShowPDF(true);
            } else {
                setShowPDF(false);
            };
            url = UserSession.getSystemSettings().API_URL + "scan/ShowScan?sCurrentMandant=" + UserSession.getLoggedInUser().CurrentMandant
            url += '&sMandantID=' + obj?.sMandantID
            url += '&sPID=' + obj?.sPID
            url += '&sReqID=' + obj?.sReqID
            url += '&sDocumentNr=' + obj?.sDocumentNr
            url += '&sPageNr=' + obj?.sPageNr
            url += '&sExtLabName=' + obj?.sExtLabName
            url += '&sIDFrgCard=' + obj?.sIDFrgCard
            url += '&sBldBgNr=' + obj?.sBldBgNr
            url += '&sBldBgType=' + obj?.sBldBgType
            url += '&sMaterialID=' + obj?.sMaterialID
            url += '&sLotID=' + obj?.sLotID
            url += '&sQCExtCenter=' + obj?.sQCExtCenter
            url += '&sID=' + obj?.sID
            url += '&sCompID=' + obj?.sCompID
            url += '&sCompBar=' + obj?.sCompBar
            url += '&sFileType=' + obj?.sFileType
            url += '&enm=' + obj?.enm
            console.log('url', url);
            setImageURL(url);            
        } else {
           
        };
    };

    const CustomItem = (props: ItemRenderProps) => {
        return (
            <>
                {
                    props.item.hasOwnProperty('mandantid') ? <span data-code={"M:" + props.item.text} key="0" className="k-icon k-font-icon k-i-folder" />
                        : <span data-code={props.item.text} key="0" className="k-icon k-font-icon k-i-html" />
                }
                {props.item.text}
            </>
        );
    };

    useEffect(() => {
        setShowPDF(false);
        setImageURL("");    
        GetScanData();
        setVisible(props.showDialog);
    }, [props.showDialog]);     

    var rootHeight = window.innerHeight - 100;
    var rootWidth = window.innerWidth - 100;
    var paneContentHeightLeft: number = rootHeight-145;    
    var paneContentHeighRight: number = rootHeight - 145;      
    
    return (
        <>
            <div>
                {visible && (
                    <Dialog style={{ overflowX: 'hidden' }} title={<DialogTitleBar title={mui(userRights, "viewscan", "textTitle", "View Scan")} />} onClose={CancelClick} height={rootHeight} width={rootWidth} >
                        <Splitter panes={panes} onChange={onPaneChange}>
                            <div className="pane-content-rm" style={{ height: paneContentHeightLeft, padding: "5px", overflowX: 'hidden' }}>
                                {/*<Grid*/}
                                {/*    style={{*/}
                                {/*        height: gridHeight, width: '100%'*/}
                                {/*    }}*/}
                                {/*    selectable={{*/}
                                {/*        enabled: true,*/}
                                {/*        drag: false,*/}
                                {/*        cell: false,*/}
                                {/*        mode: "single",*/}
                                {/*    }}*/}
                                {/*    onSelectionChange={onSelectionChange}*/}
                                {/*    selectedField={'selected'}*/}
                                {/*    data={orderBy(viewScanData, sort).map((item) => ({*/}
                                {/*        ...item,*/}
                                {/*        selected: selectedState[idGetter(item)],*/}
                                {/*    }))}      */}
                                {/*    dataItemKey={'sKey'}*/}
                                {/*>*/}
                                {/*    <GridColumn field="sPID" title={mui(userRights, "viewscan", "col_PID", "PID")} width="100px" />*/}
                                {/*    <GridColumn field="sReqID" title={mui(userRights, "viewscan", "col_ReqID", "ReqID")} width={props.enm === enmScanning.Scan_PatDocs ? "0" : "90px"} />*/}
                                {/*    <GridColumn field="sIDFrgCard" title={mui(userRights, "viewscan", "col_IDFrgCard", "IDFrgCard")} width={props.enm === enmScanning.Scan_BGCards? "90px" : "0"} />*/}
                                {/*    <GridColumn field="sDocumentNr" title={mui(userRights, "viewscan", "col_DocumentNr", "DocNr")} width="70px" />*/}
                                {/*    <GridColumn field="sExtLabName" title={mui(userRights, "viewscan", "col_ExtLabName", "ExtLabName")} width={props.enm === enmScanning.Scan_ExternLabResults? "100px" : "0"} />*/}
                                {/*    <GridColumn field="sBldBgNr" title={mui(userRights, "viewscan", "col_BldBgNr", "BldBgNr")} width={(props.enm === enmScanning.Scan_DeliveryLabels || props.enm === enmScanning.Scan_TransReaction) ? "100px" : "0"} />*/}
                                {/*    <GridColumn field="sBldBgType" title={mui(userRights, "viewscan", "col_BldBgType", "BldBgType")} width={(props.enm === enmScanning.Scan_DeliveryLabels || props.enm === enmScanning.Scan_TransReaction) ? "100px" : "0"} />*/}
                                {/*    <GridColumn field="sFileType" title={mui(userRights, "viewscan", "col_FileType", "FileType")} width="90px" />*/}
                                {/*    );*/}
                                {/*</Grid>*/}
                                <TreeView
                                    className="scrollable-treeview"    
                                    item={CustomItem}
                                    size={size}
                                    expandIcons={true}
                                    onExpandChange={onExpandChange}
                                    onItemClick={editCTD}
                                    data={processTreeViewItems(scanDataTree, {
                                        select: select,
                                        check: check,
                                    })}

                                />
                            </div>
                            <div className="pane-content-rm" style={{
                                height: paneContentHeighRight, width: "100%",
                                overflowY: 'hidden', border: '0px solid green'
                            }}>
                                <div className="row">
                                    {showPDF === false && <TransformWrapper>
                                        <div className="row">
                                            <div className="col">
                                                <Controls />
                                            </div>
                                        </div>
                                        <TransformComponent>
                                            <div className="row">
                                                <div className="col">
                                                    <img src={imageURL} alt="" />
                                                </div>
                                            </div>
                                        </TransformComponent>
                                    </TransformWrapper>}
                                    {showPDF === true && <PDFViewer defaultZoom={1} url={ imageURL} style={{ height: '100%', width: '100%' }} />}  
                                </div>
                            </div>
                        </Splitter>&nbsp;                        
                        <div className="row">                            
                            <div className="col-12">
                                <div className="button-right">
                                    <Button type={"button"} style={{ width: '150px' }} themeColor={'secondary'} onClick={() => {
                                        CancelClick()
                                    }}>{mui(userRights, "common", "cmdCancel", "Cancel")}</Button>
                                </div>
                            </div>                                                        
                        </div>
                    </Dialog>
                )}       
            </div>                 
        </>
    );
}

export default ViewScan;