/* eslint-disable react/jsx-no-undef */

import { useEffect, useState } from "react";
import { formatDate, formatTime } from "../../Helper";
import { CResultEntry, ctDataType, CUserRightsAppl, selCodeDataType, emptyREData } from "../../types";

import { SelectCode } from "../SelectCode/SelectCode";

import CustomDate from "../Misc/CustomDate";
import CustomTime from "../Misc/CustomTime";
import { CodeTableSel } from "../CodeTableSel/CodeTableSel";
import { Input } from "@progress/kendo-react-inputs";
interface resSelDialog {
  
    objRE: CResultEntry;
    selType: string;
}
const CustomResultEntry = (props: resSelDialog) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'resseldialog' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const [resultData, setResultData] = useState<CResultEntry>(emptyREData);

    const [resDate, setResDate] = useState(formatDate(new Date().toDateString()));
    const [resTime, setResTime] = useState(formatTime(new Date().toLocaleString(), true));
    const [selCode, setSelCode] = useState<string | "">("");
    const [selDesc, setSelDesc] = useState<string | "">("");

    const [formHeight, setFormHeight] = useState<number | 540>(540);
    const [selDefCode, setSelDefCode] = useState<string | "">("");

    const handleResChangeDate = (value: string, source: string, supplParam1?: string, supplParam2?: string) => {
        console.log('event.value', value);
        props.objRE.sRawResult = value
        if (props.objRE.ExistingResult.length > 0) {
            props.objRE.sRecStatus = "CHG"
        }
        else {
            props.objRE.sRecStatus = "UNS"
        }
       
        setResDate(value)
        setSelCode(value);
        setSelDesc("");
        //if (value !== undefined && value !== null) {
        //    setSelCode(formatDate(value.toString()));
        //    setSelDesc("");
        //} else {
        //    setSelCode("");
        //    setSelDesc("");
        //}
    };

    const handleResChangeTime = (value: string, source: string, supplParam1?: string, supplParam2?: string) => {
        console.log('event.value', value);
        if (props.objRE.ExistingResult.length > 0) {
            props.objRE.sRecStatus = "CHG"
        }
        else {
            props.objRE.sRecStatus = "UNS"
        }
        props.objRE.sRawResult = value
        setResTime(value)
        setSelCode(value);
        setSelDesc("");
        //if (event.value !== undefined && event.value !== null) {
        //    setSelCode(event.value?.getHours() + ':' + event.value?.getMinutes() + ':' + event.value?.getSeconds());
        //    setSelDesc("");
        //} else {
        //    setSelCode("");
        //    setSelDesc("");
        //}
    };

    const callbackCTSelected = async (ctid: string, controlID: string, objCT: ctDataType, supplParam1: string, supplParam2: string) => {
        console.log("callbackCTSelected", objCT)
        if (props.objRE.ExistingResult.length > 0) {
            props.objRE.sRecStatus = "CHG"
        }
        else {
            props.objRE.sRecStatus = "UNS"
        }
        if (objCT.sCode !== undefined && objCT.sCode !== null) {
            setSelCode(objCT.sCode);
            setSelDesc(objCT.sDescDevelopment);
        } else {
            setSelCode("");
            setSelDesc("");
        }
    };

    const callbackResultSel = async (tableid: string, controlID: string, obj: selCodeDataType, supplParam1: string, supplParam2: string) => {
        console.log("callbackResultSel", obj)
        if (props.objRE.ExistingResult.length > 0) {
            props.objRE.sRecStatus = "CHG"
        }
        else {
            props.objRE.sRecStatus = "UNS"
        }
        if (obj.code !== undefined && obj.code !== null) {
            setSelCode(obj.code);
            setSelDesc(obj.desc);
           
        } else {
            setSelCode("");
            setSelDesc("");
        }
    };

    useEffect(() => {
        setResultData(props.objRE);
        setSelCode("");
        setSelDesc("");
        setSelDefCode(props.objRE.sRawResult);

    }, [])


    const onBlur = async (e: any) => {
        setSelDefCode(e.target.value);
        props.objRE.sRawResult = e.target.value;

    };

    const handleChange = (e: any) => {
        setSelDefCode(e.target.value);
        props.objRE.sRawResult = e.target.value;
    }

    return (
        <>
            <div className="container-fluid" style={{ overflowX: 'hidden', width: '100px' }}>
                <div className="row">

                    <div className="col-12">
                        {

                            props.selType === "RESULT" ?

                            props.objRE.sResSelTableName === "Date" ?
                                (<CustomDate objRE={props.objRE} dateChanged={handleResChangeDate} onlyTextBox={true} width="100%" defaulValue={props.objRE.sRawResult} id={'txtReqDate'} source={'selDefCode'} label="" supplParam1={props.objRE.sParamID} supplParam2=""
                                    required={false} disabled={false}></CustomDate>)
                            :
                            props.objRE.sResSelTableName === "Time" ?
                                    (<CustomTime objRE={props.objRE} allowSecond={true} supplParam1={props.objRE.sParamID} supplParam2="" timeChanged={handleResChangeTime} width="100%" defaulValue={props.objRE.sRawResult} id={'ResTime'} source={'selDefCode'} label="" required={false} disabled={false}></CustomTime>)
                            :
                                props.objRE.sCodeTableID !== "" ?
                                    (<CodeTableSel objRE={props.objRE} autocomplete={true} setSelectedCT={callbackCTSelected} mandantChanged={parseInt(props.objRE.sMandantID)} id={props.objRE.sCodeTableID}
                                        codeTableID={props.objRE.sCodeTableID} selCode={props.objRE.sRawResult} width="100%" supplParam1={props.objRE.sParamID} supplParam2=""></CodeTableSel>)
                                    :
                                    (props.objRE.sResSelTableName === "ResultCode" || props.objRE.sResSelTableName === "PhenoType" || props.objRE.sResSelTableName === "Antibodies") ?
                                        (<SelectCode objRE={props.objRE} autocomplete={true} disabled={false} setSelectedTable={callbackResultSel} id="ResultCode"
                                            tablename={props.objRE.sResSelTableName} code={props.objRE.sResSelCodeFieldName} desc={props.objRE.sResSelDescrFieldName} selCode={props.objRE.sRawResult}
                                            mandantChanged={0} width="100%" supplParam1={props.objRE.sParamID} supplParam2=""></SelectCode>)
                                        :
                                        <Input
                                            onBlur={onBlur} disabled={false}
                                                onChange={handleChange} defaultValue={props.objRE.sRawResult} value={props.objRE.sRawResult}
                                            style={{ textAlign: 'right' }}
                                            width="100%"
                                                />
                                :
                                (<SelectCode objRE={props.objRE} autocomplete={true} disabled={false} setSelectedTable={callbackResultSel} id="ResultComm"
                                    tablename={"ResultComm"} code={"Code"} desc={"Conclusion"} selCode={props.objRE.sResultComm}
                                    mandantChanged={0} width="100%" supplParam1={props.objRE.sParamID} supplParam2=""></SelectCode>)

                        }
                   
                    </div>

                </div>
            </div>
        </>
    );
}

export default CustomResultEntry;