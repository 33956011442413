import { Dialog } from "@progress/kendo-react-dialogs";
import { useEffect, useState } from "react";
import { DialogTitleBar, mui } from "../../Helper";
import { CUserRightsAppl, qcResultEntryDialog } from "../../types";
import QCResultEntryComponent from "./QCResultEntryComponent";

const QCResultEntryDialog = (props: qcResultEntryDialog) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'QCResultEntry' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;
        
    const [visible, setVisible] = useState<boolean>(props.showDialog);
    
    const CancelClick = async () => {
        //props.ResultSaved(false);
        setVisible(false);
    };

    useEffect(() => {
        setVisible(props.showDialog);
    }, [props.showDialog])

    var dlgHeight: number = window.innerHeight - 80;
    var dlgWidth: number = window.innerWidth - 40;

    return (
        <>
            {visible &&
                <Dialog title={<DialogTitleBar title={mui(userRights, "QCResultEntry", "textTitle", "QC Result")} />} onClose={CancelClick} height={dlgHeight} closeIcon={false} width={dlgWidth} >
                    <QCResultEntryComponent mandantid={props.mandantid} userid={props.userid} formstate={props.formstate}
                        showDialog={props.showDialog} obj={props.obj} ResultSaved={props.ResultSaved}></QCResultEntryComponent>
                </Dialog>
            }
        </>
    );       
}
export default QCResultEntryDialog;