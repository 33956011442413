
import {useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DialogTitleBar, mui} from "../../Helper";
import IHValidationComponent from "./IHValidationComponent";
import { resultEntryDialog } from "../../types";

const IHValidation = (props: resultEntryDialog) => {
    const [visible, setVisible] = useState<boolean>(props.showDialog);
    
    const CancelClick = async () => {
       /* props.ResultSaved(false);*/
        setVisible(false);
        
    };

    useEffect(() => {      
        setVisible(props.showDialog);
    }, [props.showDialog])

    var dlgHeight: number = window.innerHeight - 80;    
    var dlgWidth: number = 1800; // window.innerWidth - 40;

    return (
        <>

            {visible &&
                <Dialog title={<DialogTitleBar title={mui(userRights, "IHValidation", "textTitle", "IH Validation")} />}
                    onClose={CancelClick} height={dlgHeight} contentStyle={{ overflow: 'hidden' }} closeIcon={false } width={dlgWidth}>
                    <IHValidationComponent mandantid={props.mandantid} reqid={props.reqid} pid={props.pid} fid={props.fid}
                        paramid={props.paramid} reqdatetime={props.reqdatetime} reqprovider={props.reqprovider} valtype={props.valtype} addedparams={props.addedparams}
                        pregnancyweek={props.pregnancyweek} mbyn={props.mbyn} userid={props.userid}
                        showDialog={true} ResultSaved={props.ResultSaved} selprofilecode={props.selprofilecode} dayWorkSpace={false}></IHValidationComponent>                    
                </Dialog>}
                
        </>
    );
}

export default IHValidation;