import { Suspense, useEffect, useRef, useState } from "react";
import * as React from "react";
import { Grid, GridCellProps, GridColumn, GridExpandChangeEvent, GridFilterChangeEvent, GridGroupChangeEvent, GridItemChangeEvent, GridPageChangeEvent, GridRowProps, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { ListView, ListViewItemProps } from "@progress/kendo-react-listview";
import axios from 'axios'
import UserSession from "../UserSession";
import { Button } from "@progress/kendo-react-buttons";
import { Splitter, SplitterOnChangeEvent } from "@progress/kendo-react-layout";
import {
    Dialog
} from "@progress/kendo-react-dialogs";
import { CNFMonitorSettings, ctDataType, CUserRightsAppl, SystemUser, tParamData } from "../../types";

import {
    CompositeFilterDescriptor,
    filterBy,
    groupBy,
    GroupDescriptor,
    GroupResult,
    orderBy,
    SortDescriptor,
} from "@progress/kendo-data-query";

import {
    setExpandedState,
    setGroupIds,
} from "@progress/kendo-react-data-tools";
import { Loader } from "@progress/kendo-react-indicators";
import { MyCommandCell } from "./MyCommandCell";
import { CodeTableSel } from "../CodeTableSel/CodeTableSel";
import { DialogTitleBar, mui } from "../../Helper";
import { useLocation } from "react-router-dom";
import { MyEditCell } from "./MyEditCell";
import ProgressBar from "../Misc/ProgressBar";

const EditForm = React.lazy(() => import('./editForm'));

interface CobraMonitorProps {
    mandantChanged: number;
    loadfor: string;
}

interface PageState {
    skip: number;
    take: number;
}

type groupData = {
    sCode: string
    sAbbrDevelopment: string
    sCodeTableID: string
    sDescDevelopment: string
    sMandantID: string
    sSuppl1: string
};

type CNFMonitorDisplay = {
    sKey: number
    sDF1: string
    sDF2: string
    sDF3: string
    sDF4: string
    sDF5: string
    sDF6: string
    sDF7: string
    sDF8: string
    sDF9: string
    sDF10: string
    sFC: string
    sBC: string
    sDisplay: string
    sReqID: string
    sH1: string
    sH2: string
    sH3: string
    sH4: string
    sH5: string
    sH6: string
    sH7: string
    sH8: string
    sH9: string
    sH10: string
};

const CobraMonitor = (props: CobraMonitorProps) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'cobramonitor' === ura.sFormName || 'common' === ura.sFormName || 'dlgsettings' === ura.sFormName || 'dlgparamdata' === ura.sFormName;
        }) : null;
    const [isLoading, setIsLoading] = useState(false);
    //param data
    const initialFilter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [{ field: "NameOnRep", operator: "contains", value: "" }],
    };

    const initialSort: Array<SortDescriptor> = [
        { field: "NameOnRep", dir: "asc" },
    ];

    const initialDataState: PageState = { skip: 0, take: 15 };
    const [page, setPage] = React.useState<PageState>(initialDataState);

    const pageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
    };
    //editing
    const [openForm, setOpenForm] = React.useState<boolean>(false);
    const [editItem, setEditItem] = React.useState<CNFMonitorSettings>({ SettingID: '1' });

    //////////interface EditCommandCellProps extends GridCellProps {
    //////////    enterEdit: (item: CNFMonitorSettings) => void;
    //////////}

    //////////const EditCommandCell = (props: EditCommandCellProps) => {
    //////////    <MyCommandCell
    //////////        {...props}
    //////////        edit={enterEdit}
    //////////    />
    //////////    //return (
    //////////    //    <td>
    //////////    //        <button
    //////////    //            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
    //////////    //            onClick={() => props.enterEdit(props.dataItem)}
    //////////    //        >
    //////////    //            Bearbeiten
    //////////    //        </button>
    //////////    //    </td>
    //////////    //);
    //////////};
   
    const editField: string = "inEdit";

    // modify the data in the store, db etc
    const enterEdit = (item: CNFMonitorSettings) => {
        setOpenForm(true);
        setEditItem(item);
    };

    function HexCode(rgba: any, forceRemoveAlpha = true) {
        const fchar = rgba.substring(0, 1);
        if (fchar !== "#") {
            return "#" + rgba.replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
                .split(',') // splits them at ","
                .filter((string: any, index: number) => !forceRemoveAlpha || index !== 3)
                .map((string: string) => parseFloat(string)) // Converts them to numbers
                .map((number: number, index: number) => index === 3 ? Math.round(number * 255) : number) // Converts alpha to 255 number
                .map((number: { toString: (arg0: number) => any; }) => number.toString(16)) // Converts numbers to hex
                .map((string: string | any[]) => string.length === 1 ? "0" + string : string) // Adds 0 when length of one number is 1
                .join("") // Puts the array to togehter to a string
        }
        else {
            return rgba;
        }
    }

    const handleSubmit = (event: CNFMonitorSettings, selNF: string) => {
         console.log(event, selNF);
        var user: SystemUser = UserSession.getLoggedInUser();
        var sUsername = '';
        if (user) {
            sUsername = user.UserFirstName + ' ' + user.UserLastName
        }
        sUsername = user.UserID;
        // console.log(NFMonitorSettingsData);
        let newItem = true;
        let newData = NFMonitorSettingsData.map((item, index) => {
            if (event.SettingID === item.SettingID) {
                
                newItem = false;
                item = { ...event };

                //console.log("1",item.SettingBackColor);

                if (item.SettingBackColor === '#NaN' || item.SettingBackColor === '')
                    item.SettingBackColor = '';
                else
                    item.SettingBackColor = HexCode(item.SettingBackColor);

                //console.log("3", item.SettingBackColor);

                if (item.SettingForeColor === '#NaN' || item.SettingForeColor === '')
                    item.SettingForeColor = '';
                else
                    item.SettingForeColor = HexCode(item.SettingForeColor);

                if (item.SettingType === '3' || item.SettingType === '7' || item.SettingType === '10' )
                    item.SettingValue = selNF;

                item.UserName = sUsername;
                item.bUpdate = true;
            }
            return item;
        });
        if (newItem) {
            newData.push(event);
        }
        //console.log(newData, selNF);        
        SaveNFMonitorSettingsData(newData);
        setOpenForm(false);
    };

    const handleCancelEdit = () => {
        setOpenForm(false);
    };

    const [panes, setPanes] = React.useState<Array<any>>([
        { size: "22%", min: "22%", collapsible: true, resizable: true },
        { collapsible: false, resizable: false },
    ]);

    const onPaneChange = (event: SplitterOnChangeEvent) => {
        setPanes(event.newState);
    };

    // for dyn columns
    type columnDefinition = {
        field: string;
        title: string;
        k: string;
        width: string;
    }

    // param data
    const [ParamData, setParamData] = useState<tParamData[]>([]);
    const [ParamDataRef, setParamDataRef] = useState<tParamData[]>([]);
    const [CodeTableData, setCodeTableData] = useState<ctDataType | null>(null);
    const [selectedCode, setSelectedCode] = useState("");

    const callbackCTSelected = async (ctid: string, controlID: string, objCT: ctDataType) => {
        setSelectedCode(objCT.sCode);
        setCodeTableData(objCT);
    };

    //////////interface ParamDataEditCommandCellProps extends GridCellProps {
    //////////    Editenter: (item: tParamData) => void;
    //////////}

    const getOldItems = () => {
        //console.log("r", ParamDataRef)
        return ParamDataRef;
    };

    const updateItem = (item: tParamData) => {
         let index = ParamData.findIndex((record) => record.sParamId === item.sParamId);
         //console.log(item)
         var user: SystemUser = UserSession.getLoggedInUser();
         var sUsername = '';
         if (user) {
             sUsername = user.UserFirstName + ' ' + user.UserLastName
         }
         sUsername = user.UserID;
         item.bUpdate = true;
         item.sNFMonitorGroup = selectedCode;
         item.sNFMonitorGroupDisplayString = '';
         if (item.sNFMonitorGroup != '') {
             item.sNFMonitorGroupDisplayString = item.sNFMonitorGroup + ' : ' +  CodeTableData?.sDescDevelopment
         }
          
         item.UserName = sUsername;
         ParamData[index] = item;
         const newData = ParamData.map((pditem) =>
             item.sParamId === pditem.sParamId
                 ? item
                 : { ...pditem, disableEdit: false }
         );

         return newData;
    };

    const update = (dataItem: tParamData) => {
        dataItem.inEdit = false;
        dataItem.disableEdit = false;
        const newData = updateItem(dataItem);
        setParamData(newData);
        setParamDataRef(newData);
    };

    const cancel = (dataItem: tParamData) => {
        dataItem.inEdit = false;
        const originalItem : any= getOldItems().find(
            (p) => p.sParamId === dataItem.sParamId
        );
       
        const newData = ParamData.map((item) =>
            item.sParamId === originalItem.sParamId
                ? originalItem
                : { ...item, disableEdit: false }
        );
        setParamData(newData);
    };

    const Editenter = (dataItem: tParamData) => {
        setSelectedCode(dataItem.sNFMonitorGroup);
        setParamData(
            ParamData.map((item) =>
                item.sParamId === dataItem.sParamId
                    ? {
                        ...item,
                        inEdit: true,
                    }
                    : { ...item, disableEdit: true }
            )
        );
    };

    const itemChange = (event: GridItemChangeEvent) => {
        const newData = ParamData.map((item) =>
            item.sParamId === event.dataItem.sParamId
                ? { ...item, [event.field || ""]: event.value }
                : item
        );

        setParamData(newData);
    };

    ////////const PDEditCommandCell = (props: ParamDataEditCommandCellProps) => {
    ////////    return (
    ////////        <td>
    ////////            <button
    ////////                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
    ////////                onClick={() => props.Editenter(props.dataItem)}
    ////////            >
    ////////                {mui(userRights, "EditFormNFSettings", "lblEdit", "Edit")}
    ////////            </button>
    ////////        </td>
    ////////    );
    ////////};

    ////////const MyPDEditCommandCell = (props: GridCellProps) => (
    ////////    <PDEditCommandCell {...props} Editenter={Editenter} />
    ////////);

    const CommandCell = (props: GridCellProps) => (
        <MyCommandCell
            {...props}
            edit={Editenter}
            update={update}
            cancel={cancel}
            editField={editField}
        />
    );
      //

    const [columnsToShow, setColumnsToShow] = useState<columnDefinition[]>([]);
    const [NFMonitorSettingsData, setNFMonitorSettingsData] = useState<CNFMonitorSettings[]>([]);

    const [CobraMonitorData, setCobraMonitorData] = useState<CNFMonitorDisplay[]>([]);
    const [CobraMonitorDisplayData, setCobraMonitorDisplayData] = useState<CNFMonitorDisplay[]>([]);
    const [NFParamGroupData, setNFParamGroupData] = useState<groupData[]>([]);
    const [selectedGroupData, setSelectedGroupData] = useState<groupData[]>([]);
    const stateRef = useRef(selectedGroupData);

    const handleGroupCheckbox = (event: CheckboxChangeEvent, sCode: string) => {        
        var sIDs = "";
        var tabElement: groupData;
        if (event.value === true) { // something checked so add it to the list
            const binddata: groupData[] = [...selectedGroupData];
            for (tabElement of NFParamGroupData) {
                if (tabElement.sCode === sCode) {
                    binddata.push({ ...tabElement });
                    break;
                }
            }
            setSelectedGroupData(binddata);
            if (binddata.length === 0) setSelectAll(false);
            if (binddata.length === NFParamGroupData.length) setSelectAll(true);
            sIDs = Array.prototype.map.call(binddata, function (item: groupData) { return item.sCode; }).join(",");
        }
        else {
            const binddata: groupData[] = [];
            for (tabElement of selectedGroupData) {
                if (tabElement.sCode !== sCode) {
                    binddata.push({ ...tabElement });
                }
            }
            setSelectedGroupData(binddata);
            if (binddata.length === 0) setSelectAll(false);
            if (binddata.length === NFParamGroupData.length) setSelectAll(true);
            sIDs = Array.prototype.map.call(binddata, function (item: groupData) { return item.sCode; }).join(",");
        }                      
        GetCobraMonitorData(UserSession.getLoggedInUser().CurrentMandant, sIDs)
    };

    const [selectAll, setSelectAll] = useState(false);

    const handleSwitch = (bSelectAllToggle: boolean) => {
        var sIDs = "";
        if (bSelectAllToggle) { // select all
            const binddata: groupData[] = [...NFParamGroupData];
            setSelectedGroupData(binddata)
            sIDs = Array.prototype.map.call(binddata, function (item: groupData) { return item.sCode; }).join(",");
        } else { // clear all
            sIDs = '1';
            setSelectedGroupData([]);
        }
        GetCobraMonitorData(UserSession.getLoggedInUser().CurrentMandant, sIDs)
        setSelectAll(bSelectAllToggle);
    };

    async function GetParamData(mandantid: string) {
        const NFMonitorSettingsData_URL = UserSession.getSystemSettings().API_URL + 'ParamSel/getparamdata';
        const response = await axios.post(NFMonitorSettingsData_URL,
            JSON.stringify({ "mandantid": mandantid }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
       
        setParamData(response?.data);
        setParamDataRef(response?.data);
    }

    async function SaveParamData(newdata: tParamData[]) {
        const SaveParamData_URL = UserSession.getSystemSettings().API_URL + 'CobraMonitor/setParamNFMonitorSettings';
        await axios.post(SaveParamData_URL,
            JSON.stringify({ "list": newdata }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        GetParamData(UserSession.getLoggedInUser().CurrentMandant);
        GetNFParamGroupData(UserSession.getLoggedInUser().CurrentMandant, '');
    }

    async function GetNFMonitorSettingsData(mandantid: string) {
        const NFMonitorSettingsData_URL = UserSession.getSystemSettings().API_URL + 'CobraMonitor/getNFMonitorSettings';
        const response = await axios.post(NFMonitorSettingsData_URL,
            JSON.stringify({ "mandantid": mandantid, "nfmonitorgroups": '' }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        setNFMonitorSettingsData(response?.data);
    }

    async function GetNFParamGroupData(mandantid: string, nfmonitorgroups: string) {
        setIsLoading(true);
        const NFParamGroupData_URL = UserSession.getSystemSettings().API_URL + 'CobraMonitor/getNFParamGroup';
        const response = await axios.post(NFParamGroupData_URL,
            JSON.stringify({ "mandantid": mandantid, "nfmonitorgroups": nfmonitorgroups }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        setNFParamGroupData(response?.data);
        setIsLoading(false);
    }

    async function GetCobraMonitorDisplayData(mandantid: string, nfmonitorgroups: string) {
        setIsLoading(true);
        const GetCobraMonitorDisplayData_URL = UserSession.getSystemSettings().API_URL + 'CobraMonitor/FillSettingDisplay';
        const response = await axios.post(GetCobraMonitorDisplayData_URL,
            JSON.stringify({ "mandantid": mandantid, "nfmonitorgroups": nfmonitorgroups }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        setCobraMonitorDisplayData(response?.data);
        setIsLoading(false);
    }

    async function GetCobraMonitorData(mandantid: string, nfmonitorgroups: string) {
        try {
            setIsLoading(true);
            const GetCobraMonitorData_URL = UserSession.getSystemSettings().API_URL + 'CobraMonitor/getrecords';
            const response = await axios.post(GetCobraMonitorData_URL,
                JSON.stringify({ "mandantid": mandantid, "nfmonitorgroups": nfmonitorgroups }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
            setCobraMonitorData(response?.data);
            setIsLoading(false);
        }
        catch (ex: any) {

        }
        setIsLoading(false);
    }

    async function SaveNFMonitorSettingsData(newdata: CNFMonitorSettings[]) {
        const SetNFMonitorSettingsData_URL = UserSession.getSystemSettings().API_URL + 'CobraMonitor/setNFMonitorSettings';
        await axios.post(SetNFMonitorSettingsData_URL,
            JSON.stringify({ "list": newdata }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        GetNFParamGroupData(UserSession.getLoggedInUser().CurrentMandant, '');
        GetNFMonitorSettingsData(UserSession.getLoggedInUser().CurrentMandant);
    }

   // Actual monitor data changed
    useEffect(() => {
        var binddataCols: columnDefinition[] = [];
        CobraMonitorData !== null && CobraMonitorData.length > 0 && CobraMonitorData.slice(0, 1).map((info: CNFMonitorDisplay, index: number) => {
            info.sH1 !== '' && binddataCols.push({ field: 'sDF1', title: info.sH1, k: 'sKey', width: '300' });
            info.sH2 !== '' && binddataCols.push({ field: 'sDF2', title: info.sH2, k: 'sKey', width: '' });
            info.sH3 !== '' && binddataCols.push({ field: 'sDF3', title: info.sH3, k: 'sKey', width: '150' });
            info.sH4 !== '' && binddataCols.push({ field: 'sDF4', title: info.sH4, k: 'sKey', width: '150' });
            info.sH5 !== '' && binddataCols.push({ field: 'sDF5', title: info.sH5, k: 'sKey', width: '150' });
            info.sH6 !== '' && binddataCols.push({ field: 'sDF6', title: info.sH6, k: 'sKey', width: '150' });
            info.sH7 !== '' && binddataCols.push({ field: 'sDF7', title: info.sH7, k: 'sKey', width: '150' });
            info.sH8 !== '' && binddataCols.push({ field: 'sDF8', title: info.sH8, k: 'sKey', width: '150' });
            info.sH9 !== '' && binddataCols.push({ field: 'sDF9', title: info.sH9, k: 'sKey', width: '150' });
            info.sH10 !== '' && binddataCols.push({ field: 'sDF10', title: info.sH10, k: 'sKey', width: '150' });
        });
        setColumnsToShow(binddataCols)
    }, [CobraMonitorData])
    
    useEffect(() => {

        const binddata: groupData[] = [];
      
        NFParamGroupData.forEach((value: groupData) => {
            if (value.sSuppl1 === '1') {
                binddata.push(value);
            }
        });
        //if (isAllSelected == true)
        //    setActive(true);
        console.log(binddata)
        setSelectedGroupData(binddata)

        //setSelectedGroupData([]);
        GetCobraMonitorDisplayData(UserSession.getLoggedInUser().CurrentMandant, '');
        GetCobraMonitorData(UserSession.getLoggedInUser().CurrentMandant, '');
        GetNFMonitorSettingsData(UserSession.getLoggedInUser().CurrentMandant);
        GetParamData(UserSession.getLoggedInUser().CurrentMandant);
    }, [NFParamGroupData])

    // Group selection changed in the checkbox list
    useEffect(() => {
        stateRef.current = selectedGroupData;
        
    }, [selectedGroupData])

    // on mandant change
    useEffect(() => {
        GetNFParamGroupData(UserSession.getLoggedInUser().CurrentMandant, '');
        GetNFMonitorSettingsData(UserSession.getLoggedInUser().CurrentMandant);
    }, [props.mandantChanged]);     

    // on first time render of component
    useEffect(() => {
        GetNFParamGroupData(UserSession.getLoggedInUser().CurrentMandant, '');
       
        const interval = setInterval(() => {
            var sIDs = "";
            var cid = stateRef.current;
            if (cid.length >= 1)
                cid.forEach((o: groupData) => {
                    if (sIDs === '')
                        sIDs = o.sCode.toString();
                    else
                        sIDs = sIDs + ',' + o.sCode.toString()
                });

            GetCobraMonitorDisplayData(UserSession.getLoggedInUser().CurrentMandant, '');
            GetCobraMonitorData(UserSession.getLoggedInUser().CurrentMandant, sIDs)
        }, 30 * 1000);
        return () => clearInterval(interval);

    }, [])

    const ListViewItemRender = (props: ListViewItemProps) => {
        let item = props.dataItem;
        return (
            <div
                id={item.SettingID}
                className="k-listview-item"
                style={{ borderBottom: "1px solid lightgrey", backgroundColor: item.SettingBackColor, padding: 5, color: item.SettingForeColor }}
            >
              {item.Remark}
            </div>
        );
    };

    const GridRowRender = (
        trElement: React.ReactElement<HTMLTableRowElement>,
        props: GridRowProps
    ) => {
        const rowColor = { backgroundColor: props.dataItem.sBC, color: props.dataItem.sFC };
                const trProps: any = { style: rowColor};
            return React.cloneElement(
                trElement,
                { ...trProps }
            );  
    };

    const [visibleWindow, setVisibleWindow] = useState<boolean>(false);
    const [visiblePDWindow, setvisiblePDWindow] = useState<boolean>(false);

    const toggleWindow = () => {
        setVisibleWindow(!visibleWindow);
    };

    const togglePDWindow = () => {
       
        if (visiblePDWindow === true) {
            //console.log(visiblePDWindow)
            SaveParamData(ParamData);
        }
        setvisiblePDWindow(!visiblePDWindow);
    };

    // for grouping of data
    const initialGroup: GroupDescriptor[] = [{ field: 'SettingTypeDesc' }];
    const [group, setGroup] = React.useState(initialGroup);
    const processWithGroups = (data: CNFMonitorSettings[], group: GroupDescriptor[]): GroupResult[] => {
        const newDataState: any = groupBy(data, group);
        setGroupIds({ data: newDataState, group: group });
        return newDataState;
    };
    const [collapsedState, setCollapsedState] = React.useState<string[]>([]);
    const onGroupChange = React.useCallback(
        (event: GridGroupChangeEvent) => {
            processWithGroups(NFMonitorSettingsData, event.group);
            setGroup(event.group);
        },
        [NFMonitorSettingsData]
    );

    const onExpandChange = React.useCallback(
        (event: GridExpandChangeEvent) => {
            const item = event.dataItem;

            if (item.groupId) {
                const collapsedIds = !event.value
                    ? [...collapsedState, item.groupId]
                    : collapsedState.filter((groupId) => groupId !== item.groupId);
                setCollapsedState(collapsedIds);
            }
        },
        [collapsedState]
    );

    const cellRender = (tdElement: any, cellProps: GridCellProps) => {
        return tdElement;
    }

    const MyEditCommandCell = (props: GridCellProps) => (
        props.rowType !== 'groupHeader' ? < MyEditCell { ...props } enterEdit = { enterEdit } />: <></>
    );

    const newData = setExpandedState({
        data: processWithGroups(NFMonitorSettingsData, initialGroup),
        collapsedIds: collapsedState
    });
   
    //

    //param data
    const [filter, setFilter] = React.useState(initialFilter);
    const [sort, setSort] = React.useState(initialSort);

    const nfgcellRender = (cprops: GridCellProps) => {
        const { dataItem } = cprops;

        //console.log("di", dataItem.sNFMonitorGroupDisplayString, dataItem.sNFMonitorGroup)
        /*<></>*/
        return (

            <td>
                {dataItem.inEdit ? (
                    <CodeTableSel autocomplete={false} setSelectedCT={callbackCTSelected} mandantChanged={props.mandantChanged} id="nfMonitorGroup"
                        codeTableID='223' selCode={selectedCode} supplParam1="" supplParam2="" width="250px"></CodeTableSel>
                ) : (
                    dataItem.sNFMonitorGroupDisplayString
                )}
            </td>
        );
    };

    const location: string = props.loadfor;
    var paneContentHeightLeft: number = window.innerHeight;
    paneContentHeightLeft -= 200;
    var paneContentHeighRight: number = window.innerHeight;
    paneContentHeighRight -= 180;
    var gridHeight: number = window.innerHeight;
    gridHeight -= 425; // location === "modal" ? 300 : 425;

    var rightPaneWidth: number = window.innerWidth - 480;
    if (location === "modal")
        rightPaneWidth = window.innerWidth - 610;
    if (panes[0].collapsed) {
        rightPaneWidth += 350;
    }
    var gridWidth: number = rightPaneWidth - 25;
    if (panes[0].collapsed) {
        gridWidth += 20;
    }
    var gridColWidth: number = gridWidth / columnsToShow.length;
    gridColWidth -= (columnsToShow.length * 1);
    return (
    <>
        <Splitter panes={panes} onChange={onPaneChange}>
                <div className="pane-content-rm" style={{ height: paneContentHeightLeft, padding: "15px", overflowX: 'hidden' }}>
                    <div>
                        <Button onClick={() => handleSwitch(!selectAll)} themeColor={'primary'} style={{ width: "200px", marginRight: "16px" }}>
                            {selectAll ? mui(userRights, "cobramonitor", "cmDeSelectAll", "Deselect All") : mui(userRights, "cobramonitor", "cmdSelectAll", "Select All")}
                        </Button>

                        {!visibleWindow && (
                            <Button
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" style={{ marginRight: "16px" }}
                                icon="gear"
                                onClick={toggleWindow}
                            >
                            </Button>
                            
                            )}

                        {openForm && (
                            <Suspense fallback={<Loader />}>
                                <EditForm
                                    cancelEdit={handleCancelEdit}
                                    onSubmit={handleSubmit}
                                    item={editItem}
                                />
                            </Suspense>
                        )}

                        {!visiblePDWindow && (
                            <Button
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" style={{ marginRight: "16px" }}
                                icon="pencil"
                                onClick={togglePDWindow}
                            >
                            </Button>

                        )}
                    </div>
                    <div>
                        {

                            NFParamGroupData !== null && NFParamGroupData.map((value: groupData, index) => {
                                return (
                                    <div key={index} style={{ width: "100%", padding: 10 }}>
                                            <Checkbox
                                                key={value.sCode}
                                            onChange={(event: CheckboxChangeEvent) => handleGroupCheckbox(event, value.sCode)}
                                            checked={selectedGroupData.some((el: groupData) => el.sCode === value.sCode)}
                                                label={value.sCode + ' ' + value.sDescDevelopment}
                                            />
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
                <div className="pane-content-rm" style={{
                    height: paneContentHeighRight, width: rightPaneWidth,
                    overflowY: 'hidden', border: '0px solid green'
                }}>
                    {isLoading ? <ProgressBar message=""></ProgressBar> : 
                        <><ListView
                            data={CobraMonitorDisplayData}
                            item={ListViewItemRender}
                            style={{ width: gridWidth }} /><Grid
                                style={{ height: gridHeight, overflowY: 'hidden', width: gridWidth }}
                                data={CobraMonitorData}
                                dataItemKey={'sKey'}
                                rowRender={GridRowRender}
                            >
                                {columnsToShow.map((item, i) => <GridColumn width={gridColWidth} field={item.field} title={item.title} key={item.k} />)}
                            </Grid></>
                    }
                </div>
            </Splitter>

            {visibleWindow && (
                <Dialog  title={<DialogTitleBar title={mui(userRights, "dlgsettings", "header", "Settings")} ></DialogTitleBar>} onClose={toggleWindow} >
                    <Grid
                        size={'medium'}
                        style={{ height: "calc(100vh - 300px)", width: 'calc(100vw - 300px)', overflowX: 'hidden' }}
                        data={newData}
                        dataItemKey={'sKey'}
                        group={group}
                        onGroupChange={onGroupChange}
                        onExpandChange={onExpandChange}
                        expandField="expanded"
                        cellRender={cellRender}
                        rowHeight={40}
                    >
                        <GridColumn cell={MyEditCommandCell} width="200px" />
                        <GridColumn field="SettingType" title={mui(userRights, "dlgsettings", "colSettingType", "SettingType")} width="100" locked={true} />
                        <GridColumn field="SettingID" title={mui(userRights, "dlgsettings", "colSettingID", "SettingID")} width="0" />
                        <GridColumn field="MandantID" title={mui(userRights, "dlgsettings", "colMandantID", "MandantID")} width="0" />
                        <GridColumn field="SettingValue" title={mui(userRights, "dlgsettings", "colSettingValue", "SettingValue")} width="300" />
                        <GridColumn field="SettingForeColor" title={mui(userRights, "dlgsettings", "colSettingForeColor", "SettingForeColor")} width="100" />
                        <GridColumn field="SettingBackColor" title={mui(userRights, "dlgsettings", "colSettingBackColor", "SettingBackColor")} width="100" />
                        <GridColumn field="Remark" title={mui(userRights, "dlgsettings", "colRemark", "Remark")} />
                    </Grid>
                </Dialog>
            )}

            {visiblePDWindow && (
                <Dialog  title={<DialogTitleBar title={mui(userRights, "dlgparamdata", "header", "Parameter Data")} ></DialogTitleBar>} onClose={togglePDWindow}>
                    <Grid 
                        size={'medium'}
                        style={{ height: "calc(100vh - 200px)", width: 'calc(100vw - 650px)', overflowX: 'hidden' }}
                        data={orderBy(filterBy(ParamData, filter), sort)}
                        dataItemKey={'sParamId'}
                        rowHeight={40}
                        onItemChange={itemChange}
                        editField={editField}
                        skip={page.skip}
                        take={page.take}
                        total={ParamData.length}
                        pageable={false}
                        onPageChange={pageChange}
                        filterable={true}
                        filter={filter}
                        onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e: GridSortChangeEvent) => {
                            setSort(e.sort);
                        }}
                    >
                        <GridColumn field="sParamId" title={mui(userRights, "dlgparamdata", "colParamId", "ParamId")} width="100" locked={true} editable={false} />
                        <GridColumn field="sNameOnRep" title={mui(userRights, "dlgparamdata", "colNameOnRep", "NameOnRep")} width="300" editable={false} />
                        <GridColumn field="sShowOnNFMonitorYN" title={mui(userRights, "dlgparamdata", "colShowOnNFMonitorYN", "ShowOnNFMonitorYN")} width="100" filterable={false} editor="boolean" />
                        <GridColumn field="sShowOnNFMonitorOnlyIfEmergYN" title={mui(userRights, "dlgparamdata", "colShowOnNFMonitorOnlyIfEmergYN", "ShowOnNFMonitorOnlyIfEmergYN")} width="100" filterable={false} editor="boolean" />
                        <GridColumn field="sNFMonitorGroupDisplayString" title={mui(userRights, "dlgparamdata", "colNFMonitorGroup", "NFMonitorGroup")} width="275px" filterable={false} cell={nfgcellRender} />
                        <GridColumn cell={CommandCell} filterable={false} width="300px" />
                    </Grid>
                </Dialog>
            )}


    </>
    );
}

export default CobraMonitor;