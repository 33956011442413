import { useCallback, useEffect, useState } from "react";
import { CAddressData, CAdrExtPID, CHANGED, DELETED, SAVED, UNSAVED } from "../../../types";
import { v4 as uuidv4 } from 'uuid';
import { getter, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { ComboBoxChangeEvent, ComboBoxFilterChangeEvent, MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import UserSession from "../../UserSession";
import axios from 'axios';
import { getSelectedState } from "@progress/kendo-react-data-tools";
import { Grid, GridCellProps, GridColumn, GridSelectionChangeEvent, GridSortChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import Confirmation, { IConfirmation } from "../../Misc/Confirmation";
import { mui } from "../../../Helper";

interface AdrExtPIDProps {
    data: CAddressData;
    AdrExtPIDData: CAdrExtPID[] | undefined;
    contentContainerWidth: number;
    callbackHandleAdrExtPIDDataChange(value: CAdrExtPID[]): any;
}

let AdrExtPIDTab_source: any = null;

const AdrExtPIDTab = (props: AdrExtPIDProps) => {
    // For confirmation dialog
    const emptyConfirmation: IConfirmation = {
        title: "",
        message: "",
        button_yes: "",
        button_no: "",
        show: false,
        userdata: "",
        buttonClicked: function (yes: boolean): void {

        }
    }
    const [confirmDialog, setConfirmDialog] = useState<IConfirmation>(emptyConfirmation);

    const idGetter_AdrExtPID = getter('key');
    const [disableCombo, setDisableCombo] = useState<boolean>(false);
    const [adrExtPID, setAdrExtPID] = useState<CAdrExtPID[]>([]);
    const [extPIDKey, setExtPIDKey] = useState<string>("");
    const [extPID, setExtPID] = useState<string>("");
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [extLabData, setExtLabData] = useState<ExtLabObject[]>([]); // For auto complete
    const [extLabDataSelFromGrid, setExtLabDataSelFromGrid] = useState<ExtLabObject | null>(null);
    const extLabDataCols = [
        { field: "sPID", header: "PID" },
        { field: "sLastName", header: "Last Name" },
        { field: "sFirstName", header: "First Name" },
    ];
    // Grid item object
    type ExtLabObject = {
        sPID: string;
        sLastName: string;
        sFirstName: string;
        sDisplayString: string;
        selected: boolean;        
    }
    // multi sel combo item change
    const extLabDataOnChange = (event: ComboBoxChangeEvent) => {
        setExtLabDataSelFromGrid(event.target.value);
    };
    // Delay the api call to search address (ext lib) by 500 ms
    useEffect(() => {
        const delayedSearch = setTimeout(() => {
            if (searchTerm) {
                filterExtLab();
            }
        }, 500);

        return () => clearTimeout(delayedSearch);
    }, [searchTerm]);
    // filter data from api
    const filterExtLab = async () => {
        if (searchTerm === "") return;
        if (searchTerm.length < 2) return;
        console.log('filterExtLab', searchTerm);
        try {
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'address/getAddressDataForPassedTypes',
                JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, pid: "", adrtype: "5", searchstring: searchTerm }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json',
                    },
                    withCredentials: false,
                    cancelToken: AdrExtPIDTab_source.token
                }
            );
            var binddata: ExtLabObject[] = [];
            if (response?.data !== null) {
                response?.data.forEach((o: CAddressData) => {
                    binddata.push({
                        sPID: o.sPID,
                        sLastName: o.sLastName,
                        sFirstName: o.sFirstName,
                        sDisplayString: o.sPID + ', ' + o.sLastName + ' ' + o.sFirstName,
                        selected: false,                        
                    });
                });
            }
            setExtLabData(binddata);

        } catch (err: any) {
            console.log(err);
            if (!err?.response) {
            } else if (err.response?.status === 401) {
            } else {
            }
        }

    }
    // user typed something in combo
    const extLabDataFilterChange = (event: ComboBoxFilterChangeEvent) => {
        setSearchTerm(event.filter.value);
    };
    // All textbox and checkboxes related to adr ext pid tab
    const handleChange_AdrExt = (e: any) => {
        const type = e.target.type
        const name = e.target.name
        const value = type === "checkbox"
            ? e.target.checked
            : e.target.value
        if (name === 'adrExtPID')
            setExtPID(value);
    }
    const deleteExtPID = useCallback((key: string, value: string, recstatus: string) => {
        if (recstatus === DELETED) return;
        const buttonClickedConfExtPID = (yes: boolean, userdata: string): void => {
            if (yes) {
                console.log('extPIDKey to delete', userdata, adrExtPID);
                if (recstatus === UNSAVED) {
                    let newList: CAdrExtPID[] = adrExtPID.filter(ura => {
                        return userdata !== ura.key;

                    });
                    console.log(newList);
                    setAdrExtPID(newList);
                    props.callbackHandleAdrExtPIDDataChange(newList);
                } else {
                    var binddata: CAdrExtPID[] = adrExtPID;
                    binddata.forEach((o: CAdrExtPID) => {
                        if (o.sExtLab === value) {
                            o.sRecStatus = DELETED;
                        };
                    });
                    setAdrExtPID(binddata);
                    props.callbackHandleAdrExtPIDDataChange(binddata);
                };                
            };
            setConfirmDialog(emptyConfirmation);
        }

        const deleteConfirmation: IConfirmation = {
            title: mui(userRights, "common", "deleteconfirm", "Delete Confirm"),
            message: "Delete ext lab " + value + '?',
            button_yes: mui(userRights, "common", "yes", "Yes"),
            button_no: mui(userRights, "common", "no", "No"),
            show: true,
            buttonClicked: buttonClickedConfExtPID,
            userdata: key
        }
        setConfirmDialog(deleteConfirmation);
    }, [adrExtPID]);
    const CustomCellEdit_AdrExtPID = (props: GridCellProps) => {
        const key = props.dataItem['key'];
        const value = props.dataItem['sExtLab'];
        const recstatus = props.dataItem['sRecStatus'];
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <Button className="buttons-container-button" icon="delete"
                    onClick={() => {
                        deleteExtPID(key, value, recstatus);
                    }
                    }
                >
                </Button>
            </td>
        );
    };
    const newExtPID = () => {
        let sel: any = {};
        setSelectedState_AdrExtPID(sel);
        setExtPID("");
        setExtPIDKey("");
        setExtLabData([]);
        setExtLabDataSelFromGrid(null);
        setDisableCombo(false);
        setTimeout(() => { document.getElementById('adrExtLab')?.focus() }, 200);
    }
    const [selectedState_AdrExtPID, setSelectedState_AdrExtPID] = useState<{
        [id: string]: boolean | number[];
    }>({});
    const initialSort_AdrExtPID: Array<SortDescriptor> = [
        { field: "sExtLab", dir: "asc" },
    ];
    const [sort_AdrExtPID, setSort_AdrExtPID] = useState(initialSort_AdrExtPID);
    const gridSelChangeAdrExtPID = (event: GridSelectionChangeEvent) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState_AdrExtPID,
            dataItemKey: 'key',
        });
        // get the selected row from the grid and set state variable so that data is populated in the form
        let selectedIds: CAdrExtPID[] = [];
        var binddata: ExtLabObject[] = [];
        for (const property in newSelectedState) {
            if (newSelectedState[property]) {
                let selobj: CAdrExtPID | undefined = adrExtPID.find((item: CAdrExtPID) => item.key === property.toString())
                if (selobj !== undefined) {
                    selectedIds.push(selobj)
                    binddata.push({
                        sPID: selobj.sExtLab,
                        sLastName: selobj.sLastName,
                        sFirstName: selobj.sFirstName,
                        sDisplayString: selobj.sExtLab + ', ' + selobj.sLastName + ' ' + selobj.sFirstName,
                        selected: true,                        
                    });
                    setExtLabData(binddata);
                    setExtLabDataSelFromGrid(binddata[0]);
                }
            }
        }
        if (selectedIds.length > 0) {
            setExtPID(selectedIds[0].sExtPID);
            setExtPIDKey(selectedIds[0].key);
            setDisableCombo(true);
        } else {
            setDisableCombo(false);
        };
        setSelectedState_AdrExtPID(newSelectedState);
    };
    function CheckMandatoryData() {        
        var bok: boolean = true;
        if (extLabDataSelFromGrid === null) {
            alert("Enter Ext Lab");
            bok = false;        
        };
        return bok;
    };
    const updateListAdrExtPID = () => {
        if (CheckMandatoryData() === false) return;
        if (Object.keys(selectedState_AdrExtPID).length === 0 && extLabDataSelFromGrid !== null) {            
            let clonedArray: CAdrExtPID[] = JSON.parse(JSON.stringify(adrExtPID));
            var newkey: string = uuidv4();
            clonedArray.unshift({
                key: newkey,
                sMandantID: UserSession.getLoggedInUser().CurrentMandant,
                sPID: props.data.sPID,
                sExtLab: extLabDataSelFromGrid.sPID,
                sExtPID: extPID,
                sInactiveYN: "",
                objRecUpd: {
                    sModifiedBy: "",
                    sModifiedDate: "",
                    sModifiedTime: "",
                    sRecordedBy: "",
                    sRecordedDate: "",
                    sRecordedTime: "",
                    sDelete: "",
                    sInActive: ""
                },
                sFirstName: extLabDataSelFromGrid.sFirstName,
                sLastName: extLabDataSelFromGrid.sLastName,
                sRecStatus: UNSAVED
            });
            setAdrExtPID(clonedArray);
            props.callbackHandleAdrExtPIDDataChange(clonedArray);
            let sel: any = {};
            sel[clonedArray[0].key] = true;
            setSelectedState_AdrExtPID(sel);
            setExtPIDKey(newkey);
        }
        else if (extLabDataSelFromGrid !== null) {
            var binddata: CAdrExtPID[] = [];
            adrExtPID.forEach((o: CAdrExtPID) => {
                if (extPIDKey === o.key) {
                    binddata.push({
                        ...o, sExtPID: extPID, sExtLab: extLabDataSelFromGrid.sPID, sFirstName: extLabDataSelFromGrid.sFirstName, sLastName: extLabDataSelFromGrid.sLastName, sRecStatus: o.sRecStatus === SAVED ? CHANGED : o.sRecStatus
                    });
                }
                else
                    binddata.push({ ...o });
            });
            setAdrExtPID(binddata);
            props.callbackHandleAdrExtPIDDataChange(binddata);
        }
    }

    useEffect(() => {
        if (props.data.hasOwnProperty('state') && props.data.state === "EMPTY") {
            setAdrExtPID([]);
            setExtPID("");
            setExtPIDKey("");
            setExtLabData([]);
            setExtLabDataSelFromGrid(null);
            setSelectedState_AdrExtPID({});
        }
        else {
            var binddata_adrext: CAdrExtPID[] = [];
            if (props.AdrExtPIDData !== null && props.AdrExtPIDData !== undefined)
                props.AdrExtPIDData.forEach((o: CAdrExtPID) => {
                    binddata_adrext.push({ ...o, key: uuidv4() });
                });
            setAdrExtPID(binddata_adrext);

        }
    }, [props.AdrExtPIDData])

    useEffect(() => {
        AdrExtPIDTab_source = axios.CancelToken.source();

        return () => {
            if (AdrExtPIDTab_source) {
                AdrExtPIDTab_source.cancel("Component got unmounted");
            }
        };

    }, [])

    function checkDeleted(obj: CAdrExtPID) {
        return obj.sRecStatus !== DELETED;
    };

    return (
        <>
            <div className="container pt5" style={{ maxWidth: props.contentContainerWidth }}>
                <div className="row">
                    <div className="col-6">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'adrExtLab'}>Ext Lab</Label></div>
                            <div className="vertical-wrapper-child">
                                <MultiColumnComboBox
                                    disabled={disableCombo}
                                    id="adrExtLab"
                                    data={extLabData}
                                    value={extLabDataSelFromGrid}
                                    columns={extLabDataCols}
                                    filterable={true}
                                    textField={"sDisplayString"}
                                    onFilterChange={extLabDataFilterChange}
                                    onChange={extLabDataOnChange}
                                    placeholder="Search Ext Lab ..." />                                   
                            </div>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label editorId={'idfrgcard'}>Ext PID</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="adrExtPID" name="adrExtPID" type='text' onChange={handleChange_AdrExt}
                                    value={extPID} />
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="vertical-wrapper">&nbsp;</div>
                        <div className="vertical-wrapper-child">
                            <div className="button-right">
                                <Button style={{ width: '120px' }}
                                    themeColor={'success'}
                                    onClick={updateListAdrExtPID}
                                >
                                    Update List
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col" style={{ height: 390, maxHeight: 390, overflowY: 'hidden', marginTop: 10, border: '0px solid green' }}>
                        <div style={{ overflowX: 'hidden' }}>
                            <Grid style={{ width: '100%', height: 390 }}
                                sortable={true}
                                sort={sort_AdrExtPID}
                                onSortChange={(e: GridSortChangeEvent) => {
                                    setSort_AdrExtPID(e.sort);
                                }}
                                selectable={{
                                    enabled: true,
                                    drag: false,
                                    cell: false,
                                    mode: "single",
                                }}
                                onSelectionChange={gridSelChangeAdrExtPID}
                                selectedField={'selected'}
                                data={orderBy(adrExtPID.filter(checkDeleted), sort_AdrExtPID).map((item) => ({
                                    ...item,
                                    selected: selectedState_AdrExtPID[idGetter_AdrExtPID(item)],
                                }))}
                                dataItemKey={'key'}
                            >
                                <GridToolbar>
                                    <button
                                        title="Add new"
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                        onClick={newExtPID}
                                    >
                                        Add new
                                    </button>
                                </GridToolbar>
                                <GridColumn field="" title="" width="80px" cell={CustomCellEdit_AdrExtPID} />
                                <GridColumn field="sExtLab" title="Ext Lab" width="100px" />
                                <GridColumn field="sFirstName" title="First Name" />
                                <GridColumn field="sLastName" title="Last Name" />
                                <GridColumn field="sExtPID" title="Ext PID" width="180px" />
                                {/*<GridColumn field="key" title="Key" />*/}
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
            <Confirmation title={confirmDialog.title} message={confirmDialog.message} button_yes={confirmDialog.button_yes} button_no={confirmDialog.button_no}
                show={confirmDialog.show} buttonClicked={confirmDialog.buttonClicked} userdata={confirmDialog.userdata}></Confirmation>
        </>
    );
}

export default AdrExtPIDTab;