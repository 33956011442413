
import { Dialog } from "@progress/kendo-react-dialogs";
import React, { useEffect } from "react";
import { useRef, useState } from "react";
import { DialogTitleBar, mui } from "../../Helper";
import { CUserRightsAppl, resultEntryDialog } from "../../types";

import UrineSticksResAndValComponent from "./UrineSticksResAndValComponent";

const UrineSticksResultAndValidation = (props: resultEntryDialog) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'UrineSticksResultAndValidation' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const ref_divButtons = useRef<HTMLDivElement>(null);
    const ref_divReqInfoBar = useRef<HTMLDivElement>(null);
    const ref_divGrid = useRef<HTMLDivElement>(null);


    const [visible, setVisible] = useState<boolean>(props.showDialog);
 
    const adjustGridSize = () => {
        var rootHeight = 900;
        var buttonHeight = 0;
        var reqInfoBarHeight = 0;
        var gridHt = 0;

        /*console.log('refs ', ref_divButtons, ref_divReqInfoBar, ref_divGrid);*/

        if (ref_divButtons !== undefined) buttonHeight = Number(ref_divButtons.current?.clientHeight);
        if (ref_divReqInfoBar !== undefined) reqInfoBarHeight = Number(ref_divReqInfoBar.current?.clientHeight);
        //console.log('buttonHeight', buttonHeight);
        //console.log('reqInfoBarHeight', reqInfoBarHeight);
        gridHt = rootHeight - (buttonHeight + reqInfoBarHeight + 140);
        /*console.log('gridHeight new old ', gridHt, ref_divGrid.current?.style.height);*/
        if (ref_divGrid !== null && ref_divGrid.current)
            ref_divGrid.current.style.height = gridHt.toString() + 'px';
    };

    const CancelClick = async () => {

        //props.ResultSaved(false);
        setVisible(false);
    };

    useEffect(() => {
        setVisible(props.showDialog);
    }, [props.showDialog])

    var dlgHeight: number = window.innerHeight - 80;
    var dlgWidth: number = window.innerWidth - 40;

    return (
        <>
            <div>
                {visible && (
                    <Dialog title={<DialogTitleBar title={mui(userRights, "UrineSticksResultAndValidation", "textTitle", "UrineSticks Result")} />} onClose={CancelClick} height={dlgHeight} closeIcon={false} width={dlgWidth} >
                        <UrineSticksResAndValComponent mandantid={props.mandantid} reqid={props.reqid} pid={props.pid} fid={props.fid}
                            paramid={props.paramid} reqdatetime={props.reqdatetime} reqprovider={props.reqprovider} valtype={props.valtype} addedparams={props.addedparams}
                            pregnancyweek={props.pregnancyweek} mbyn={props.mbyn} userid={props.userid}
                            showDialog={props.showDialog} ResultSaved={props.ResultSaved} selprofilecode={""} dayWorkSpace={false}></UrineSticksResAndValComponent>
                    </Dialog>
                )}
            </div>
        </>
    );
}
export default UrineSticksResultAndValidation;