import { Button } from "@progress/kendo-react-buttons";
import { MultiSelect, MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { Checkbox, Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useEffect, useState } from "react";
import { mui } from "../../../Helper";
import { CAddressData, ctDataType, ListItemData, MULTI_SELECT_TABLES } from "../../../types";
import { CodeTableSel } from "../../CodeTableSel/CodeTableSel";
import MultiSelectList, { multiSelectProps } from "../MultiSelect/MultiSelectList";
import axios from 'axios';
import UserSession from "../../UserSession";
interface OtherTabProps {
    data: CAddressData;
    callbackCTSelected(ctid: string, controlID: string, objCT: ctDataType): any;
    callbackControlChange(e: any): any;
    contentContainerWidth: number
    MultiSelectSaved(multiSelectTable: MULTI_SELECT_TABLES, savedCodes: string, cancel: boolean): any;
    callbackAdrTypeSelected(items: ListItemData[]):any;
}

let OtherTab_source: any = null;

const OtherTab = (props: OtherTabProps) => {
    // Address type related
    const [allAddressTypes, setAllAddressTypes] = useState<ListItemData[]>([]);
    const [selectedAddressTypes, setSelectedAddressTypes] = useState<ListItemData[]>([]);
    const handleChangeAddressType = (event: MultiSelectChangeEvent) => {
        setSelectedAddressTypes(event.value);   
        props.callbackAdrTypeSelected(event.value);
    }

    const dlgMSEmptyState: multiSelectProps = {
        multiSelectTable: "",
        selectedValues: "",
        showDialog: false,
        MultiSelectSaved: (multiSelectTable: MULTI_SELECT_TABLES, savedCodes: string, cancel: boolean) => { }
    }
    const MultiSelectSaved = (multiSelectTable: MULTI_SELECT_TABLES, savedCodes: string, cancel: boolean) => {
        props.MultiSelectSaved(multiSelectTable, savedCodes, cancel);
        setMultiSelectData(dlgMSEmptyState);
    }
    const openRepToEmailMS = (multiSelectTable: MULTI_SELECT_TABLES) => {
        var selectedValues: string = "";
        if (multiSelectTable === "ReportPage")
            selectedValues = props.data.sRepToEmail;
        setMultiSelectData({
            multiSelectTable: multiSelectTable, showDialog: true,
            selectedValues: selectedValues, MultiSelectSaved: MultiSelectSaved
        });
    };

    const [multiSelectData, setMultiSelectData] = useState<multiSelectProps>(dlgMSEmptyState);

    useEffect(() => {
        OtherTab_source = axios.CancelToken.source();

        return () => {
            // console.log('Address Un-Mounted');
            if (OtherTab_source) {
                OtherTab_source.cancel("Component got unmounted");
            }
        };
    }, [])


    useEffect(() => {

        const fill_AddressType = async (defSelection: string) => {
            try {
                const response = await axios.post(UserSession.getSystemSettings().API_URL + 'codetablesel/getcodetabledata',
                    JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, "codeTableID": "305" }),
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                            'Content-Type': 'application/json',
                        },
                        withCredentials: false,
                        cancelToken: OtherTab_source.token
                    }
                );
                var binddata_all: ListItemData[] = [];
                if (response?.data !== null) {
                    response?.data.forEach((o: ctDataType) => {
                        binddata_all.push({ selected: false, id: o.sCode, name: o.sCode + ' ' + o.sDescDevelopment });
                        setAllAddressTypes(binddata_all);
                    });
                }
                var binddata_sel: ListItemData[] = [];
                binddata_all.forEach((o: ListItemData) => {
                    if (props.data.sAddressTypes.indexOf(o.id) !== -1)
                        binddata_sel.push({ selected: true, id: o.id, name: o.name });
                });
                setSelectedAddressTypes(binddata_sel);
            } catch (err: any) {
                console.log(err);
                if (!err?.response) {
                } else if (err.response?.status === 401) {
                } else {
                }
            }
        }
        

        if (props.data.hasOwnProperty('state') && props.data.state === "EMPTY") {
            setSelectedAddressTypes([]);
            fill_AddressType("");
        }
        else {
            fill_AddressType(props.data.sAddressTypes);
        }
    }, [props.data])
 
    return (
        <>
            <div className="container pt5" style={{
                maxWidth: props.contentContainerWidth
            }}>
                <div className="row">
                    <div className="col-4">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>ConcordatNrAmb</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="sConcordatNrAmb" name="sConcordatNrAmb" type='text' onChange={props.callbackControlChange}
                                    value={props.data.sConcordatNrAmb} />
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>ConcordatNrStat</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input autoComplete="off" id="sConcordatNrStat" name="sConcordatNrStat" type='text' onChange={props.callbackControlChange}
                                    value={props.data.sConcordatNrStat} />
                            </div>
                        </div>
                    </div>
                    <div className="col-4"></div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="vertical-wrapper">
                            <div style={{ display: 'inline-block' }}>
                                <Checkbox onChange={props.callbackControlChange} id="NotifyPassMandatoryYN" name="NotifyPassMandatoryYN"
                                    value={props.data.NotifyPassMandatoryYN}
                                    label="NotifyPassMandatoryYN" />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper">
                            <div style={{ display: 'inline-block' }}>
                                <Checkbox onChange={props.callbackControlChange} id="NotifyOnlyNegResultYN" name="NotifyOnlyNegResultYN"
                                    value={props.data.NotifyOnlyNegResultYN}
                                    label="NotifyOnlyNegResultYN" />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper">
                            <div style={{ display: 'inline-block' }}>
                                <Checkbox onChange={props.callbackControlChange} id="DoNotPrintNotificationYN" name="DoNotPrintNotificationYN"
                                    value={props.data.DoNotPrintNotificationYN}
                                    label="DoNotPrintNotificationYN" />
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                    </div>
                </div>
            </div>
            <div className="container" style={{ maxWidth: props.contentContainerWidth, padding: 5, marginTop: 5, borderRadius: 10, border: '1px solid lightblue' }}>
                <div className="row">
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Rep Output type reference doc</Label></div>
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} setSelectedCT={props.callbackCTSelected} mandantChanged={0} id="sRepOutputTypeRefDoc"
                                    codeTableID='637' selCode={props.data.sRepOutputTypeRefDoc} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Rep Output type treating doc</Label></div>
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} setSelectedCT={props.callbackCTSelected} mandantChanged={0} id="sRepOutputTypeTrtDoc"
                                    codeTableID='637' selCode={props.data.sRepOutputTypeTrtDoc} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Rep Output type att doc</Label></div>
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} setSelectedCT={props.callbackCTSelected} mandantChanged={0} id="sRepOutputTypeAttDoc"
                                    codeTableID='637' selCode={props.data.sRepOutputTypeAttDoc} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vertical-wrapper"><div className="vertical-wrapper-child"><Label>Rep Output type copy to doc</Label></div>
                            <div className="vertical-wrapper-child">
                                <CodeTableSel autocomplete={false} setSelectedCT={props.callbackCTSelected} mandantChanged={0} id="sRepOutputTypeCopyToDoc"
                                    codeTableID='637' selCode={props.data.sRepOutputTypeCopyToDoc} supplParam1="" supplParam2="" width="100%"></CodeTableSel>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col-11">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child"><Label editorId={'sRepToEmail'}>Rep to E-Mail</Label></div>
                            <div className="vertical-wrapper-child">
                                <Input width='100%' readOnly={true} autoComplete="off" id="sRepToEmail" name="sRepToEmail" type='text' onChange={props.callbackControlChange} value={props.data.sRepToEmail} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="button-right">
                            <div className="vertical-wrapper">
                                <div className="vertical-wrapper-child">&nbsp;</div>
                                <div className="vertical-wrapper-child">
                                    <Button type={"button"} themeColor={'primary'} onClick={() => {
                                        openRepToEmailMS("ReportPage")
                                    }}>{mui(userRights, "common", "cmdMultiSelect", "M")}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container" style={{ maxWidth: props.contentContainerWidth }}>
                <div className="row">
                    <div className="col">
                        <div className="vertical-wrapper">
                            <div className="vertical-wrapper-child"><Label editorId={'sAddressType'}>Address Type</Label></div>
                            <div className="vertical-wrapper-child">
                                <MultiSelect
                                    id="sPatientType"
                                    data={allAddressTypes}
                                    onChange={handleChangeAddressType}                                   
                                    value={selectedAddressTypes}
                                    textField="name"
                                    dataItemKey="id"
                                    style={{ width: '100%' }}
                                    placeholder="."
                                    autoClose={false}
                                    fillMode="solid"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MultiSelectList multiSelectTable={multiSelectData.multiSelectTable} selectedValues={multiSelectData.selectedValues}
                showDialog={multiSelectData.showDialog} MultiSelectSaved={multiSelectData.MultiSelectSaved}></MultiSelectList>
        </>
    );
}

export default OtherTab;